import React, { useState, useEffect } from 'react';
import { Calendar } from 'primereact/calendar';
import { language } from '../../../lib/date/string';
import { yearRange } from '../../../lib/date/utils';

const CalendarPrime = ({ value, dateFormat = 'yy-mm-dd', style, className, placeholder, onChange, ...props }) => {
    const [date, setDate] = useState(value && new Date(value));

    const chageHandler = ({ target }) => {
        if (!target.value) return;
        onChange && onChange(target.value);
        setDate(target.value);
    };

    useEffect(() => {
        setDate(value ? new Date(value) : null);
    }, [value]);

    return (
        <Calendar
            value={date}
            dateFormat={dateFormat}
            locale={language}
            style={style}
            className={className}
            monthNavigator={true}
            yearNavigator={true}
            yearRange={yearRange(5, 'string')}
            placeholder={placeholder}
            onChange={chageHandler}
            {...props}
        />
    );
};

export default CalendarPrime;
