import fetch from './instance';

export default {
    // 추가번호 관리
    get_school_numbers: () => fetch.get('/services/school-tels'),
    update_school_numbers: (tel_id, data) => fetch.put(`/services/school-tels/${tel_id}`, data),
    delete_school_numbers: (tel_id) => fetch.delete(`/services/school-tels/${tel_id}`),

    // 스쿨투게더 이용학교 관리
    service_schools: (params) => fetch.get('/services/schools', { params }),
    service_schools_admin_update: (school_id, data) => fetch.put(`/services/schools/${school_id}`, data),
    service_message: (school_id, params) => fetch.get(`/services/schools/${school_id}/messages`, { params }),
    service_message_detail: (school_id, message_request_id, params) =>
        fetch.get(`/services/schools/${school_id}/messages/${message_request_id}`, { params }),
    service_message_fail_update: (school_id, message_request_id) =>
        fetch.put(`/services/schools/${school_id}/messages/${message_request_id}`),

    // 앱 유입정보
    inflow_info: () => fetch.get(`/manages/statistics/inflow-infomations`),

    // 일별 사용자
    day_user: (params) => fetch.get('/manages/statistics/daily-users', { params }),

    // 주목 학교 param
    attention_school: () => fetch.get('/manages/statistics/attention-schools'),

    // 가입자순 학교랭킹  param { count (인원수) }
    ranking_schools: () => fetch.get('/manages/statistics/ranking-schools?limit=100'),

    // 개별 서버 성능
    local_server_performance: (params) => fetch.get('/services/school-servers', { params }),
};
