import { createAction } from 'redux-actions';
import { actionString, createRequestAction } from '../helper';

const action = actionString('schoolV2');

export const SET_RECENT_SCHOOL = action('SET_RECENT_SCHOOL');
export const CHANGE_FILTER_VALUE = action('CHANGE_FILTER_VALUE');
export const SCHOOL_DATA_UPDATE = action('SCHOOL_DATA_UPDATE');
export const DASHBOARD_LOADING_INITIALIZE = action('DASHBOARD_LOADING_INITIALIZE');

export const setRecentSchool = createAction(SET_RECENT_SCHOOL);
export const changeFilterValue = createAction(CHANGE_FILTER_VALUE);
export const schoolDataUpdate = createAction(SCHOOL_DATA_UPDATE);
export const dashboardLoadingInitialize = createAction(DASHBOARD_LOADING_INITIALIZE);

// request
export const SCHOOL_SEARCH_REQUEST = action('SCHOOL_SEARCH_REQUEST');
export const SCHOOL_SEARCH_SUCCESS = action('SCHOOL_SEARCH_SUCCESS');
export const SCHOOL_SEARCH_FAILURE = action('SCHOOL_SEARCH_FAILURE');

export const INFLOW_REQUEST = action('INFLOW_REQUEST');
export const INFLOW_SUCCESS = action('INFLOW_SUCCESS');
export const INFLOW_FAILURE = action('INFLOW_FAILURE');

export const DAY_USER_REQUEST = action('DAY_USER_REQUEST');
export const DAY_USER_SUCCESS = action('DAY_USER_SUCCESS');
export const DAY_USER_FAILURE = action('DAY_USER_FAILURE');

export const ATTENSTION_SCHOOL_REQUEST = action('ATTENSTION_SCHOOL_REQUEST');
export const ATTENSTION_SCHOOL_SUCCESS = action('ATTENSTION_SCHOOL_SUCCESS');
export const ATTENSTION_SCHOOL_FAILURE = action('ATTENSTION_SCHOOL_FAILURE');

export const RANKING_SCHOOL_REQUEST = action('RANKING_SCHOOL_REQUEST');
export const RANKING_SCHOOL_SUCCESS = action('RANKING_SCHOOL_SUCCESS');
export const RANKING_SCHOOL_FAILURE = action('RANKING_SCHOOL_FAILURE');

export const SCHOOL_NUMBERS_REQUEST = action('SCHOOL_NUMBERS_REQUEST');
export const SCHOOL_NUMBERS_SUCCESS = action('SCHOOL_NUMBERS_SUCCESS');
export const SCHOOL_NUMBERS_FAILURE = action('SCHOOL_NUMBERS_FAILURE');

export const LOCAL_SERVER_PERFORMANCE_REQUEST = action('LOCAL_SERVER_PERFORMANCE_REQUEST');
export const LOCAL_SERVER_PERFORMANCE_SUCCESS = action('LOCAL_SERVER_PERFORMANCE_SUCCESS');
export const LOCAL_SERVER_PERFORMANCE_FAILURE = action('LOCAL_SERVER_PERFORMANCE_FAILURE');

export const SERVICE_MESSAGES_REQUEST = action('SERVICE_MESSAGES_REQUEST');
export const SERVICE_MESSAGES_SUCCESS = action('SERVICE_MESSAGES_SUCCESS');
export const SERVICE_MESSAGES_FAILURE = action('SERVICE_MESSAGES_FAILURE');

export const SERVICE_MESSAGE_DETAILS_REQUEST = action('SERVICE_MESSAGE_DETAILS_REQUEST');
export const SERVICE_MESSAGE_DETAILS_SUCCESS = action('SERVICE_MESSAGE_DETAILS_SUCCESS');
export const SERVICE_MESSAGE_DETAILS_FAILURE = action('SERVICE_MESSAGE_DETAILS_FAILURE');

export const NICE_SCHOOINFO_REQUEST = action('NICE_SCHOOINFO_REQUEST');
export const NICE_SCHOOINFO_SUCCESS = action('NICE_SCHOOINFO_SUCCESS');
export const NICE_SCHOOINFO_FAILURE = action('NICE_SCHOOINFO_FAILURE');

// request action
export const schoolSearchPender = createRequestAction(
    SCHOOL_SEARCH_REQUEST,
    SCHOOL_SEARCH_SUCCESS,
    SCHOOL_SEARCH_FAILURE
);

export const inflowPender = createRequestAction(INFLOW_REQUEST, INFLOW_SUCCESS, INFLOW_FAILURE);

export const dayUserPender = createRequestAction(
    DAY_USER_REQUEST,
    DAY_USER_SUCCESS,
    DAY_USER_FAILURE
);

export const attenstionSchoolPender = createRequestAction(
    ATTENSTION_SCHOOL_REQUEST,
    ATTENSTION_SCHOOL_SUCCESS,
    ATTENSTION_SCHOOL_FAILURE
);

export const rankingSchoolsPender = createRequestAction(
    RANKING_SCHOOL_REQUEST,
    RANKING_SCHOOL_SUCCESS,
    RANKING_SCHOOL_FAILURE
);

export const schoolNumbersPender = createRequestAction(
    SCHOOL_NUMBERS_REQUEST,
    SCHOOL_NUMBERS_SUCCESS,
    SCHOOL_NUMBERS_FAILURE
);

export const localServerPerformancePender = createRequestAction(
    LOCAL_SERVER_PERFORMANCE_REQUEST,
    LOCAL_SERVER_PERFORMANCE_SUCCESS,
    LOCAL_SERVER_PERFORMANCE_FAILURE
);

export const serviceMessagesPender = createRequestAction(
    SERVICE_MESSAGES_REQUEST,
    SERVICE_MESSAGES_SUCCESS,
    SERVICE_MESSAGES_FAILURE
);

export const serviceMessageDetailsPender = createRequestAction(
    SERVICE_MESSAGE_DETAILS_REQUEST,
    SERVICE_MESSAGE_DETAILS_SUCCESS,
    SERVICE_MESSAGE_DETAILS_FAILURE
);

export const niceSchoolInfoPender = createRequestAction(
    NICE_SCHOOINFO_REQUEST,
    NICE_SCHOOINFO_SUCCESS,
    NICE_SCHOOINFO_FAILURE
);
