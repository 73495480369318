import React from 'react';
import styled from 'styled-components';

const Left = styled.div`
    float: left;
`;

const Right = styled.div`
    float: right;
`;

const Float = ({ children, left, right, ...props }) => {
    if (left) {
        return <Left {...props}>{children}</Left>;
    }

    if (right) {
        return <Right {...props}>{children}</Right>;
    }

    return <div>{children}</div>;
};

Float.defaultProps = {
    left: false,
    right: false,
};

export default Float;
