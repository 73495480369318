import React from 'react';
import styled, { css } from 'styled-components';

const SwitchStyled = styled.label`
    display: inline-block;
    padding: 0;
    margin: 0;
    height: 32px;
    cursor: pointer;

    ${(props) => {
        switch (props.size) {
            case 'small':
                return css`
                    width: 46px;
                    height: 24px;
                `;
            default:
                return css`
                    width: 56px;
                    height: 32px;
                `;
        }
    }}

    input.switch-ctrl {
        display: none;

        &:checked {
            & + .switch-proxy {
                background-color: ${(props) => props.theme.green};

                &::after {
                    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.15);

                    ${(props) => {
                        switch (props.size) {
                            case 'small':
                                return css`
                                    left: calc(100% - 22px);
                                `;
                            default:
                                return css`
                                    left: calc(100% - 30px);
                                `;
                        }
                    }}
                }
            }
        }
    }

    .switch-proxy {
        position: relative;
        width: inherit;
        background-color: ${(props) => props.theme.red};

        ${(props) => {
            switch (props.size) {
                case 'small':
                    return css`
                        height: 24px;
                        border-radius: 24px;
                    `;
                default:
                    return css`
                        height: 32px;
                        border-radius: 32px;
                    `;
            }
        }}

        &::after {
            content: '';
            position: absolute;
            transition: all 0.3s;
            top: 50%;
            left: 4px;
            transform: translateY(-50%);
            border-radius: 50%;
            background-color: #ffffff;
            box-shadow: -1px 2px 4px 0 rgba(0, 0, 0, 0.15);

            ${(props) => {
                switch (props.size) {
                    case 'small':
                        return css`
                            width: 18px;
                            height: 18px;
                        `;
                    default:
                        return css`
                            width: 26px;
                            height: 26px;
                        `;
                }
            }}
        }
    }
`;

const Switch = ({ name, checked, index, size, onChange, ...props }) => {
    const changeHandler = () => {
        if (onChange) {
            onChange();
        }
    };

    return (
        <SwitchStyled size={size} {...props}>
            <input
                type="checkbox"
                name={name}
                className="switch-ctrl"
                checked={checked || false}
                onChange={changeHandler}
            />
            <div className="switch-proxy">{/* ::after switch ball */}</div>
        </SwitchStyled>
    );
};

Switch.defaultProps = {
    name: null,
    size: 'default',
    checked: false,
};

export default Switch;
