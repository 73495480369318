import { handleActions } from "redux-actions";
import * as api from "../api";

const addressMap = {
  contract: "contractAPI",
  user: "userAPI",
  other: "otherAPI",
};

export const downloadAsync = (data, alertEvent) =>
  () => {
    const [hapi, feather] = data.endpoint.split("/");
    const fn = api[addressMap[hapi]];

    // endpoinet 가 같이 넘어가는걸 방지
    delete data.endpoint;

    fn[feather](data, (progress) => {
      if (alertEvent) {
        alertEvent(progress);
      }
    })
      .then((response) => {
        const { res, headers } = response;
        const blob = new Blob([res], { type: headers["content-type"] });
        const encode = headers["content-disposition"].split("filename=")[1];
        const filename = decodeURI(encode, "utf-8");

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          //other browsers
          let link = document.createElement("a");
          link.setAttribute("href", window.URL.createObjectURL(blob));
          link.setAttribute("download", filename);
          link.setAttribute("target", "_blank");
          link.style.display = "none";
          link.click();

          link = null;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

const initialState = {};

export default handleActions({}, initialState);
