// pure action helpere
export const actionString = (key) => (action) => `${key}/${action}`;

// request action helper
export const createRequestAction = (request, success, failure) => {
    return {
        request: (payload) => ({ type: request, payload }),
        success: (payload) => ({ type: success, payload }),
        failure: (payload) => ({ type: failure, payload }),
    };
};
