import React from 'react';
import styled, { css } from 'styled-components';

const PaginationArrowStyled = styled.div`
    height: 32px;
    width: 32px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;

    ${(props) =>
        props.disabled &&
        css`
            cursor: unset;

            i {
                color: ${(props) => props.theme.gray[3]};
            }
        `}
`;

const PaginationArrow = ({ prev, next, start, end, disabled, onPage }) => {
    const pageHandler = () => {
        if (!disabled) onPage();
    };

    if (prev) {
        return (
            <PaginationArrowStyled disabled={disabled} onClick={pageHandler}>
                <i className="fas fa-angle-left"></i>
            </PaginationArrowStyled>
        );
    }

    if (next) {
        return (
            <PaginationArrowStyled disabled={disabled} onClick={pageHandler}>
                <i className="fas fa-angle-right"></i>
            </PaginationArrowStyled>
        );
    }

    if (start) {
        return (
            <PaginationArrowStyled disabled={disabled} onClick={pageHandler}>
                <i className="fas fa-angle-double-left"></i>
            </PaginationArrowStyled>
        );
    }

    if (end) {
        return (
            <PaginationArrowStyled disabled={disabled} onClick={pageHandler}>
                <i className="fas fa-angle-double-right"></i>
            </PaginationArrowStyled>
        );
    }
};

export default PaginationArrow;
