import { itv } from './options';

export const defaultStyle = {
    position: 'fixed',
    zIndex: '2001'
};

export default position => {
    if (typeof position !== 'string') {
        console.warn('position is not string type');
    }

    let pos = typeof position !== 'string' ? 'top center' : position;

    switch (pos) {
        case 'top left':
            return { top: itv.y, left: itv.x };
        case 'top right':
            return { top: itv.y, right: itv.x };
        case 'top center':
            return { top: itv.y, left: '50%', transform: 'translateX(-50%)' };
        case 'bottom left':
            return { bottom: itv.y, left: itv.x };
        case 'bottom right':
            return { bottom: itv.y, right: itv.x };
        case 'bottom center':
            return {
                bottom: itv.y,
                left: '50%',
                transform: 'translateX(-50%)'
            };
        default:
            return { top: itv.y, left: '50%', transform: 'translateX(-50%)' };
    }
};
