import { useState, useCallback } from 'react';

const useToggle = (defaultValue) => {
    const [toggle, setToggle] = useState(defaultValue || false);

    const onToggle = useCallback(
        (value) => {
            // boolean 일 때만 parameter 허용
            const isBoolean = typeof value === 'boolean';
            const next = isBoolean ? value : !toggle;
            setToggle(next);
        },
        [toggle]
    );

    return [toggle, onToggle];
};

export default useToggle;
