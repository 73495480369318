import { createAction } from 'redux-actions';
import { actionString, createRequestAction } from '../helper';

const key = 'contracrV2';
const action = actionString(key);

// string action
export const CLEAN_UP = action('CLEAN_UP');
export const SET_FILTER = action('SET_FILTER');
export const SET_PLAN = action('SET_PLAN');
export const SET_ACTIVE_CONTRACT = action('SET_ACTIVE_CONTRACT');
export const SET_RE_CONTRACT_INFO = action('SET_RE_CONTRACT_INFO');

// real action
export const cleanUp = createAction(CLEAN_UP);
export const setFilter = createAction(SET_FILTER);
export const setPlan = createAction(SET_PLAN);
export const setActiveContract = createAction(SET_ACTIVE_CONTRACT);
export const setReContractInfo = createAction(SET_RE_CONTRACT_INFO);

// request
export const CONTRACT_SCHOOLS_REQUEST = action('CONTRACT_SCHOOLS_REQUEST');
export const CONTRACT_SCHOOLS_SUCCESS = action('CONTRACT_SCHOOLS_SUCCESS');
export const CONTRACT_SCHOOLS_FAILURE = action('CONTRACT_SCHOOLS_FAILURE');

export const CONTRACT_SCHOOLS_VIEW_REQUEST = action('CONTRACT_SCHOOLS_VIEW_REQUEST');
export const CONTRACT_SCHOOLS_VIEW_SUCCESS = action('CONTRACT_SCHOOLS_VIEW_SUCCESS');
export const CONTRACT_SCHOOLS_VIEW_FAILURE = action('CONTRACT_SCHOOLS_VIEW_FAILURE');

export const CONTRACT_MESSAGE_REQUEST = action('CONTRACT_MESSAGE_REQUEST');
export const CONTRACT_MESSAGE_SUCCESS = action('CONTRACT_MESSAGE_SUCCESS');
export const CONTRACT_MESSAGE_FAILURE = action('CONTRACT_MESSAGE_FAILURE');

export const USAGE_STATEMENT_REQUEST = action('USEAGE_STATEMENT_REQUEST');
export const USAGE_STATEMENT_SUCCESS = action('USEAGE_STATEMENT_SUCCESS');
export const USAGE_STATEMENT_FAILURE = action('USEAGE_STATEMENT_FAILURE');

// request action
export const contractSchoolsPender = createRequestAction(
    CONTRACT_SCHOOLS_REQUEST,
    CONTRACT_SCHOOLS_SUCCESS,
    CONTRACT_SCHOOLS_FAILURE
);

export const contractSchoolsViewPender = createRequestAction(
    CONTRACT_SCHOOLS_VIEW_REQUEST,
    CONTRACT_SCHOOLS_VIEW_SUCCESS,
    CONTRACT_SCHOOLS_VIEW_FAILURE
);

export const contractMessagePender = createRequestAction(
    CONTRACT_MESSAGE_REQUEST,
    CONTRACT_MESSAGE_SUCCESS,
    CONTRACT_MESSAGE_FAILURE
);
