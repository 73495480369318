import React from 'react';
import styled, { css } from 'styled-components';

import Loading from '../Loading/Loading';

const ButtonStyled = styled.button`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 1px solid;
    cursor: pointer;
    transition: all 0.2s;
    word-break: keep-all;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);

    & + * {
        margin-left: 10px;
    }

    &.ghost {
        background-color: transparent;
    }

    &:disabled {
        ${(props) => props.theme.disabled}
    }

    i {
        vertical-align: middle;
        font-size: 1.6rem;
        padding: 0 4px;
    }

    ${(props) =>
        props.block &&
        css`
            width: 100%;
        `}

    ${(props) =>
        props.round &&
        css`
            border-radius: 40px;
        `}

    ${(props) => {
        switch (props.size) {
            case 'large':
                return css`
                    padding: 0 1.6rem;
                    height: 48px;
                    font-size: 1.4rem;
                `;
            case 'small':
                return css`
                    padding: 0 1.2rem;
                    height: 38px;
                    font-size: 1.2rem;
                `;
            case 'label':
                return css`
                    padding: 2px 6px;
                    font-size: 1.1rem;
                `;
            default:
                return css`
                    padding: 0 1.6rem;
                    height: 42px;
                    font-size: 1.25rem;
                `;
        }
    }}

    ${(props) => props.loading && props.theme.disabled}

    ${(props) => props.theme[props.color]}
`;

const Button = ({
    block,
    children,
    color,
    className,
    disabled,
    size,
    style,
    loading,
    type,
    round,
    iconPosition,
    onClick,
}) => {
    function clickHandler(e) {
        if (!loading && onClick) {
            onClick(e);
        }
    }

    return (
        <ButtonStyled
            style={style}
            type={type}
            className={className}
            color={color}
            block={block}
            size={size}
            round={round}
            iconPosition={iconPosition}
            loading={loading}
            disabled={disabled}
            onClick={clickHandler}
        >
            {loading ? <Loading loading={loading} mode="cycle" /> : children}
        </ButtonStyled>
    );
};

Button.defaultProps = {
    size: 'middle',
    block: false,
    disabled: false,
    color: 'defaulten',
    type: 'button',
};

export default Button;
