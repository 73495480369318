import React from 'react';
import * as routerDom from 'react-router-dom';
import styled, { css } from 'styled-components';

const PureLink = routerDom.Link;

const LinkStyled = styled.div.attrs({
    className: 'link',
})`
    display: inline-block;
    cursor: pointer;
    font-size: ${(props) => props.fontSize}rem;

    a {
        color: #24344b;
    }

    & + *:not(ul) {
        margin-left: 10px;
    }

    ${(props) =>
        props.underline &&
        css`
            text-decoration: underline;
        `}

    ${(props) =>
        props.color &&
        css`
            color: ${props.theme[props.color]};
        `};

    ${(props) =>
        props.disabled &&
        css`
            color: ${props.theme.gray[5]};
            cursor: not-allowed;
        `}
`;

const defaultProps = {
    fontSize: 1.4,
};

const Link = ({ children, to, underline, download, disabled, href, target, color, fontSize, onClick, ...props }) => {
    if (to) {
        return (
            <LinkStyled underline={underline} color={color} disabled={disabled} fontSize={fontSize} {...props}>
                <PureLink to={to}>{children}</PureLink>
            </LinkStyled>
        );
    }

    if (download) {
        return (
            <LinkStyled color={color} fontSize={fontSize} disabled={disabled} {...props}>
                <a href={href} download={download} target={target}>
                    {children}
                </a>
            </LinkStyled>
        );
    }

    return (
        <LinkStyled
            color={color}
            underline={underline}
            disabled={disabled}
            fontSize={fontSize}
            onClick={onClick}
            {...props}
        >
            {children}
        </LinkStyled>
    );
};

Link.defaultProps = defaultProps;

export default Link;
