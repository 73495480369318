import React from 'react';
import styled from 'styled-components';
import { classJoin } from '../../../lib/utils';

const LoadingPrentStyled = styled.div`
    font-size: 1.4rem;
    width: 100%;
    color: ${(props) => props.theme.primary};
`;

const LoadingStyled = styled.div`
    position: relative;
    width: 100%;
    font-size: 1.4rem;
    height: 10px;
    border-radius: 10px;
    overflow: hidden;
    margin: 16px 0;
    background-color: #f5f5f5;

    &:after {
        content: '';
        position: absolute;
        display: block;
        left: -150px;
        width: 100px;
        height: inherit;
        border-radius: 6px;
        transform: translateX(0%);
        animation: ${(props) => props.theme.Animation.bar} 1.5s linear infinite;
    }
`;

const CycleStyled = styled.i`
    animation: ${(props) => props.theme.Animation.rotate} 1s linear infinite;
`;

const defaultProps = {
    loading: false,
};

const Loading = ({ loading, mode, children }) => {
    const classNames = classJoin({ ani: loading });

    if (!loading) {
        return '';
    }

    if (mode === 'cycle') {
        return <CycleStyled className="fas fa-spinner" style={{ fontSize: '1.6rem' }} />;
    }

    return (
        <LoadingPrentStyled>
            {children}
            <LoadingStyled className={classNames} />
        </LoadingPrentStyled>
    );
};

Loading.defaultProps = defaultProps;

export default Loading;
