import React from 'react';
import styled from 'styled-components';

const CardImageStyled = styled.div`
    height: ${(props) => (props.height ? props.height : '200px')};
    background-image: url(${(props) => props.src});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-bottom: 1px solid ${(props) => props.theme.gray[1]};
`;

const CardImage = ({ src, height }) => {
    return <CardImageStyled src={src} height={height} />;
};

export default CardImage;
