import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { flex_box, Text } from '../../dressroom/layout';

import { Float, Title } from '../';
import ModalFooter from './ModalFooter';
import { useToggle } from '../../../lib/hooks';

const ModalStyled = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1010;
    overflow: auto;
    padding-bottom: 15vh;
    ${flex_box({ justify: 'center', align: 'flex-start' })};

    ${(props) =>
        props.visible
            ? `
        visibility: visible;
        opacity: 1;
        `
            : `
        visibility: hidden;
        opacity: 0;
        `}

    transition: all .25s;

    .modal {
        &-content {
            margin-top: 10vh;
            min-width: 300px;
            background-color: #ffffff;
            z-index: 1011;
            font-size: 1.4rem;
        }

        &-header {
            padding: 0 20px;
            height: 56px;
            line-height: 56px;

            i {
                cursor: pointer;
            }
        }

        &-body {
            position: relative;
            padding: 4px 20px 16px;
        }
    }
`;

const defaultProps = {
    footer: null,
};

const Modal = ({ children, footer, title, visible, width, onClose, beforeClose }) => {
    const [ableClose, setAbleClose] = useToggle(true);
    function modalHandler(e) {
        if (beforeClose && typeof beforeClose === 'function') {
            beforeClose(onClose);
        } else {
            onClose();
        }
    }

    function clickForButton() {
        modalHandler();
    }

    const mouseUpHandler = (e) => {
        const isClose = e.target.getAttribute('data-target');
        // 마우스 업 과 다운이 모두 true 여야 닫을 수 있음
        if (ableClose && isClose === 'close') {
            modalHandler();
        }
    };

    const mounseDownHandler = (e) => {
        const isClose = e.target.getAttribute('data-target');

        // model content 부분을 클릭하면 false 가 됨
        setAbleClose(isClose === 'close');
    };

    const stylus = useCallback(
        () => ({
            width: width ? width + 'px' : null,
        }),
        [width]
    );

    useEffect(() => {
        if (!visible) {
            // close
        }
    }, [visible]);

    return (
        <ModalStyled
            className="modal"
            visible={visible}
            onMouseDown={mounseDownHandler}
            onMouseUp={mouseUpHandler}
            data-target="close"
        >
            <div className="modal-content" style={stylus()}>
                <div className="modal-header clear-both">
                    <Float left bold>
                        <Title size={1.8}>{title}</Title>
                    </Float>
                    <Float right>
                        <Text size={1.6} onClick={clickForButton}>
                            <i className="fas fa-times none-middle"></i>
                        </Text>
                    </Float>
                </div>
                <div className="modal-body">{children}</div>
                {footer && (
                    <ModalFooter mode={footer.mode}>
                        <button onClick={footer.onSubmit}>{footer.confirmText || '확인'}</button>
                        <button onClick={footer.onCancel || clickForButton}>{footer.cancleText || '취소'}</button>
                    </ModalFooter>
                )}
            </div>
        </ModalStyled>
    );
};

Modal.defaultProps = defaultProps;

export default Modal;
