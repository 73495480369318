import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { TableCover, TStyled, THStyled, TH, TR, TBStyled } from './table';

import { Loading } from '../';
import TableItem from './TableItem';
import ColGroup from './ColGroup';

import { sortinum, nexort } from '../../../lib/utils';

const Table = ({
    className,
    board,
    config,
    id,
    fields,
    rowFocus,
    headFixed,
    loading,
    todos,
    rowClickHandler,
    rowExpended: EXP,
}) => {
    const [sorts, setSorts] = useState({});

    useEffect(() => {
        if (Object.keys(sorts).length === 0) {
            fields
                .filter(({ sortable }) => !!sortable)
                .forEach((fd) => {
                    setSorts((prevState) => ({
                        ...prevState,
                        [fd.column]: 'asc',
                    }));
                });
        }

        return function cleanup() {};
    }, [fields]);

    const sortHandler = ({ column, sortable, onSort }) => {
        if (!sortable) return;
        // unicode 변경 후 데이터 정렬 시키기
        const next = nexort(sorts[column]);
        setSorts({ ...sorts, [column]: next });

        if (!!sortable && onSort && typeof onSort === 'function') {
            onSort(column, next);
            return;
        }

        sortinum[next](todos, column);
    };

    const options = { board, clickable: rowClickHandler ? true : false };

    // for config static width
    const cols = fields.map(({ width }) => {
        let number = parseInt(width);
        return !isNaN(number) ? width : null;
    });

    return (
        <TableCover>
            <TStyled id={id} className={`table ${className}`} {...options}>
                {cols.length && <ColGroup cols={cols} />}
                <THStyled headFixed={headFixed}>
                    <TR className="row-header">
                        {fields.map((fd, i) => {
                            const { title, align, sortable, column, onSort } = fd;

                            const _title = typeof title === 'function' ? title(fd, i, { onSort }) : title;

                            return (
                                <TH
                                    key={`th_${i}`}
                                    data-colindex={i}
                                    align={align}
                                    sortable={sortable}
                                    sort={sorts[column]}
                                    onClick={() =>
                                        sortHandler({
                                            column,
                                            sortable,
                                            onSort,
                                        })
                                    }
                                >
                                    <div>{_title}</div>
                                </TH>
                            );
                        })}
                    </TR>
                </THStyled>
                <TBStyled>
                    {!loading && config.emptyText && todos.length === 0 ? (
                        <TR>
                            <td className="empty" colSpan={fields.length}>
                                {config.emptyText}
                            </td>
                        </TR>
                    ) : (
                        todos.map((todo, index) => {
                            const focus =
                                rowFocus &&
                                rowFocus.findIndex((v) => {
                                    const key = Object.keys(v)[0];
                                    return todo[key] === v[key];
                                }) !== -1;

                            return (
                                <TableItem
                                    key={todo.id || index}
                                    row={index}
                                    todo={todo}
                                    focus={focus}
                                    column={fields}
                                    emptyValue={config.emptyValue}
                                    onRowClick={rowClickHandler}
                                    EXP={EXP}
                                />
                            );
                        })
                    )}
                </TBStyled>
            </TStyled>
            <Loading loading={loading} />
        </TableCover>
    );
};

Table.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.object),
    todos: PropTypes.arrayOf(PropTypes.object),
    config: PropTypes.object,
};

Table.defaultProps = {
    fields: [],
    todos: [],
    loading: false,
    config: {
        emptyText: null,
        emptyValue: '-',
    },
    rowClickHandler: null,
    rowExpended: null,
};

export default React.memo(Table);
