export { Alert } from './Alert';
export { Button } from './Button';
export { Card } from './Card';
export { Calendar } from './Calendar';
export { Checkbox } from './Checkbox';
export { Column } from './Column';
export { ComboBox } from './ComboBox';
export { ComboBoxV2 } from './ComboBoxV2';
export { Divider } from './Divider';
export { Dropdown } from './Dropdown';
export { Float } from './Float';
export { Image } from './Image';
export { Input } from './Input';
export { InputGroup } from './InputGroup';
export { Label } from './Label';
export { Link } from './Link';
export { Loading } from './Loading';
export { Modal } from './Modal';
export { Pagination } from './Pagination';
export { Radio } from './Radio';
export { RadioGroup } from './RadioGroup';
export { Row } from './Row';
export { Selector } from './Selector';
export { SubTitle } from './SubTitle';
export { Switch } from './Switch';
export { Table } from './Table';
export { Tab } from './Tab';
export { Textarea } from './Textarea';
export { Title } from './Title';
export { Tooltip } from './Tooltip';
export { Upload } from './Upload';
export { UploadV2 } from './UploadV2';

// * auto exportor
// const importer = require.context('./', true, /(index\.js)$/);

// importer.keys().forEach(path => {
//     const key = path.replace(/\.\/|\.jsx|\.js|(\/index)/g, '');
//     if (key !== 'index') {
//         module.exports[key] = importer(path)[key];
//     }
// });
