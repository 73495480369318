import React from 'react';
import PropTypes from 'prop-types';

import { TR, TD } from './table';
import { isEmpty } from '../../../lib/utils';

const TableItem = ({ todo, column, row, focus, onRowClick, emptyValue }) => {
    function eventClickHandler(event) {
        if (onRowClick && typeof onRowClick === 'function') {
            onRowClick(todo, row, event);
        }
    }

    return (
        <TR data-focus={focus} data-rowindex={row} onClick={eventClickHandler}>
            {column.map((fd, i) => {
                const key = `${row}_${i}`;

                const children =
                    fd.render !== undefined && typeof fd.render === 'function'
                        ? fd.render(todo, row)
                        : todo[fd.column];

                return (
                    <TD key={key} data-colindex={i} align={fd.align}>
                        {isEmpty(children) ? emptyValue : children}
                    </TD>
                );
            })}
        </TR>
    );
};

TableItem.propTypes = {
    todo: PropTypes.object,
    fields: PropTypes.arrayOf(PropTypes.object),
    row: PropTypes.number,
    emptyValue: PropTypes.string,
    onRowClick: PropTypes.func
};

TableItem.defaultProps = {
    todo: {},
    emptyValue: '-'
};

export default React.memo(TableItem);
