import axios from 'axios';

export default {
    school_search: (params) =>
        axios.get(`https://api.schoolt.net/util/searchSchool`, { params, limit: 100 }),

    school_board_types: (params) =>
        axios.get(`https://api.schoolt.net/util/board_type`, { params }),

    get_school_board: (params) => axios.get(`https://api.schoolt.net/util/board_list`, { params }), // board_type=1901&page=1&limit=1

    run_crawler: ({ smom_id, type }) =>
        axios.post(`https://crawler.schoolmom.co.kr/school/${smom_id}/board_type/${type}/notice`),

    school_board_detail: (params) =>
        axios.get('https://api.schoolt.net/util/board_detail', { params }),

    school_board_delete: (data) =>
        axios.delete('https://api.schoolt.net/util/board_detail', { data }),

    file_upload: (data) => {
        return axios.post('https://file.school-mom.kr/uploadFile2.php', data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    },

    error_test: (code) => {
        return axios.get('http://schoolmom.co.kr:3001/fail_api?fail_type=' + code);
    },
};
