import React from 'react';
import styled from 'styled-components';
import { flex_box } from '../../dressroom/layout';

const InputGroupStyled = styled.div`
    ${flex_box()}

    input,
    button {
        margin-left: 0;
    }

    input {
        border-radius: 5px 0 0 5px;
    }

    button {
        border-radius: 0 5px 5px 0;
        transform: translateX(-1px);
    }
`;

const InputGroup = ({ children }) => {
    return <InputGroupStyled className="s-inputgroup">{children}</InputGroupStyled>;
};

export default InputGroup;
