import fetch from './instance';

export default {
    // SK 수신메시지 조회
    get: (phone) => fetch.get(`/users/sk-messages?phone=${phone}`),

    // 메시지 발송내역
    // 메시지 발송내역 상세
    // 실패한 메시지 다시보내기

    get_message: (school_id, query) => fetch.get(`/services/schools/${school_id}/messages?${query}`),

    get_view_message: (school_id, message_request_id, query) =>
        fetch.get(`/services/schools/${school_id}/messages/${message_request_id}?${query}`),
    update_fail_message: (school_id, message_request_id) =>
        fetch.put(`/services/schools/${school_id}/messages/${message_request_id}`),
};
