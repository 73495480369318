import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import { TabContext } from './TabContext';

const TabPanalStyled = styled.div``;

const TabPanal = ({ children, label, tab }) => {
    const { tab: activeTab, setLabel } = useContext(TabContext);

    useEffect(() => {
        setLabel(label, tab);
    }, [setLabel, label, tab]);

    if (activeTab !== tab) {
        return null;
    }

    return <TabPanalStyled>{children}</TabPanalStyled>;
};

export default TabPanal;
