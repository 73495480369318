import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import DropdownItem from './DropdownItem';

import { agentEvent } from '../../../lib/utils';

const DropdownStyled = styled.div`
    position: relative;
    display: inline-block;

    ${(props) =>
        props.fit &&
        css`
            width: 100%;
            text-align: center;
        `}

    label {
        display: inline-block;
        width: 100%;
        cursor: pointer;
    }
`;

const DropdownItemsStyled = styled.div`
    position: absolute;
    right: 0;
    top: 100%;
    background-color: #fff;
    word-break: keep-all;
    white-space: nowrap;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);
    z-index: 1004;

    visibility: hidden;
    opacity: 0;
    transform: translateY(15px);
    transition: all 0.3s;

    ${(props) =>
        props.active &&
        css`
            visibility: visible;
            opacity: 1;
            transform: translateY(5px);
        `}
`;

const event = agentEvent('click');

const Dropdown = ({ children, items, fit, data }) => {
    const [active, setActive] = useState(false);
    const documentListener = useRef(null);

    const unbindDocumentListener = useCallback(() => {
        if (documentListener) {
            document.removeEventListener(event, documentListener.current);
            documentListener.current = null;
        }
    }, []);

    const bindDocumentListener = () => {
        if (!documentListener.current) {
            documentListener.current = () => {
                if (!active) hide();
            };
        }
        document.addEventListener(event, documentListener.current);
    };

    const show = () => {
        setActive(true);
        bindDocumentListener();
    };

    const hide = () => {
        setActive(false);
        unbindDocumentListener();
    };

    const clickHandler = () => {
        if (!active) {
            show();
        } else {
            hide();
        }
    };

    useEffect(() => {
        return function cleanup() {
            if (documentListener.current) unbindDocumentListener();
        };
    }, [unbindDocumentListener]);

    return (
        <DropdownStyled fit={fit}>
            <label onClick={clickHandler}>{children}</label>
            <DropdownItemsStyled active={active}>
                {items.map(({ label, ...props }, index) => (
                    <DropdownItem key={index} data={data} {...props}>
                        {label}
                    </DropdownItem>
                ))}
            </DropdownItemsStyled>
        </DropdownStyled>
    );
};

Dropdown.defaultProps = {
    items: [
        {
            label: null,
            icon: null,
            handler: null,
        },
    ],
    fit: false,
    id: null,
};

Dropdown.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    fit: PropTypes.bool,
};

export default Dropdown;
