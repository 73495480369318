import { handleActions } from 'redux-actions';
import { produce } from 'immer';
import {
    NOTICES_FAILURE,
    NOTICES_REQUEST,
    NOTICES_SUCCESS,
    NOTICE_DETAIL_FAILURE,
    NOTICE_DETAIL_REQUEST,
    NOTICE_DETAIL_SUCCESS,
    NOTICE_TYPES_FAILURE,
    NOTICE_TYPES_REQUEST,
    NOTICE_TYPES_SUCCESS,
} from './actions';

const initialState = {
    loading: {
        notice: false,
        detail: false,
    },

    count: {
        notice: false,
    },

    notices: [],

    types: [],

    detail: null,
    detailComment: [],
    detailFile: [],
};

export default handleActions(
    {
        [NOTICES_FAILURE]: (state) =>
            produce(state, (draft) => {
                draft.loading.notice = false;
            }),

        [NOTICES_REQUEST]: (state) =>
            produce(state, (draft) => {
                draft.loading.notice = true;
                draft.notices = [];
            }),

        [NOTICES_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                const { data, count } = action.payload;
                draft.loading.notice = false;
                draft.count.notice = count;

                draft.notices = data;
            }),

        [NOTICE_TYPES_FAILURE]: (state) => produce(state, (draft) => {}),
        [NOTICE_TYPES_REQUEST]: (state) => produce(state, (draft) => {}),

        [NOTICE_TYPES_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.types = action.payload;
            }),

        [NOTICE_DETAIL_FAILURE]: (state) =>
            produce(state, (draft) => {
                draft.loading.detail = false;
            }),

        [NOTICE_DETAIL_REQUEST]: (state) =>
            produce(state, (draft) => {
                draft.loading.detail = true;
            }),

        [NOTICE_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                const { data, comment, file } = action.payload;
                draft.loading.detail = false;

                draft.detailComment = comment;
                draft.detailFile = file;
                draft.detail = data;
            }),
    },
    initialState
);
