import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import SideNavUser from './SideNavUser';

const NavStyled = styled.nav`
    position: absolute;
    width: ${(props) => props.theme.size.nav}px;
    height: 100vh;
    top: 0;
    left: ${(props) => (props.side ? -props.theme.size.nav : 0)}px;
    user-select: none;
    transition: left 0.7s;
    z-index: 9;
    background-color: ${(props) => props.theme.primary};
    overflow-y: auto;

    .nav-header {
        padding: 16px;
        display: none;

        i {
            font-size: 1.6rem;
            color: #fff;
        }
    }

    ul {
        width: 100%;

        li {
            font-size: 1.3rem;

            padding-bottom: 8px;

            div.nav-title {
                font-size: 1.1rem;
                color: #ffffff;
                padding: 0 16px;
                margin-top: 16px;
                margin-bottom: 8px;
            }

            a + a {
                margin-top: 5px;
            }

            a {
                display: block;
                width: ${(props) => props.theme.size.nav - 24}px;
                margin: 0 auto;
                padding: 0 16px;
                height: 36px;
                line-height: 36px;
                letter-spacing: -0.5px;
                color: #ffffff;
                cursor: pointer;
                border-radius: 3px;
                transition: background 0.3s;

                &:hover,
                &.active {
                    background: linear-gradient(to right, #311af7, #0095ff);
                }
            }

            ul li {
                padding-left: 4px;
            }
        }
    }

    .slide-button {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 24px;
      background-color: ${(props) => props.theme.primary};
      display: flex;
      align-items: center;
      justify-content: center;

      left: 100%;

      i {
        color: #ffffff
        font-size: 24px;
      }
    }
`;

const SideNav = ({ user, side, onSideHandler }) => {
    return (
        <NavStyled side={side}>
            <SideNavUser user={user} />
            <ul>
                <li>
                    <div className="nav-title">스쿨투게더</div>
                    <ul>
                        <NavLink to="/dashboard" activeClassName="active">
                            <li>대시보드</li>
                        </NavLink>
                        <NavLink to="/notice" activeClassName="active">
                            <li>공지사항</li>
                        </NavLink>
                        <NavLink to="/email" activeClassName="active">
                            <li>이메일</li>
                        </NavLink>
                    </ul>
                </li>
            </ul>
            <ul>
                <li>
                    <div className="nav-title">학교관리</div>
                    <ul>
                        <NavLink to="/contract" activeClassName="active">
                            <li>계약학교</li>
                        </NavLink>
                        <NavLink
                            to="/schools"
                            activeClassName="active"
                            isActive={(match, location) => /(schools)/g.test(location.pathname)}
                        >
                            <li>스쿨투게더 이용학교</li>
                        </NavLink>
                        <NavLink to="/performance" activeClassName="active">
                            <li>개별서버 성능</li>
                        </NavLink>
                        <NavLink to="/numbers" activeClassName="active">
                            <li>추가번호 관리</li>
                        </NavLink>
                        <NavLink to="/board" activeClassName="active">
                            <li>게시물 관리</li>
                        </NavLink>
                        <NavLink to="/nice" activeClassName="active">
                            <li>NICE 학교정보 검색</li>
                        </NavLink>
                        <NavLink to="/talk" activeClassName="active">
                            <li>쪽지 신고</li>
                        </NavLink>
                    </ul>
                </li>
            </ul>
            <ul>
                <li>
                    <div className="nav-title">회원관리</div>
                    <ul>
                        <NavLink to="/user" activeClassName="active">
                            <li>사용자 조회</li>
                        </NavLink>
                        <NavLink to="/teachers" activeClassName="active">
                            <li>선생님 조회</li>
                        </NavLink>
                        <NavLink to="/message" activeClassName="active">
                            <li>SK 수신메시지 조회</li>
                        </NavLink>
                        <NavLink to="/qna" activeClassName="active">
                            <li>고객문의 (QnA)</li>
                        </NavLink>
                        <NavLink to="/teacher-qna" activeClassName="active">
                            <li>선생님문의 (QnA)</li>
                        </NavLink>
                        <NavLink to="/survey" activeClassName="active">
                            <li>2020 설문 결과</li>
                        </NavLink>
                    </ul>
                </li>
            </ul>

            <ul>
                <li>
                    <div className="nav-title">동행 [임시]</div>
                    <ul>
                        <NavLink to="/church" activeClassName="active">
                            <li>교회 및 관리자 설정</li>
                        </NavLink>
                    </ul>
                </li>
            </ul>
        </NavStyled>
    );
};

export default SideNav;
