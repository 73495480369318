import React from 'react';
import styled, { css } from 'styled-components';

export const FLEX_BOX_TYPE = {
    justify: null,
    align: null,
    column: false,
    compose: null,
};

export const flex_box = (props = FLEX_BOX_TYPE, join = {}) => {
    const { justify, align, column } = props;
    return css`
        display: flex;
        justify-content: ${!justify ? 'flex-start' : justify};
        align-items: ${!align ? 'center' : align};
        ${column && 'flex-direction: column'}
        ${join}
    `;
};

const TextOverflowStyle = styled.div`
    display: flex;
    ${(props) =>
        props.block &&
        css`
            flex: 1;
        `}

    span {
        flex: 1;
        width: 1px;

        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
    }
`;

export const TextOverflow = ({ children, block, ...props }) => {
    return (
        <TextOverflowStyle block={block}>
            <Text {...props}>{children}</Text>
        </TextOverflowStyle>
    );
};

export const TextGroup = styled.div`
    & > span + span {
        margin-left: ${(props) => props.gutter}rem;
    }

    & > span,
    i,
    div {
        ${(props) =>
            props.cursor &&
            css`
                cursor: ${props.cursor};
            `}
    }
`;

export const Text = styled.span`
    display: inline-block;

    ${(props) =>
        props.size &&
        css`
            font-size: ${props.size}rem;
        `};

    ${(props) =>
        props.align &&
        css`
            text-align: ${props.align};
        `}

    ${(props) =>
        props.bold &&
        css`
            font-weight: bold;
        `}
    ${(props) =>
        props.underline &&
        css`
            text-decoration: underline;
        `}
    ${(props) =>
        props.error &&
        css`
            color: ${props.theme.red};
        `}
    ${(props) =>
        props.width &&
        css`
            width: ${props.width};
        `}

    ${(props) =>
        props.color &&
        css`
            color: ${props.theme[props.color]};

            i {
                color: ${props.theme[props.color]};
            }
        `};

    ${(props) =>
        props.cursor &&
        css`
            cursor: ${props.cursor};
        `}
`;

export const SchoolIcon = styled.div`
    display: flex;
    align-items: center;

    img {
        width: 36px;
        margin-right: 16px;
        box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.12);
    }

    .fa-laugh-beam {
        color: ${(props) => props.theme.blue};
        margin-left: 6px;
    }
`;
