// import {  } from 'redux-actions';
import { actionString, createRequestAction } from '../helper';
import { createAction } from 'redux-actions';

const action = actionString('schoolV2');

export const SET_REMOVE_ACCOUNT = action('SET_REMOVE_ACCOUNT');
export const SET_UPDATE_NAME = action('SET_UPDATE_NAME');
export const SET_UPDATE_GATE = action('SET_UPDATE_GATE');
export const SET_REMOVE_GATE = action('SET_REMOVE_GATE');

export const setRemoveAccount = createAction(SET_REMOVE_ACCOUNT);
export const setUpdateName = createAction(SET_UPDATE_NAME);
export const setUpdateGate = createAction(SET_UPDATE_GATE);
export const setRemoveGate = createAction(SET_REMOVE_GATE);

export const CHURCH_LIST_REQUEST = action('CHURCH_LIST_REQUEST');
export const CHURCH_LIST_SUCCESS = action('CHURCH_LIST_SUCCESS');
export const CHURCH_LIST_FAILURE = action('CHURCH_LIST_FAILURE');

export const CHURCH_VIEW_REQUEST = action('CHURCH_VIEW_REQUEST');
export const CHURCH_VIEW_SUCCESS = action('CHURCH_VIEW_SUCCESS');
export const CHURCH_VIEW_FAILURE = action('CHURCH_VIEW_FAILURE');

export const CHURCH_GATE_REQUEST = action('CHURCH_GATE_REQUEST');
export const CHURCH_GATE_SUCCESS = action('CHURCH_GATE_SUCCESS');
export const CHURCH_GATE_FAILURE = action('CHURCH_GATE_FAILURE');

// request action
export const churchListPender = createRequestAction(CHURCH_LIST_REQUEST, CHURCH_LIST_SUCCESS, CHURCH_LIST_FAILURE);

export const churchViewPender = createRequestAction(CHURCH_VIEW_REQUEST, CHURCH_VIEW_SUCCESS, CHURCH_VIEW_FAILURE);

export const churchGatePender = createRequestAction(CHURCH_GATE_REQUEST, CHURCH_GATE_SUCCESS, CHURCH_GATE_FAILURE);
