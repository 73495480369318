import React from 'react';
import { positions } from './options';
import AlertStyles, { defaultStyle } from './AlertStyles';

const AlertWrapper = ({ alerts, position, template: AlertComponent, duration }) => {
    let pos = positions[position];

    if (pos === undefined) {
        console.warn(`${position} is not position name`);
    }

    const style = AlertStyles(pos);

    return (
        <div style={{ ...defaultStyle, ...style }} className="alert-warpper">
            {alerts.map(({ id, message, options, close }, index) => {
                return (
                    <AlertComponent
                        key={id}
                        id={id}
                        duration={duration}
                        message={message}
                        options={options}
                        onClose={close}
                    />
                );
            })}
        </div>
    );
};

export default AlertWrapper;
