import React, { Component } from 'react';
import { types } from './options';
import AlertWrapper from './AlertWrapper';

export const AlertContext = React.createContext();

const { Provider, Consumer: AlertConsumer } = AlertContext;

class AlertProvider extends Component {
    state = {
        alerts: [],
        id: 0,
    };

    show = (message = '', options) => {
        const { alerts, id } = this.state;
        const { duration } = this.props;

        const alert = {
            id,
            message,
            options,
        };

        alert.close = () => this.remove(alert);

        this.setState({ alerts: [...alerts, alert], id: id + 1 });

        if (duration) {
            // const timer =
            setTimeout(() => {
                alert.close();
            }, duration);

            // if (options.type === types.DOWNLOAD) {
            //     clearTimeout(timer);
            // }
        }
    };

    update = (index, options) => {
        this.setState((prevState) => {
            prevState.alerts[index].options = options;
            return {
                alerts: [...prevState.alerts],
            };
        });
    };

    remove = (target) => {
        this.setState((prevState) => {
            const alerts = prevState.alerts.filter((a) => a.id !== target.id);
            return {
                alerts,
            };
        });
    };

    success = (message = '', options = {}) => {
        options.type = types.SUCCESS;
        this.show(message, options);
    };

    info = (message = '', options = {}) => {
        options.type = types.INFO;
        this.show(message, options);
    };

    warning = (message = '', options = {}) => {
        options.type = types.WARNING;
        this.show(message, options);
    };

    error = (message = '', options = {}) => {
        options.type = types.ERROR;
        this.show(message, options);
    };

    download = (message = '', options = {}) => {
        options.type = types.DOWNLOAD;
        const index = this.findUnique(options.uniqueKey);

        if (index !== -1) {
            this.update(index, options);
        } else {
            this.show(message, options);
        }
    };

    findUnique = (uniqueKey) => {
        return this.state.alerts.findIndex(({ options }) => {
            if (options.type === 'download') {
                return uniqueKey === options.uniqueKey;
            } else {
                return false;
            }
        });
    };

    render() {
        const { show, success, info, warning, error, remove, download } = this;

        const { children, template: AlertComponent, position, duration } = this.props;

        const { alerts } = this.state;

        const actions = {
            show,
            success,
            info,
            warning,
            error,
            remove,
            download,
        };

        const value = {
            actions,
        };

        return (
            <Provider value={value}>
                {children}
                <AlertWrapper alerts={alerts} template={AlertComponent} position={position} duration={duration} />
            </Provider>
        );
    }
}

AlertProvider.defaultProps = {
    options: {
        type: 'default',
    },
    position: 'TOP_CENTER',
    duration: 2500,
};

export { AlertProvider, AlertConsumer };
