import fetch from './instance';

export default {
    login: ({ password }) => fetch.post('/admin_login', { password }),
    new_login: ({ id, password }) => fetch.post('/auth/login', { id, password }),
    qnaCategory: () => fetch.get('/services/web-qa-category'),
    qnaList: (params) => fetch.get('/services/web-qa-boards', { params }),
    qnaView: (qa_id) => fetch.get(`/services/web-qa-boards/${qa_id}`),
    qnaReply: (qa_id, data) => fetch.post(`/services/web-qa-boards/${qa_id}/answers`, data),
    qnaReplyUpdate: (qa_id, reply_id, data) =>
        fetch.put(`/services/web-qa-boards/${qa_id}/answers/${reply_id}`, data),
    qnaReplyDelete: (qa_id) => fetch.delete(`/services/web-qa-boards/${qa_id}/answers`),
    qnaComplated: (qa_id) => fetch.post(`/services/web-qa-boards/${qa_id}/finish`),

    // 사용자 조회
    searchUser: (phone) => fetch.get(`/manages/app-users?phone=${phone}`),
    alramUpdate: (data) => fetch.put(`/manages/app-users`, data),

    // phone, page, limit
    messageHistory: (params) => fetch.get(`/users/messages`, { params }),

    getEmails: (params) => fetch.get('/emails', { params }),
    setEmail: (data) => fetch.post('/emails', data),
    updateEmail: (id, data) => fetch.put(`/emails/${id}`, data),
    deleteEmail: (ids) => fetch.delete(`/emails/${ids}`),

    sendEmail: (data) => fetch.post('/emails/send', data),
    sendedEmailHistory: (params) => fetch.get('/emails/history', { params }),

    surveyEvent: (params) => fetch.get('/event/results', { params }),
    surveyChecked: (param, body) => fetch.put(`/event/results/${param}`, body),

    // teacher qna
    teacherQnaList: (params) => fetch.get('/services/teacher-qa-boards', { params }),
    teacherQnaView: (id) => fetch.get(`/services/teacher-qa-boards/${id}`),
    teacherQnaAnswer: (id, data) => fetch.post(`/services/teacher-qa-boards/${id}/answers`, data),
    teacherQnaAnswerUpdate: (id, aid, data) =>
        fetch.put(`/services/teacher-qa-boards/${id}/answers/${aid}`, data),
    teacherQnaFinish: (id) => fetch.post(`/services/teacher-qa-boards/${id}/finish`),
};
