import React, { useState } from 'react';
import { Modal, Input } from '../../components/common';

import './ConfirmWrapper.scss';
import { Calendar } from 'primereact/calendar';
import { yearRange } from '../../lib/date/utils';
import { language } from '../../lib/date/string';
import moment from 'moment';

const ConfirmWrapper = ({
    content,
    title,
    mode,
    promptType,
    defaultPromptOptions,
    visible,
    onAgree,
    onDisAgree,
    onClose,
}) => {
    const { placeholder } = defaultPromptOptions;
    const [value, setValue] = useState(defaultPromptOptions.value || null);

    return (
        <Modal
            width={350}
            title={title}
            visible={visible}
            onClose={onClose}
            footer={{
                mode: 'buttons',
                onSubmit: () => onAgree(value),
                onCancel: onDisAgree,
            }}
            mode="confirm"
        >
            <div className="Confirm">
                <div className="Confirm-content" dangerouslySetInnerHTML={{ __html: content }} />
                {mode !== 'prompt' || !promptType ? null : promptType === 'input' ? (
                    <Input placeholder={placeholder} value={value} onChange={(e) => setValue(e.target.value)}></Input>
                ) : (
                    <Calendar
                        dateFormat="yy-mm-dd"
                        value={value || defaultPromptOptions.value}
                        name="end_date"
                        monthNavigator={true}
                        yearNavigator={true}
                        yearRange={yearRange(2, 'string')}
                        locale={language}
                        placeholder="종료일"
                        onChange={({ target }) => target.value && setValue(moment(target.value).format('YYYY-MM-DD'))}
                    />
                )}
            </div>
        </Modal>
    );
};

export default ConfirmWrapper;
