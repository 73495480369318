import { handleActions } from 'redux-actions';
import { produce } from 'immer';

import { INITIALIZE, SET_PAGE, SET_LIMIT, UPDATE_FAIL, SET_SIDE } from './actions';

const initialState = {
    side: false,
    fail: false,
    err_message: null,
    contract: { page: 1, limit: 20 },
    contract_school: { page: 1, limit: 20 },
    contract_message: { page: 1, limit: 20 },
    service_message: { page: 1, limit: 20 },
    service_message_detail: { page: 1, limit: 20 },
    performance: { page: 1, limit: 20 },
    qna: { page: 1, limit: 20 },
    message: { page: 1, limit: 50 },
    notice: { page: 1, limit: 20 },
    nicel: { page: 1, limit: 100 },
    email_history: { page: 1, limit: 20 },
};

export default handleActions(
    {
        [SET_PAGE]: (state, action) =>
            produce(state, (draft) => {
                const { page, key } = action.payload;
                draft[key].page = page;
            }),
        [SET_LIMIT]: (state, action) =>
            produce(state, (draft) => {
                const { limit, key } = action.payload;
                draft[key].page = 1;
                draft[key].limit = limit;
            }),

        [INITIALIZE]: (state, action) =>
            produce(state, (draft) => {
                const key = action.payload;
                draft[key] = initialState[key];
            }),

        [UPDATE_FAIL]: (state, action) =>
            produce(state, (draft) => {
                draft.fail = !state.fail;
                draft.err_message = action.payload;
            }),

        [SET_SIDE]: (state) =>
            produce(state, (draft) => {
                draft.side = !draft.side;
            }),
    },
    initialState
);
