export function yearRange(range = 1, type = 'array', between = ':') {
    const result = [year() - range, year() + range];

    switch (type) {
        case 'string':
            return result.join(between);
        default:
            return result;
    }
}

export function dateFormat(format = 'yyyy-MM-DD', date) {
    const $date = date || Date.now();
    const includeT = /(T|Z)/g.test($date);
    const replace = includeT
        ? $date.toString()
        : $date.toString().replace(/-/g, '/');

    const _date = core(replace);

    return format.replace(/(yyyy|mm|dd|MM|DD|H|h|loc|i|s|a)/g, t => {
        switch (t) {
            case 'yyyy':
                return _date.getFullYear();
            case 'mm':
                return _date.getMonth() + 1;
            case 'dd':
                return _date.getDate();
            case 'MM':
                return zero(_date.getMonth() + 1);
            case 'DD':
                return zero(_date.getDate());
            case 'H':
                return zero(_date.getHours());
            case 'h':
                return minHour(_date.getHours());
            case 'i':
                return zero(_date.getMinutes());
            case 's':
                return zero(_date.getSeconds());
            case 'a':
                return krTime(_date.getHours()); // 오전 오후
            case 'loc':
                return 'T' + zero(_date.getHours()); // local : using for input type='datetime-local'
            default:
                return false;
        }
    });
}

export const core = date => {
    let _date = date;

    if (/^[0-9]+$/.test(date)) {
        _date = parseInt(_date, 10);
    }

    _date = date ? new Date(_date) : new Date();

    return _date;
};

export const year = date => core(date).getFullYear();
export const krTime = value => (value >= 0 && 12 >= value ? '오전' : '오후');
export const zero = value =>
    value.toString().length === 1 ? `0${value}` : value;
export const minHour = value => (value > 12 ? zero(value - 12) : zero(value));
