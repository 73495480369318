import React from 'react';
import styled, { css } from 'styled-components';

const DividerStyled = styled.div`
    ${(props) =>
        props.inline
            ? css`
                  display: inline-block;
              `
            : css`
                  display: block;
                  width: 100%;
              `}

    ${(props) =>
        props.vertical &&
        css`
            vertical-align: ${props.vertical};
        `}

    ${(props) =>
        props.textAlign &&
        css`
            text-align: ${props.textAlign};
        `}

    ${(props) =>
        props.hr &&
        css`
            padding-bottom: 1rem;
            border-bottom: 1px solid ${props.theme.line};
        `}

    margin-bottom: ${(props) => props.distance === 0 || props.distance || 1}rem;
`;

const Divider = ({ children, inline, vertical, textAlign, distance, hr, ...props }) => {
    return (
        <DividerStyled inline={inline} vertical={vertical} textAlign={textAlign} distance={distance} hr={hr} {...props}>
            {children}
        </DividerStyled>
    );
};

Divider.defaultProps = {
    inline: false,
};

export default Divider;
