import React from 'react';
import styled from 'styled-components';

import { flex_box } from '../../dressroom/layout';

import PaginationItem from './PaginationItem';
import PaginationArrow from './PaginationArrow';
import { Selector } from '../Selector';

const PaginationStyled = styled.div`
    padding: 6px 8px;

    & > div {
        padding: 6px 0;
        ${flex_box()}
    }

    .pagination-body {
        float: right;
        font-size: 1.4rem;
    }

    .pagination-info {
        float: left;
        font-size: 1.4rem;
    }
`;

const Pagination = ({ page = 1, count = 0, limit = 20, isLimit = true, block, onPage, onLimit }) => {
    let _block;
    const block_count = Math.ceil(count / limit);
    const _page = !count ? 0 : page;

    _block = block_count > block ? block : block_count;

    const total_block = Math.ceil(block_count / block);
    const current_block = Math.ceil(_page / block);
    const next_block = current_block * block + 1;
    const prev_block = (current_block - 1) * block;

    if (count && total_block === current_block) {
        const rest = block_count % block;
        _block = rest === 0 ? block : rest;
    }

    const numberic = _block < 0 ? 0 : _block;
    const block_to_array = Array.apply(null, Array(numberic));

    const pageItem = block_to_array.map((d, i) => {
        const p = i + 1 + block * (current_block - 1);
        return <PaginationItem key={i} page={p} active={p === _page} onPage={onPage} />;
    });

    const limitValue = limit || '50개씩 보기';

    return (
        <PaginationStyled className="clear-both">
            {isLimit && (
                <div className="pagination-info">
                    <Selector
                        placeholder="갯수"
                        value={limitValue}
                        options={[
                            { label: '20개씩 보기', value: 20 },
                            { label: '50개씩 보기', value: 50 },
                            { label: '100개씩 보기', value: 100 },
                            { label: '200개씩 보기', value: 200 },
                            { label: '500개씩 보기', value: 500 },
                            { label: '1000개씩 보기', value: 1000 },
                        ]}
                        onChange={onLimit && onLimit}
                    />
                </div>
            )}

            <div className="pagination-body">
                <PaginationArrow start disabled={current_block <= 1} onPage={() => onPage(1)} />
                <PaginationArrow prev disabled={current_block <= 1} onPage={() => onPage(prev_block)} />
                {pageItem}
                <PaginationArrow next disabled={current_block === total_block} onPage={() => onPage(next_block)} />
                <PaginationArrow end disabled={current_block === total_block} onPage={() => onPage(block_count)} />
            </div>
        </PaginationStyled>
    );
};

Pagination.defaultProps = {
    block: 10,
};

export default React.memo(Pagination, (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps) ? true : false;
});
