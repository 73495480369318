import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-day-picker/lib/style.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { ConfirmProvider } from './contexts/ConfirmProvider';
import { ThemeProvider } from 'styled-components';
import * as serviceWorker from './serviceWorker';

import store from './modules';
import * as theme from './styles/theme';

import App from './components/App';

import 'moment/locale/ko';

const AppProvider = ({ contexts, children }) =>
    contexts.reduce(
        (prev, context) =>
            React.createElement(context, {
                children: prev,
            }),
        children
    );

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <AppProvider contexts={[CookiesProvider, ConfirmProvider]}>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </AppProvider>
        </Router>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
