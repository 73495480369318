export const positions = {
    TOP_LEFT: 'top left',
    TOP_RIGHT: 'top right',
    TOP_CENTER: 'top center',
    BOTTOM_LEFT: 'bottom left',
    BOTTOM_RIGHT: 'bottom right',
    BOTTOM_CENTER: 'bottom center'
};

export const itv = {
    x: '2.4vw',
    y: '2.4vh'
};

export const types = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
    DOWNLOAD: 'download'
};
