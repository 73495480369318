export const msgType = {
    message: ['메시지', '회신', '개별메시지', '생활평점', '기타'],
    send: ['일반', '무료', '긴급'],
    approval: ['반려', '대기', '승인'],
    sendStatus: ['취소', '예약', '성공', '발송중'],
    sendStatusinFail: ['실패', '취소', '예약', '성공', '발송중'],
    sendStatusinView: ['실패', '대기', '성공'],
    statusColor: ['error', 'disabled', 'success'],
    receiver: {
        Student: '학생',
        Parent1: '학부모1',
        Parent2: '학부모2',
        student: '학생',
        parent1: '학부모1',
        parent2: '학부모2',
        Unknown: '기타',
        parents: '학부모',
    },
    receiveType: { SMS: 'SMS', LMS: 'LMS', APP: 'APP' },
};

export const staticAnswer = [
    `안녕하세요 스쿨투게더 고객센터입니다.
자녀분이 다니는 학교를 구독해주시면 '학교소식' 메뉴에서 공지사항과 가정통신문, 급식 알림을 받아보실 수 있습니다.
그 외 '메시지'는 학교에서 학부모님 전화번호로 직접 알림을 발송했을 때에만 확인 가능합니다.
고객님께 발송된 메시지는 0건으로 조회가 됩니다.
해당 사항은 학교에서 고객님께 문자 메시지를 보낸 적이 없는 것으로 판단되며, 메시지를 받기 위해서는 학교에 번호가 정상적으로 등록되었는지 먼저 확인이 필요합니다.
대부분의 학교는 학부모 번호 한 개로 알림을 보내고 있기 때문에 저장되어있는 대표 번호 확인 및 변경 요청은 학교로 문의 주시기 바랍니다.
감사합니다.`,

    `안녕하세요  스쿨투게더 고객센터입니다.
우선 휴대폰이 절전 모드인지 확인해주세요.
그 다음 휴대폰 설정에 가셔서 '알림' - '최근에 알림  보낸 앱' - 더보기- 전체로 변경, 스쿨투게더를 찾아 활성화 해주세요.
그래도 메시지 알림이 정상적으로 오지 않는다면 앱 삭제 후 재설치 부탁 드립니다.
감사합니다.`,

    `안녕하세요 스쿨투게더 고객센터입니다.
고객님의 수신내역을 확인해 보니 누락이 아니라 학교에서 보낸 메시지를 일반 휴대폰 문자로 받고 있습니다.
학교 선생님들이 발송 설정을 문자로 해서 보냈을 경우 앱 내에 '메시지'가 아닌 일반 문자로 받게 됩니다.
이럴 경우는 앱 내에 '메시지'에 내용이 안남습니다. 참고해 주세요.
학교에 학부모 대표 번호로 정상 등록 되어있습니다.
감사합니다.`,

    `안녕하세요 스쿨투게더 고객센터입니다.
우선 앱 이용에 불편함을 드려 죄송합니다.
현재는 '메시지' 삭제 기능이  없습니다.
만약 받은 메시지들을 보관하지 않아도 괜찮을 경우 회원 탈퇴 후 재로그인 하시면 메시지를 초기화 하실 수 있습니다.
다만 한번 초기화 할 경우 메시지 내용을 복구할 수 없음을 참고해주세요.
감사합니다. 좋은 하루 되세요~`,

    `안녕하세요. 스쿨투게더 고객센터입니다.
확인해본 결과 스쿨투게더 어플 '메시지'알림으로 발송되었습니다.
스쿨투게더 앱에 접속하셔서 상단 탭메뉴중 "메시지"에 들어갑니다.
메시지 중 "지금 응답하기"라는 버튼이 보이는 경우 회신을 할 수 있습니다.
감사합니다.`,

    `안녕하십니까. 스쿨투게더 고객센터입니다.
스쿨투게더 앱으로 회신 메시지를 받으신 경우 스쿨투게더 앱에 접속하셔서 상단 탭메뉴중 "메시지"에 들어갑니다. 메시지 중 "지금 응답하기"라는 버튼이 보이는 경우 회신을 할 수 있습니다.
일반문자로 회신 메시지를 받으신 경우 문자메시지 앱에 들어가셔서 해당 메시지의 최하단에 링크가 하나 보이실 겁니다. 링크를 클릭하여 들어가면 회신이 가능합니다.
현재 고객님께 발송된 메시지는 0건으로 조회가 됩니다. 해당 사항은 학교에서 고객님께 문자 메시지를 보낸적이 없는 것으로 판단되며, 메시지를 받기위해서는 학교에 번호가 정상적으로 등록되었는지 먼저 확인이 필요합니다.
감사합니다.`,

    `안녕하세요 스쿨투게더 고객센터입니다.
상벌점은 해당 점수 부여 받을 때 선생님이 선택한 수신자(학부모 또는 학생)번호로 발송됩니다.
일반문자 또는 앱 내에 '메시지'탭으로 알림이 옵니다.
자세한 알림 내역 및 그 외 누적(총) 상벌점을 알고 싶을 경우 학교에 문의 해주세요.
감사합니다.`,

    `안녕하세요 스쿨투게더 고객센터입니다.
회원 탈퇴를 원하는 경우, 앱에서 'MY' - '설정' - '회원탈퇴' 순서로 진행하면 됩니다.
감사합니다. 좋은 하루 되세요~`,

    `안녕하세요 스쿨투게더 고객센터입니다. 
학교 도메인 문제로 확인됐습니다. 담당 부서에서 해결했습니다.
감사합니다. 좋은 하루 되세요~`,

    `안녕하세요 스쿨투게더 고객센터입니다. 설치가 안될 경우 저장 공간을 확인해 주시기 바랍니다. 
그래도 동일한 현상이 지속될 경우, 설치 관련 문의는 구글 플레이 스토어에 문의 해주시기 바랍니다.
감사합니다.`,

    `안녕하세요 스쿨투게더입니다.
앱 업데이트가 안될 경우 이 방법으로 수동 업데이트 부탁드립니다.
구글 플레이 스토어에 내계정을 클릭한 후 '앱 및 기기관리'-'업데이트 있음'-'스쿨투게더 업데이트'
이 방법으로 진행하시면 원활하게 이용하실 수 있습니다.
감사합니다. 좋은 하루 되세요~ `,

    `안녕하세요 스쿨투게더 고객센터입니다. 
우선 앱 이용에 불편함을 드려 죄송합니다.
튕김 현상이 있을 경우 아래와 같은 방법으로 진행해 주시기 바랍니다.
휴대폰 '설정'앱에서 '애플리케이션'메뉴로 들어가 스쿨투게더를 찾아주시기 바랍니다. 그런 다음 '저장공간'을 클릭하셔서 '데이터 삭제'후 재접속 해주시기 바랍니다.
더욱 편리하게 이용할 수 있도록 노력하는 스쿨투게더가 되겠습니다.
감사합니다. 좋은 하루 되세요~`,

    `안녕하세요 스쿨투게더 고객센터입니다. 
우선 앱 이용에 불편함을 드려 죄송합니다.
말씀하신 기능은 담당부서에 건의사항으로 전달하겠습니다.
더욱 편리하게 이용할 수 있도록 노력하는 스쿨투게더가 되겠습니다.
감사합니다. 좋은 하루 되세요~`,

    `안녕하세요 스쿨투게더 고객센터입니다.
'폴라리스 뷰어'를 설치하시면 광고 없이 문서를 확인 하실 수 있습니다.
감사합니다.`,

    `안녕하세요 스쿨투게더 고객센터입니다.
여러가지 뷰어 어플이 무료인 대신 광고가 나올 수 있으며 이는 저희 어플과는 연관이 없습니다.
대신 저희 스쿨투게더는 '폴라리스 뷰어(무료)'라는 앱과 협업을 하고 있으며 해당 어플로 문서를 볼 경우 광고 없이 문서를 확인 하실 수 있습니다.
감사합니다.`,

    `안녕하세요 스쿨투게더 고객센터입니다.
저희는 어플 회사라 문의 주신 내용에 답을 드리기 어렵습니다.
문의 주신 내용은 학교 교무실로 연락 주시기 바랍니다.
감사합니다. 건강 조심하시고 좋은 하루 되세요~`,

    `안녕하세요 스쿨투게더 고객센터입니다.
시간표나 일정에 있는 학년반을 변경하고 싶을 경우 아래 순서대로 진행해 주세요.
시간표 > 학교변경 > 학교추가 > 학교명 검색 > 학년 반 설정
감사합니다.`,

    `안녕하세요 스쿨투게더 고객센터입니다.
학교에서 교육청에 2024년 데이터를 넣어야 하는데 아직 2024 반 데이터가 없어서 현재 시간표가 안보일 수 있습니다.
학교에서 정보를 입력할 때 까지 기다려주시기 바랍니다.
신학기일 경우 학교에서 정보 등록 기간일 수 있으며 정확한 문의는 학교로 확인 부탁 드립니다.
감사합니다.`,

    `안녕하십니까. 스쿨투게더 입니다.
전화번호 변경을 원하시는 경우 'MY' - '설정' - '사용자 정보'에서 전화번호를 터치하면 바꾸실 수 있습니다.
새로운 번호로 인증해주시기 바랍니다.
그 후 자녀가 다니는 학교에 직접 전화하셔서 학년, 반, 번호, 이름을 말씀드린 뒤 번호등록 및 변경을 진행하시면 됩니다.
감사합니다.`,

    `안녕하세요. 스쿨투게더입니다.
이름 변경을 원하시는 경우 'MY' - '설정' - '사용자 정보'에서 이름을 터치하면 바꾸실 수 있습니다.
감사합니다.`,

    `안녕하세요 스쿨투게더입니다.
자녀분이 다니는 학교를 구독해주시면 '새글'메뉴에서 공지사항과 가정통신문, 급식 알림을 받아보실 수 있습니다.
'새글'은 학교홈페이지에 새로운 글이 올라오면 연동되는 메뉴입니다.
실시간이 아니기 때문에 원본 글을 확인하고 싶으실 경우 학교 홈페이지를 참고해 주시기 바랍니다.
감사합니다.`,

    `안녕하세요. 스쿨투게더입니다.
별도의 학년 반 설정 기능은 따로 없으며, 앱 사용이신 경우 기존 학교 구독하고 계시면 됩니다.
시간표 및 일정에 있는 학년 반을 변경하고 싶을 경우 아래 순서대로 진행해 주세요.
'시간표 > 학교변경 > 학교추가 > 학교명 검색 > 학년 반 설정'
감사합니다.`,

    `안녕하세요. 스쿨투게더입니다.
유형 변경을 원하시는 경우 'MY' - '설정' - '사용자 정보'에서 유형을 터치하면 바꾸실 수 있습니다.
대부분의 학교는 학부모 번호 한 개로 알림을 보내고 있기 때문에 저장되어있는 대표 번호 확인 및 변경 요청은 학교로 문의 주시기 바랍니다.
감사합니다.`,

    `안녕하십니까. 스쿨투게더입니다.
자녀추가(학년 반 설정)기능은 따로 없으며,  자녀가 다니는 학교를 'MY' - '학교 추가' - 구독 하시면 됩니다.
신학기일 경우 신입생은 학교에서 정보등록 기간일 수 있으며 정확한 문의는 학교로 확인부탁드립니다.
감사합니다.`,

    `안녕하세요. 스쿨투게더 고객센터입니다.
자녀 추가(학년 반 설정)기능은 따로 없으며, 같은 학교에 자녀가 배정되었을 경우 따로 설정하실 필요 없이 현재 그대로 유지해주시면 됩니다.
단체 문자일 경우 한 건으로 받으실 수 있으며 상벌점 및 회신문은 각 학생 별로 받으실 수 있습니다.
시간표에 있는 학년, 반을 변경하고 싶을 경우 아래 순서대로 진행해 주세요.
시간표 > 학교변경 > 학교추가 > 학교명 검색 > 학년 반 설정
신학기일 경우 신입생은 학교에서 정보 등록 기간일 수 있으며 정확한 문의는 학교로 확인 부탁드립니다.
감사합니다.`,

    `안녕하세요 스쿨투게더입니다.
학교편집을 원하시는 경우 'MY' - '구독편집(펜모양 아이콘)' - 삭제하고 싶으신 학교 클릭 후 휴지통아이콘을 클릭해 주세요.
앱을 삭제하고 싶을 경우 휴대폰 메뉴에서 스쿨투게더를 오랫동안 클릭하고 있으면 '설치 삭제'를 하실 수 있습니다.
감사합니다. `,

    `안녕하세요 스쿨투게더 고객센터입니다.
어플 메뉴 중 'MY'-'설정'-'문의하기'로 해당 게시글의 제목, 날짜 또는 캡쳐사진을 보내주시면 더 정확한 상담을 해드릴 수 있습니다.
감사합니다. 좋은 하루 되세요~`,

    `안녕하세요 스쿨투게더입니다.
고객님의 번호를 조회해본 결과 현재 정상적으로 설치되어있습니다.
학교에서 계속 설치가 안되어 있다고 연락이 올 경우 전화번호가 정확하게 등록되어 있는지 확인 부탁드립니다.
감사합니다.`,

    `안녕하세요 스쿨투게더 고객센터입니다.
스쿨투게더 앱 이용자(학부모, 학생)와 1:1로 쪽지를 주고 받을 수 있는 기능이 있습니다.
'메시지'메뉴 오른쪽 하단에 1:1쪽지 버튼을 누르면 시작할 수 있습니다.
선생님이 먼저 온라인으로 활성화 해주셔야 채팅을 시작할 수 있습니다.
그 후 사용자(학부모나 학생)가 채팅을 시작할 수 있습니다.
감사합니다. `,

    `안녕하세요. 스쿨투게더 고객센터입니다. 
앱 이용에 불편을 드려 죄송합니다.
캐시버튼은 다양한 미션 수행을 통해 캐시를 적립 할 수 있는 서비스입니다.
우선 상단바 캐시버튼을 없애려면 기존에 비활성화 한 캐시버튼을 활성화 해주세요.
그 후 캐시 버튼 파란색 아이콘 클릭 - 더보기 - '알림창 상태바'를 off 해주시면 됩니다.
앱내에 있는 캐시버튼 파란색 아이콘을 비활성화 하고 싶을 경우 스쿨투게더 앱 메뉴 중 "MY" - 설정 - 캐시버튼 on/off 통해 설정하실수있습니다.
감사합니다. 좋은 하루 되세요~`,

    `안녕하세요 스쿨투게더 고객센터입니다.
우선 캐시버튼 서비스에 관심을 주셔서 감사합니다.
캐시버튼은 다양한 미션 수행을 통해 캐시를 적립 할 수 있는 서비스입니다.
현재는 안드로이드로 서비스를 제공 중이며 ios는 추후 서비스 예정에 있습니다.
담당 부서에 전달하여 조금 더 빠르게  고객님들께 선보일 수 있도록 하겠습니다.
감사합니다. 좋은 하루 되세요~`,

    `안녕하세요. 스쿨투게더 고객센터입니다.
캐시버튼 포인트가 없어졌을 경우 캐시 버튼 파란색 아이콘 클릭 - 더보기 - '문의하기'로 접속, 사라진 포인트에 관해 자세한 상담을 하실 수 있습니다.
감사합니다. 좋은 하루 되세요~`,

    `안녕하세요 스쿨투게더입니다.
네이버나 구글창에 '스쿨투게더'를 검색하면 저희 홈페이지가 나옵니다.
접속하시면 오른쪽 상단에 '학부모 및 학생 PC버전'이 있습니다.
이 기능을 이용하시면 좀 더 편리하게 사용하실 수 있습니다.
감사합니다.`,

    `안녕하세요 스쿨투게더 고객센터입니다.
만약 동일한 현상이 지속된다면 'MY' - '설정' - '로그아웃'하신 후 번호 인증 재로그인 부탁 드립니다.
감사합니다.`,

    `안녕하세요 스쿨투게더입니다.
해당 현상은 아이폰ios에서 발생하는 일입니다.
우선 어플을 종료해주셔야 정상적으로 글을 받아보실 수 있습니다.
홈버튼 2번을 눌러 사용중인 어플 중 스쿨투게더를 제거한 뒤 다시 접속을 하시거나, 화면을 제일 상단으로 스크롤하시면 새로고침으로 최신소식을 확인하실 수 있습니다.
감사합니다.`,

    `안녕하십니까. 스쿨투게더 입니다.
우선 불편을 끼쳐드려 죄송합니다. 해당 문제는 간헐적으로 발생되어 안내사항에 따라 조치 취해주시면 감사하겠습니다.
휴대폰 '설정'앱에서 '애플리케이션'메뉴로 들어가 스쿨투게더를 찾아주시기 바랍니다. 그런다음 '저장공간'을 클릭하셔서 '데이터 삭제'후 재접속 해주시기 바랍니다.
그 후에도 앱이 정상적으로 실행이 안된다면 02-499-9725로 전화 주셔서 상담원과 함께 절차 진행해주시면 감사하겠습니다.`,

    `안녕하세요 스쿨투게더입니다.
해당 오류는 현재 담당 부서에서 접수되어 있으며, 최대한 빠른 시간 안에 해결하도록 하겠습니다.
급하실 경우 학교 홈페이지를 참고해 주시기 바랍니다.
감사합니다.`,

    `안녕하세요 스쿨투게더입니다.
광고를 받고싶지 않으실 경우 'MY' - '학교 추가' - '광고성 정보 받지않기'를 구독해주세요.
감사합니다. 좋은 하루 되세요~`,

    `안녕하세요 스쿨투게더입니다.
우선 늦은 답변을드려 죄송합니다.
급식 연동이 늦는 부분은 담당 부서로 전달해 드렸습니다.
좀 더 편하게 이용하실 수 있도록 노력하겠습니다.
감사합니다. 좋은 하루 되세요~`,

    `안녕하세요 스쿨투게더 고객센터입니다.
학교에서 교육청에 올해 데이터를 넣어야 하는데 아직 반 데이터가 없어서 현재 시간표가 안보일 수 있습니다.
학교에서 정보를 입력할 때 까지 기다려주시기 바랍니다.
신학기일 경우 학교에서 정보 등록 기간일 수 있으며 정확한 문의는 학교로 확인 부탁 드립니다.
감사합니다.`,

    `안녕하세요 스쿨투게더입니다.
요청주신 사항은 담당부서에 접수되었습니다.
어플에서 확인해 주시기 바랍니다.
감사합니다. 좋은 하루 되세요~`,

    `안녕하세요 스쿨투게더입니다.
해당 문의는 유선 상으로 답변 드렸습니다.
감사합니다.`,

    `안녕하세요 스쿨투게더입니다.
정확한 상담을 위해 고객센터로 연락주시기 바랍니다.
고객센터(02-499-9725 / 연결 가능한 시간  9:30~17:00 )
감사합니다`,

    `안녕하세요 스쿨투게더 고객센터입니다. 
접수된 오류를 담당 부서에서 해결했습니다. 앱 이용에 불편함을 드려 죄송합니다.
감사합니다. 좋은 하루 되세요~`,

    `안녕하세요 스쿨투게더 고객센터입니다.
~~학교는 현재 서비스 계약을 종료하여 학교의 요청으로 홈페이지 연동이 차단되었습니다.
자세한 문의는 학교로 연락 주시기 바랍니다.
감사합니다.`
];

export const staticTeacherAnswer = [
    `안녕하세요. 선생님. 스쿨투게더 입니다.
유료 서비스(회신, 생활 평점, 그룹 제한 없음, 광고 없음 등 )는 통합 서비스입니다.
학급 수에 따라서 기본 요금이 다르며, 문자 단가는 현재 이용하시는 요금제와 동일한 금액입니다.
총 학급 수 15학급 이하일 경우  VAT포함 월 55,000원(1년 660,000원)  /
16~30학급일 경우 VAT포함 월 71,500원(1년 858,000원) /
31학급 이상일 경우  VAT포함 월 82,500원(1년 990,000원) 입니다.
문자 단가 단문(SMS) 건당 14원 / 장문(LMS) 건당 36원 입니다.
더 궁금하신 점이 있으실 경우 고객센터(02-499-9725)로 연락 주시기 바랍니다.
감사합니다.`,

    `안녕하세요. 선생님. 스쿨투게더입니다.
어플에서 문자를 보내고 싶으실 경우 우선 선생님 pc버전에 교직원 계정으로 가입이 되어 있어야 합니다.
교직원으로 선생님 페이지(https://teacher.schooltogether.kr/) 가입하신 후 어플 '학교소식'탭 오른쪽 하단에 노란색 편지봉투 아이콘을 클릭하시면 문자보내는 화면으로 전환됩니다.
감사합니다. 좋은 하루 되세요~`,

    `안녕하세요. 선생님. 스쿨투게더 고객센터입니다.
메시지를 발송하면 대기 시간은 1분~4분 사이로 진행됩니다.
대기 중에는 발송취소가 가능하며 이미 발송된 건은 취소가 어렵습니다.
감사합니다.`,

    `안녕하세요. 선생님. 스쿨투게더입니다.
스쿨투게더 선생님pc 홈페이지 (https://teacher.schooltogether.kr/) 에서 회원 가입 후 로그인, '교직원 승인 신청하기'를 통해 정보를 입력해주세요.
각 학교 관리자 권한을 가지고 있는 선생님께서 정보 확인 후 승인하면 바로 사용 하실 수 있습니다.
감사합니다. 좋은 하루 되세요~`,

    `안녕하세요. 선생님. 스쿨투게더 고객센터입니다.
상벌점 수정 및 삭제는 '전체 평점 조회 및 관리' 권한이 있거나 관리자 아이디로 가능합니다.
'생활평점' > '학생별 현황(안보일 경우 권한 필요)' >수정할 학생 '상세보기' > 수정, 삭제
이 순서대로 처리하시면 됩니다.
대신 수정 삭제 시 학생이나 학부모에게 알림이 발송되진 않으니 참고해주세요.
감사합니다.`,

    `안녕하세요. 선생님. 스쿨투게더 고객센터입니다. 
말씀하신 기능은 담당부서에 건의사항으로 전달하겠습니다. 
더욱 편리하게 이용할 수 있도록 노력하는 스쿨투게더가 되겠습니다. 
감사합니다. 좋은 하루 되세요~`,

    `안녕하세요 스쿨투게더입니다.
해당 문의는 유선 상으로 답변 드렸습니다.
감사합니다.`,

    `안녕하세요 스쿨투게더입니다.
정확한 상담을 위해 고객센터로 연락주시기 바랍니다.
고객센터(02-499-9725 / 연결 가능한 시간  9:30~17:00 ) 
감사합니다`,

    `안녕하세요. 선생님. 스쿨투게더 고객센터입니다.
요청한 서류를 발송했습니다.
혹시 요청한 서류 외에 필요한 서류가 있다면 메일 답장이나 고객센터(02-499-9725)로 연락 주시기 바랍니다.
감사합니다.`,

    `안녕하십니까. 스쿨투게더 입니다.
- PC 버전
상단 좌측 학교 이름 아래 "학교변경"을 클릭하여 교직원 신청 및 전환이 가능합니다.
- 모바일 버전
PC에서 교직원신청을 완료하고 신청이 받아들여진 상태라면 모바일 화면 우측 상단의 "학교전환"을 눌러 해당 학교로 전환 하시면 됩니다.
감사합니다.`,

    `안녕하세요 스쿨투게더 고객센터입니다.
스쿨투게더 선생님 페이지에 로그인 하신 후 오른쪽 상단에 있는 '000선생님' 란을 클릭해 주세요.
'내 정보 수정' - 회원 탈퇴 - 비밀번호 입력후 '회원 탈퇴'버튼을 클릭하시면 정상적으로 탈퇴 하실 수 있습니다.
감사합니다.`,

    `안녕하세요 스쿨투게더 고객센터입니다.
학교 관리자 선생님이 학급 관리 메뉴에서 담임 선생님 설정을 변경할 수 있습니다.
감사합니다.`,

    `안녕하세요 선생님, 스쿨투게더 고객센터입니다.
관리자 변경은 '학교'메뉴 > '교직원 관리' > 변경할 선생님 정보'확인' > 관리자 위임 버튼을 클릭해 주세요.
이 순서대로 하고 재로그인 하면 변경된 권한으로 접근 할 수 있습니다.
감사합니다.`,

    `안녕하세요 스쿨투게더 고객센터입니다.
승인신청은 관리자 선생님께서 가능하며, 선생님pc버전으로만 하실 수 있습니다.
감사합니다.`,

    `안녕하세요 선생님, 스쿨투게더 고객센터입니다.
비밀번호를 잊으셨을 경우 로그인 화면에서 하단에 '비밀번호를 잊으셨나요 ?비밀번호 재설정 '을 클릭해주세요.
아이디와 휴대폰 인증을 하면 비밀번호를 새롭게 생성할 수 있습니다.
감사합니다.`,

    `안녕하세요 스쿨투게더 고객센터입니다.
우선 스쿨투게더 선생님 홈페이지에 로그인을 해주세요.
그 후 왼쪽 상단에 '000선생님'클릭 - '나의 정보 수정' - 연락처 설정에 변경할 휴대폰 번호를 입력 후 인증 받으면 바로 변경됩니다.
감사합니다.`,

    `안녕하세요 스쿨투게더 고객센터입니다.
교직원이 '메시지 보내기'에서 '결제 필요'로 메시지를 전송할 경우, 결재자로 지정된 선생님은 '메시지'메뉴 > '결제함' 메뉴에서 내용 확인 및 승인을 할 수 있습니다.
감사합니다.`,

    `안녕하세요 선생님, 스쿨투게더 고객센터입니다.
해당 기능은 '개별메시지' 기능으로 이용할 수 있습니다.
자세한 상담은 02-499-9725로 연락 주시면 담당 부서에서 친절하게 설명 드리겠습니다.
감사합니다.`,

    `안녕하세요 스쿨투게더 고객센터입니다.
'학생관리'에 저장되어있는 데이터를 수정하려면 관리자 선생님에게 수정 권한을 받으셔야 가능합니다.
만약 조회 권한만 있을 경우 수정 가능한 선생님께 부탁하셔야 합니다.
감사합니다.`,

    `안녕하세요 선생님, 스쿨투게더 고객센터입니다.
기존에 만든 회신문 선택 후 '더보기' > '회신 다시만들기'를 클릭하면 복사해서 사용하실 수 있습니다.
감사합니다. 좋은 하루 되세요~`,

    `안녕하세요 선생님, 스쿨투게더 입니다.
우선 실명, 익명 설정과 상관 없이 해당 기능은 이용할 수 있습니다.
회신함에서 보낸 회신을 클릭한 후 '응답자별 상세보기'를 클릭하면 '미응답자 메시지 재발송' 기능을 사용할 수 있습니다.
감사합니다.`,

    `안녕하세요 선생님, 스쿨투게더 입니다.
이미 한번 발송된 회신문 내용은 수정하실 수 없습니다.
마감일만 수정 가능합니다.
감사합니다.`,

    `안녕하세요 선생님, 스쿨투게더 고객센터입니다.
1번 질문 보기 '예', '아니오'에 각각 '하위 질문 생성' 만들기를 해주시면 원하는 방식으로 생성할 수 있습니다.
더 궁금한 점이 있을 경우 고객센터(02-499-9725 / 연결 가능한 시간  9:30~17:00 ) 로 연락 주세요.
감사합니다`,

    `안녕하세요 선생님, 스쿨투게더 고객센터입니다.
교직원 연락처는 '그룹관리'로 저장해서 사용해 주세요.
그룹에 저장된 리스트는 자동으로 생성되는 것이 아니며 학교에서 만드는 대로 사용할 수 있습니다.
년도별, 부서별로 나눠서 그룹을 만들면 문의 주신 내용대로 사용할 수 있을 것 같습니다.
감사합니다.`,

    `안녕하세요 선생님, 스쿨투게더 고객센터입니다.
홈페이지 연동은 실시간이 아닙니다. 교육청 서버 시간에 따라 1시간~3시간정도 소요됩니다.
조금 더 빠른 수동 연장을 원하실 경우 선생님페이지 '게시물' 메뉴에서 각 게시판당 '수집하기'를 통해 조금 더 빠른 수동 연동이 가능합니다.
(해당 기능은 게시물 관리 권한이 있는 아이디로 실행 가능합니다.)
감사합니다.`,

    `안녕하세요 스쿨투게더 고객센터입니다.
앱에 연동된 후 학교 홈페이지에 있는 원본 글을 수정, 삭제할 경우 반영이 안됩니다.
스쿨투게더 선생님 페이지 '게시물' 메뉴에서 동일하게 수정하셔야 앱에 정상적으로 연동됩니다.
감사합니다.`,

    `안녕하세요. 선생님. 스쿨투게더 고객센터입니다. 
담당 부서에서 해결했습니다. 
감사합니다. 좋은 하루 되세요~`,

    `안녕하세요 선생님, 스쿨투게더입니다.
학교 교표 이미지 변경 완료 되었습니다.
감사합니다.`,

    `안녕하세요 선생님, 스쿨투게더 고객센터입니다.
선생님pc버전에 나와있는 (전화번호)는 선생님들이 사용할 때 내부적으로 구분 하기 위한 정보입니다. 
학생이나 학부모에게 노출되는 정보가 아니니 걱정하지 않으셔도 됩니다.
그리고 스쿨투게더를 통해 전화를 걸 때는 학교 정보에 등록된 대표 번호로 전화가 연결됩니다.
감사합니다. 좋은 하루 되세요~`,

    `안녕하세요 스쿨투게더 고객센터입니다.
급식에 대한 연동은 아래 사이트에서 정보를 가져오고 있습니다.
나이스 교육정보 개방포털
https://open.neis.go.kr/portal/data/dataset/searchDatasetPage.do
이쪽에 정상적으로 급식 정보가 저장되어있는지 확인해 주세요.
감사합니다.`
];