import React from 'react';
import { AlertConsumer } from './AlertContext';

function useAlert(WrappedComponent) {
    return function UseAlert(props) {
        return (
            <AlertConsumer>
                {({ actions }) => (
                    <WrappedComponent alert={actions} {...props} />
                )}
            </AlertConsumer>
        );
    };
}

// stack={state.stack}

export default useAlert;
