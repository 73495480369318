import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { classJoin } from '../../../lib/utils';
import { Input } from '../Input';

const ComboBoxV2Styled = styled.div`
    position: relative;
    border: 1px solid #dedede;
    background-color: #ffffff;
    cursor: default;
    overflow-y: auto;

    ${(props) =>
        props.maxHeight &&
        css`
            max-height: ${props.maxHeight}px;
        `}

    &.small {
        min-height: 38px;
    }

    &.middle {
        min-height: 42px;
    }

    &.large {
        min-height: 46px;
    }

    padding: 0 16px;

    ${(props) =>
        props.block &&
        css`
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            flex: 1;
        `}

    .search-input {
        display: inline-flex;
        min-width: 200px;
        flex: 1;
    }
`;

const ComboBoxV2 = ({
    debounce,
    enter,
    value,
    icon,
    size = 'middle',
    search,
    items,
    name,
    field,
    block,
    loadingSync,
    maxHeight,
    placeholder,
    children,
    customRender: CustomComponent,
    onSearch,
    onChange,
    onComplate,
    onRegister,
    onBackSpace,
}) => {
    const [backSpaceTerm, setBackSapceTerm] = useState(false);
    const classNames = classJoin({ size });

    const registerHandler = useCallback(
        (ev) => {
            // 제출
            if (ev.key === 'Enter' || ev.key === ',' || ev.key === ' ') {
                if (value !== null && value !== '') {
                    onRegister && onRegister(value);
                    onChange && onChange(null);
                }
                return;
            }

            if (ev.keyCode === 8 && (value === null || value.length === 0)) {
                if (backSpaceTerm || value === null) {
                    // 삭제 및 뒤로 가기
                    onBackSpace && onBackSpace();
                }

                setBackSapceTerm(!backSpaceTerm);
            }
        },
        [value, onRegister, onChange, onBackSpace, backSpaceTerm]
    );

    const changeHandler = (ev) => {
        if (backSpaceTerm) {
            setBackSapceTerm(false);
        }

        onChange(ev.target.value);
    };

    return (
        <ComboBoxV2Styled className={classNames} block={block} maxHeight={maxHeight}>
            {children}
            <div className="search-input">
                <Input
                    style={{ paddingLeft: 0, width: '100%' }}
                    inputStyle={{ paddingLeft: 4 }}
                    value={value}
                    name={name}
                    size={size}
                    icon={icon}
                    ghost
                    placeholder={placeholder}
                    onKeyUp={registerHandler}
                    onChange={changeHandler}
                />
            </div>
        </ComboBoxV2Styled>
    );
};

ComboBoxV2.defaultProps = {
    customRender: null,
    duration: 1000,
    enter: false,
    field: 'label',
    loadingSync: false,
    items: [],
    name: '',
    maxHeight: 300,
    size: 'middle',
    placeholder: null,
    value: null,
    onChange: () => {},
    onComplate: () => {},
};

export default ComboBoxV2;
