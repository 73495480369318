import axios from 'axios';
import { hasOwnProperty } from '../lib/utils';

/**
 * type장로교
장로교(기독교 장로회, 예수교 장로회) 감리교, 침례교, 성결교, 순복음교회, 성공회

name사랑의교회
tel025235050
fax024884885
address서울 서초구 서초동 1541-15
memo
선택
 */

const fetch = axios.create({
    baseURL: 'http://13.124.221.102:3333/v1',
    headers: {
        'x-session-manager': 'SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c',
    },
});

fetch.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status >= 400) {
            const code = hasOwnProperty(error.response.data, 'code') && error.response.data.code;

            if (code) {
                return Promise.reject({ message: errorCode[code] });
            } else {
                return Promise.reject(error);
            }
        }

        return Promise.reject(error);
    }
);

export default {
    create_account: (data) => fetch.post('/accounts', data),
    create_church: (data) => fetch.post('/churchs', data),
    create_places: (churchId, data) => fetch.post(`/churchs/${churchId}/places`, data),
    create_beacon: (churchId, placeId, data) => fetch.post(`/churchs/${churchId}/places/${placeId}/gates`, data),

    get_churchs: (params) => fetch.get('/churchs', { params }),
    get_places: (churchId) => fetch.get(`/churchs/${churchId}/places`),
    get_accounts: (churchId) => fetch.get(`/churchs/${churchId}/accounts`),
    get_gate: (churchId, placeId) => fetch.get(`/churchs/${churchId}/places/${placeId}/gates`),

    delete_account: (churchId, accountId) => fetch.delete(`/churchs/${churchId}/accounts/${accountId}`),
    delete_gate: (churchId, placeId, gateId) => fetch.delete(`/churchs/${churchId}/places/${placeId}/gates/${gateId}`),

    update_place: (churchId, placeId, data) => fetch.put(`/churchs/${churchId}/places/${placeId}`, data),
    update_gate: (churchId, placeId, gateId, data) =>
        fetch.put(`/churchs/${churchId}/places/${placeId}/gates/${gateId}`, data),
};

const errorCode = {
    //church
    duplicated_beacon_id: '비콘아이디가 이미 존재합니다.',
    duplicated_username: '아이디가 이미 존재합니다.',
    invalid_email: '이메일을 정확하게 작성해주세요.',
    invalid_fax: '팩스를 입력해주세요.',
    already_exist_beacon: '비콘아이디가 이미 존재합니다.',
};
