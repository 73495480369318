import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Loding from '../Loading/Loading';

import { flex_box } from '../../dressroom/layout';
import { keyClass } from '../../../lib/utils';

const InputStyled = styled.div`
    position: relative;
    box-sizing: content-box;

    i {
        transition: all 0.3s;
        display: block;
    }

    & + * {
        margin-left: 10px;
    }

    .loading-icon {
        right: 0px;
    }

    .input-icon {
        left: 0px;
    }

    ${(props) =>
        props.block &&
        css`
            flex: 1;
            display: block;
            width: 100%;
        `}


    ${(props) =>
        props.inline &&
        css`
            display: inline-block;
        `};

    .input-icon,
    .loading-icon {
        position: absolute;
        top: 0;
        ${flex_box({ justify: 'center' })}

        ${(props) => {
            switch (props.size) {
                case 'small':
                    return css`
                        width: 38px;
                        height: 38px;
                        line-height: 38px;
                        font-size: 1.3rem;
                    `;
                case 'large':
                    return css`
                        width: 43px;
                        height: 43px;
                        line-height: 43px;
                        font-size: 1.4rem;
                    `;
                default:
                    return css`
                        width: 42px;
                        height: 42px;
                        line-height: 42px;
                        font-size: 1.35rem;
                    `;
            }
        }}
    }

    .select-input {
        font-size: 1.4rem;
        color: #989898;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translate(50%, -50%);
    }

    ${(props) =>
        props.insteadInput &&
        css`
            & > div {
                width: 100%;
            }
        `}

    input {
        outline: none;
        color: ${(props) => props.theme.black};
        border: ${(props) => props.board}px solid #dedede;
        width: ${(props) => (props.width ? `${props.width}px` : '100%')}
        transition: all 0.3s;
        display: block;

        ${(props) =>
            props.ghost &&
            css`
                background-color: transparent;
                border-color: ${(props) => props.theme.white};

                & + .input-icon i {
                    color: ${(props) => props.theme.white};
                }

                &:focus {
                    border-color: ${(props) => props.theme.white};

                    & + .input-icon i {
                        color: ${(props) => props.theme.white};
                    }
                }
            `}

            ${(props) =>
                props.invisible &&
                css`
                    background-color: transparent;
                    border-color: transparent;
                `}

        ${(props) =>
            props.align &&
            css`
                text-align: ${props.align};
            `}

        ${(props) =>
            (!props.size || !props.icon) &&
            css`
                padding: 0 1.6rem;
            `}
        ${(props) => {
            switch (props.size) {
                case 'small':
                    return css`
                        height: 38px;
                        line-height: 38px;
                        font-size: 1.3rem;
                        ${props.icon &&
                        css`
                            padding-left: 32px;
                            padding-right: 8px;
                        `}
                        ${props.loading &&
                        css`
                            padding-right: 32px;
                        `}
                    `;
                case 'large':
                    return css`
                        height: 46px;
                        line-height: 46px;
                        font-size: 1.4rem;
                        ${props.icon &&
                        css`
                            padding-left: 44px;
                            padding-right: 8px;
                        `}
                        ${props.loading &&
                        css`
                            padding-right: 44px;
                        `}
                    `;
                default:
                    return css`
                        height: 42px;
                        line-height: 42px;
                        font-size: 1.35rem;
                        ${props.icon &&
                        css`
                            padding-left: 40px;
                            padding-right: 8px;
                        `}
                        ${props.loading &&
                        css`
                            padding-right: 40px;
                        `}
                    `;
            }
        }}
    }
`;

const InputText = styled.input`
    & + .require {
        font-size: 1.2rem;
        padding-left: 6px;
        padding-top: 4px;
        text-align: left;

        &.error {
            color: #dc1922;
        }
    }

    & + .input-icon i {
        color: #989898;
    }

    &:focus {
        border-color: ${(props) => props.theme.primary};

        & + .input-icon i {
            color: ${(props) => props.theme.primary};
        }
    }

    &:disabled {
        background-color: #f5f5f5;
        color: #989898;

        & + div + .input-icon i {
            color: #989898;
        }
    }

    &[readonly] {
        cursor: unset;
    }

    ${(props) =>
        props.select &&
        css`
            padding-right: 36px;
            user-select: none;
            cursor: pointer !important;
        `}
`;

const Input = ({
    align,
    board,
    block,
    color,
    children,
    forwordedRef,
    error,
    ghost,
    type,
    icon,
    inline,
    invisible,
    insteadInput,
    required,
    readOnly,
    size,
    style,
    inputStyle,
    value,
    width,
    requiredText,
    loading,
    disabled,
    pattern,
    select,
    onChange,
    onKeyUp,
    onKeyPress,
    onEnter,
    ...props
}) => {
    const [input, setInput] = useState('');

    const changeHandler = (e) => {
        if (pattern) {
            const isAllowPattern = new RegExp(pattern, 'g').test(e.target.value);

            if (!e.target.value || isAllowPattern) {
                onChange && onChange(e);
                setInput(e.target.value);
                return;
            }
        } else {
            onChange && onChange(e);
            setInput(e.target.value);
        }
    };

    const enterHandler = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) onEnter(e);
    };

    useEffect(() => {
        setInput(value);
    }, [value]);

    const keyupEvent = onEnter ? enterHandler : onKeyPress;

    return (
        <InputStyled
            size={size}
            block={block}
            style={style}
            icon={icon}
            inline={inline}
            invisible={invisible}
            loading={loading}
            color={color}
            board={board}
            ghost={ghost}
            width={width}
            align={align}
            insteadInput={insteadInput}
        >
            {children || (
                <InputText
                    type={type}
                    value={input || ''}
                    style={inputStyle}
                    required={required}
                    readOnly={readOnly}
                    ref={forwordedRef}
                    disabled={disabled}
                    select={select}
                    onChange={changeHandler}
                    onKeyPress={keyupEvent}
                    onKeyUp={onKeyUp}
                    {...props}
                />
            )}

            {requiredText && error && <div className={`require ${keyClass({ error })}`}>{error || requiredText}</div>}

            {icon && (
                <div className="input-icon">
                    <i className={icon} />
                </div>
            )}

            {select && (
                <div className="select-input">
                    <i className="fas fa-chevron-down"></i>
                </div>
            )}

            {loading && (
                <div className="loading-icon">
                    <Loding mode="cycle" loading={loading}></Loding>
                </div>
            )}
        </InputStyled>
    );
};

Input.propTypes = {
    round: PropTypes.bool,
    board: PropTypes.number,
    name: PropTypes.string,
};

Input.defaultProps = {
    type: 'text',
    size: 'middle',
    name: 'name',
    board: 1,
    error: null,
    requiredText: null,
};

export default React.memo(Input);
