import React from 'react';
import { ConfirmConsumer } from './ConfirmContext';

const useConfirm = WrappedComponent => {
    return function UseConfirm(props) {
        return (
            <ConfirmConsumer>
                {({ actions }) => (
                    <WrappedComponent confirm={actions.confirm} {...props} />
                )}
            </ConfirmConsumer>
        );
    };
};

export default useConfirm;
