import React from 'react';
import styled from 'styled-components';
import { useToggle } from '../../../lib/hooks';
import { Text } from '../../dressroom/layout';
import { useEffect } from 'react';

const CheckboxStyled = styled.label`
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;

    i {
        font-size: 1.6rem;
        color: ${(props) => props.theme.line};
    }

    input[type='checkbox'] {
        display: none;
    }

    input[type='checkbox']:checked + div > i {
        color: ${(props) => props.theme.primary};
    }

    & + & {
        margin-left: 1rem;
    }
`;

const Checkbox = ({ checked = false, label, fontSize = 1.3, onChange }) => {
    const [toggle, setToggle] = useToggle(checked);

    const changeHandler = () => {
        const next = !toggle;
        setToggle(next);
        onChange && onChange(next);
    };

    const icon = toggle === true ? 'fas fa-check-square' : 'far fa-square';

    useEffect(() => {
        if (toggle !== checked) {
            setToggle(checked);
        }
    }, [checked, toggle, setToggle]);

    return (
        <CheckboxStyled>
            <input type="checkbox" checked={toggle} onChange={changeHandler} />
            <div>
                <i className={icon} />
            </div>
            {label && (
                <Text size={fontSize} style={{ marginLeft: 6 }} color="disabled_color">
                    {label}
                </Text>
            )}
        </CheckboxStyled>
    );
};

export default Checkbox;
