import React from 'react';
import styled, { css } from 'styled-components';
import { classJoin } from '../../../lib/utils';

const RowStyled = styled.div`
    display: flex;
    justify-content: ${(props) => props.justify};
    align-items: ${(props) => props.align};
    width: 100%;
    height: 100%;

    ${(props) =>
        props.flexDirection &&
        css`
            flex-direction: ${props.flexDirection};
        `}

    & > * + * {
        margin-left: ${(props) => props.between * 10}px;
    }

    ${(props) =>
        props.wrapper &&
        css`
            flex-wrap: wrap;
        `}

    ${(props) =>
        props.colsline &&
        css`
            & > .column + .column:not(.nb) {
                border-left: 1px solid #9598a7;
                padding-left: 1.6rem;
            }
        `}

    ${(props) => props.distance && css`& + & { margin-top: ${props.distance}rem`}
`;

const Row = ({
    children,
    style,
    justify,
    align,
    colsline,
    distance,
    between,
    wrap = false,
    flexDirection,
    onClick,
    ...rows
}) => {
    const classNames = classJoin({ ...rows });
    return (
        <RowStyled
            className={classNames}
            style={style}
            align={align}
            justify={justify}
            colsline={colsline}
            wrapper={wrap}
            flexDirection={flexDirection}
            distance={distance}
            between={between}
            onClick={onClick}
        >
            {children}
        </RowStyled>
    );
};

Row.defaultProps = {
    justify: 'flex-start',
    align: 'flex-start',
    between: 1,
};

export default Row;
