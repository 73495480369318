import React, { useState, useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { isEmpty } from '../../../lib/utils';

const TextareaStyled = styled.textarea`
    width: 100%;
    resize: none;
    border: 1px solid;
    border-color: ${(props) => (props.ghost ? 'transparent' : props.theme.gray[1])};
    outline: none;
    padding: 14px 16px;
    font-size: 1.3rem;
    line-height: 1.5;

    min-height: ${(props) => props.minHeight}px;
    max-height: ${(props) => props.maxHeight}px;

    &:focus {
        border-color: ${(props) => props.theme.primary};
    }

    font-family: Noto Sans, sans-if;
`;

const defaultProps = {
    maxHeight: 200,
    minHeight: 60,
};

const Textarea = ({ value, onChange, minHeight, maxHeight, ghost, enter, onEnter, onFocus, ...props }) => {
    const textareaRef = useRef(null);
    const [text, setText] = useState(value);

    const changeHanlder = useCallback(
        (e) => {
            setText(e.target.value);
            if (typeof onChange === 'function') {
                onChange(e);
            }
        },
        [onChange]
    );

    const keypressHandler = (e) => {
        if (e.which === 13) {
            if (!e.shiftKey && onEnter) {
                e.preventDefault();
                onEnter();
            }
        }
    };

    const autoHeightIncrease = useCallback(
        (e) => {
            const scrollHeight = textareaRef.current.scrollHeight;

            textareaRef.current.style.height = minHeight + 10 + 'px';

            if (scrollHeight > minHeight) {
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
            }

            if (!isEmpty(e) && e.which === 13) {
                // enter
            }
        },
        [minHeight]
    );

    const focusHandler = (e) => {
        autoHeightIncrease(e);
        onFocus && onFocus();
    };

    useEffect(() => {
        setText(value);

        if (value) {
            autoHeightIncrease();
        }
    }, [value, autoHeightIncrease]);

    return (
        <TextareaStyled
            value={text || ''}
            ghost={ghost}
            minHeight={minHeight}
            maxHeight={maxHeight}
            onChange={changeHanlder}
            onKeyUp={autoHeightIncrease}
            onKeyDown={autoHeightIncrease}
            onKeyPress={keypressHandler}
            onFocus={focusHandler}
            ref={textareaRef}
            {...props}
        ></TextareaStyled>
    );
};

Textarea.defaultProps = defaultProps;

export default Textarea;
