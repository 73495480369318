// import { createAction } from 'redux-actions';
import { actionString, createRequestAction } from '../helper';

const action = actionString('manage');

export const NOTICES_REQUEST = action('NOTICES_REQUEST');
export const NOTICES_SUCCESS = action('NOTICES_SUCCESS');
export const NOTICES_FAILURE = action('NOTICES_FAILURE');

export const NOTICE_TYPES_REQUEST = action('NOTICE_TYPES_REQUEST');
export const NOTICE_TYPES_SUCCESS = action('NOTICE_TYPES_SUCCESS');
export const NOTICE_TYPES_FAILURE = action('NOTICE_TYPES_FAILURE');

export const NOTICE_DETAIL_REQUEST = action('NOTICE_DETAIL_REQUEST');
export const NOTICE_DETAIL_SUCCESS = action('NOTICE_DETAIL_SUCCESS');
export const NOTICE_DETAIL_FAILURE = action('NOTICE_DETAIL_FAILURE');

export const noticesPender = createRequestAction(NOTICES_REQUEST, NOTICES_SUCCESS, NOTICES_FAILURE);

export const noticeTypesPender = createRequestAction(NOTICE_TYPES_REQUEST, NOTICE_TYPES_SUCCESS, NOTICE_TYPES_FAILURE);

export const noticeDetailPender = createRequestAction(
    NOTICE_DETAIL_REQUEST,
    NOTICE_DETAIL_SUCCESS,
    NOTICE_DETAIL_FAILURE
);
