import React from 'react';
import { NavLink } from "react-router-dom";
import styled from 'styled-components';

const SideNavUserStyled = styled.div`
    padding: 1.6rem 1.6rem 0.4rem;
    color: #ffffff;
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;

    a {
        color: #ffffff;
    }
`;

const SideNavUser = ({ user }) => {
    if (!user) {
        return <SideNavUserStyled>유저없음</SideNavUserStyled>;
    }

    return <SideNavUserStyled>
        <div>{user.name}</div>

        <div>
        <NavLink to="/logout">
            <i className="fas fa-sign-out-alt"></i>
            </NavLink>
        </div>
    </SideNavUserStyled>;
};

export default SideNavUser;
