import fetch from './instance';

export default {
    type: () => fetch.get(`/boards/types`),
    list: (query) => fetch.get(`boards?type_id=1&${query}`),
    view: (board_id) => fetch.get(`/boards/${board_id}`),
    set: (data) => fetch.post(`/boards`, data),
    update: (board_id, data) => fetch.put(`/boards/${board_id}`, data),
    delete: (board_id, data) => fetch.delete(`/boards/${board_id}`, { data }),

    // reply
    reply: (board_id, data) => fetch.post(`/boards/${board_id}/comments`, data),
    reply_update: (board_id, comment_id, data) => fetch.put(`/boards/${board_id}/comments/${comment_id}`, data),
    reply_delete: (board_id, comment_id) => fetch.delete(`/boards/${board_id}/comments/${comment_id}`),
};
