import fetch from './instance';

export default {
    // old
    get: (params) => fetch.get(`/contracts`, { params }),

    // new
    schools: (params) => fetch.get(`/contracts/schools`, { params }),
    schools_view: (smom_id) => fetch.get(`/contracts/schools/${smom_id}`),

    // view
    detail: (contract_id, params) => fetch.get(`/contracts/${contract_id}/message-history`, { params }),

    set: (data) => fetch.post('/contracts', data),
    update: (contract_id, data) => fetch.put(`/contracts/${contract_id}`, data),
    delete: (contract_id) => fetch.delete(`/contracts/${contract_id}`),

    plan: () => fetch.get('/contracts/plans'),
    charge: (contract_id, data) => fetch.post(`/contracts/${contract_id}/balance`, data),
    chargeCount: (contract_id, data) => fetch.post(`/contracts/${contract_id}/count`, data),
    finish: (contract_id) => fetch.post(`/contracts/${contract_id}/calculator-finish`),
    contract_finish: (contract_id) => fetch.post(`/contracts/${contract_id}/contract-finish`),

    // excel
    excel_contract_view: (params, onDownloadProgress) => {
        const id = params.contract_id;

        delete params.contract_id;

        return fetch.get(`/contracts//${id}/message-history-excel`, {
            params,
            responseType: 'blob',
            onDownloadProgress,
        });
    },

    excel_contract: (params, onDownloadProgress) => {
        const id = params.contract_id;

        delete params.contract_id;

        return fetch.get(`/contracts/${id}/bill-excel`, {
            params,
            responseType: 'blob',
            onDownloadProgress,
        });
    },

    excel_balence: (params, onDownloadProgress) => {
        const id = params.contract_id;

        delete params.contract_id;

        return fetch.get(`/contracts/${id}/message-balance-excel`, {
            params,
            responseType: 'blob',
            onDownloadProgress,
        });
    },

    excel_balence2: (params, onDownloadProgress) => {
        const id = params.contract_id;

        delete params.contract_id;
        delete params.version;

        return fetch.get(`/contracts/${id}/message-balance-excel2`, {
            params,
            responseType: 'blob',
            onDownloadProgress,
        });
    },
};
