import React, { useRef } from 'react';
import styled, { css } from 'styled-components';

import { Button } from '../Button';

const UploadV2Styled = styled.label`
    display: inline-block;

    i.fa-cloud-upload-alt {
        margin-top: 0.25rem;
        margin-left: 0.56rem;
    }

    input {
        display: none;
    }

    ${(props) =>
        props.block &&
        css`
            width: 100%;
        `}

    & + button {
        margin-left: 1rem;
    }
`;

const defaultProps = {
    children: '파일첨부',
};

const UploadV2 = ({ children, className, block, multiple, loading, onUploadCallback, ...props }) => {
    const upload = useRef(null);

    const uploadHandler = () => {
        upload.current.click();
    };

    const changeHandler = (e) => {
        onUploadCallback && onUploadCallback(e.target.files);
        e.target.value = '';
    };

    return (
        <UploadV2Styled className={className} block={block}>
            <input type="file" ref={upload} multiple onChange={changeHandler} {...props} />
            <Button color="deep_navi" onClick={uploadHandler} loading={loading}>
                <span>{children}</span>
            </Button>
        </UploadV2Styled>
    );
};

UploadV2.defaultProps = defaultProps;

export default UploadV2;
