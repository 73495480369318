import React from 'react';
import styled from 'styled-components';

const ModalFooterStyled = styled.div`
    .Modal-footer {
        &[mode='buttons'] {
            width: 100%;
            display: flex;
            overflow: hidden;

            & > button {
                cursor: pointer;
                flex: 1;
                text-align: center;
                height: 42px;
                line-height: 42px;
                border: none;
                background-color: #3654bd;
                color: #fff;
                font-size: 1.3rem;
                transition: all 0.2s;

                &:hover {
                    background-color: #4f6ccc;
                }

                &:active {
                    background-color: #112d8c;
                }

                &#confirm-ok:focus {
                    box-shadow: inset 0 0 8px 1px #182e77;
                }
            }
        }
    }
`;

const ModalFooter = ({ children, mode }) => {
    return (
        <ModalFooterStyled className="clear-both">
            <div mode={mode} className="Modal-footer">
                {children}
            </div>
        </ModalFooterStyled>
    );
};

export default ModalFooter;
