import React from 'react';
import styled from 'styled-components';

const CardContentStyled = styled.div`
    padding: 12px 16px;

    h4 {
        font-size: 1.7rem;
        padding-bottom: 8px;
    }

    .card-text {
        font-size: 1.3rem;
        word-break: break-all;
    }
`;

const CardContent = ({ title, children, limit }) => {
    return (
        <CardContentStyled limit={limit}>
            <div className="card-content">
                <h4>{title}</h4>
                <div className="card-text">{children}</div>
            </div>
        </CardContentStyled>
    );
};

export default CardContent;
