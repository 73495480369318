import React from 'react';
import styled, { css } from 'styled-components';

const SubTitleStyled = styled.div`
    margin-bottom: 0.4rem;
    padding-left: 0.2rem;
    font-weight: normal;
    color: ${(props) => (props.color ? props.theme[props.color] : '#868a9c')}
        ${(props) => {
            switch (props.size) {
                case 'small':
                    return css`
                        font-size: 1.1rem;
                    `;
                case 'large':
                    return css`
                        font-size: 1.4rem;
                    `;
                default:
                    return css`
                        font-size: 1.2rem;
                    `;
            }
        }}
        ${(props) =>
            props.mark &&
            `
        > div::after {
            content: ' *';
            color: #e64a51;
            font-size: 1.4rem;
        }
    `}
        ${(props) =>
            props.textAlign &&
            css`
                text-align: ${props.textAlign};
            `};
`;

const SubTitle = ({ mark, children, textAlign, color, ...props }) => {
    return (
        <SubTitleStyled mark={mark} textAlign={textAlign} color={color} {...props}>
            <div>{children}</div>
        </SubTitleStyled>
    );
};

SubTitle.defaultProps = {
    mark: false,
};

export default SubTitle;
