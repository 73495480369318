import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useAlert } from '../contexts/AlertProvider';

import { getPlanThunk } from '../modules/contract/thunk';

import {
    NewContract,
    // UsePrint,
    Board,
    SchoolNumbers,
    Dashborad,
    Teachers,
    ServiceSchools,
    Notice,
    Message,
    Error,
    Performance,
    Qna,
    TeacherQnaPage,
    SearchUser,
    Sruvey,
    Church,
    Nice,
    Email,
    Talk,
} from '../pages';

import SideNav from '../components/base/SideNav';
import { setSide } from '../modules/common/actions';

const MainPage = ({ alert }) => {
    const [user, setUser] = useState({});
    const dispatch = useDispatch();
    const { side } = useSelector((state) => ({ ...state.common }));

    const sideHandler = () => {
        dispatch(setSide());
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));

        if (user) {
            // user 없으면 plan 불러오지 말 것
            setUser(user);
            dispatch(getPlanThunk({ alert }));
        }
    }, [dispatch]);

    return (
        <div id="App">
            <SideNav user={user} side={side} onSideHandler={sideHandler} />

            <Suspense fallback={<div>loading</div>}>
                <Switch>
                    {/** admin */}
                    <Route path="/dashboard" component={Dashborad} />
                    <Route path="/notice" component={Notice} />
                    <Route path="/email" component={Email} />

                    {/** school manage */}
                    <Route path="/contract" component={NewContract} />
                    <Route path="/numbers" component={SchoolNumbers} />
                    <Route path="/schools" component={ServiceSchools} />
                    <Route path="/performance" component={Performance} />
                    <Route path="/board" component={Board} />
                    <Route path="/nice" component={Nice} />
                    <Route path="/talk" component={Talk} />

                    {/** user manage */}
                    <Route path="/user" component={SearchUser} />
                    <Route path="/teachers" component={Teachers} />
                    <Route path="/message" component={Message} />
                    <Route path="/qna" component={Qna} />
                    <Route path="/teacher-qna" component={TeacherQnaPage} />
                    <Route path="/survey" component={Sruvey} />

                    {/** church */}
                    <Route path="/church" component={Church} />

                    {/** not found */}
                    <Route component={Error} />
                </Switch>
            </Suspense>
        </div>
    );
};

export default useAlert(MainPage);
