import { createAction, handleActions } from 'redux-actions';
import { messageAPI } from '../api';
import { find } from '../lib/query';
import { isNull, promiseErrorHandler, encodeQuery } from '../lib/utils';

export const INITIALIZE = 'message/INITIALIZE';
export const GET_MESSAGE = 'message/GET_MESSAGE';
export const GET_SCHOOL_MESSAGE = 'message/GET_SCHOOL_MESSAGE';
export const GET_SCHOOL_MESSAGE_VIEW = 'message/GET_SCHOOL_MESSAGE_VIEW';
export const UPDATE_MESSAGE = 'message/UPDATE_MESSAGE';
export const CHANGE_FILTER = 'message/CHANGE_FILTER';

export const MESSAGE_SUCCESS = 'message/MESSAGE_SUCCESS';
export const MESSAGE_PENDING = 'message/MESSAGE_PENDING';
export const MESSAGE_FAILURE = 'message/MESSAGE_FAILURE';

export const initialize = createAction(INITIALIZE);
export const getMessage = createAction(GET_MESSAGE);
export const getSchoolMessage = createAction(GET_SCHOOL_MESSAGE);
export const getSchoolMessageView = createAction(GET_SCHOOL_MESSAGE_VIEW);
export const updateMessage = createAction(UPDATE_MESSAGE);
export const changeFilter = createAction(CHANGE_FILTER);

export const messagePender = (value) => {
    switch (value) {
        case 'success':
            return { type: MESSAGE_SUCCESS };
        case 'pending':
            return { type: MESSAGE_PENDING };
        case 'failure':
            return { type: MESSAGE_FAILURE };
        default:
            return {};
    }
};

export const getAsync = (phone) => async (dispatch) => {
    dispatch(messagePender('pending'));

    try {
        const { res } = await messageAPI.get(phone);
        dispatch(getMessage(res));
        dispatch(messagePender('success'));
    } catch (error) {
        throw new Error(error);
    }
};

export const getSchoolMessageAsync = (school_id) => (dispatch, getState) => {
    dispatch(messagePender('pending'));

    const { common, message } = getState();
    const { page, limit } = common['scm_pl'];
    const query = encodeQuery({
        page,
        limit,
        search_word: message.search_word,
    });

    return messageAPI
        .get_message(school_id, query)
        .then(({ res }) => {
            dispatch(messagePender('success'));
            dispatch(getSchoolMessage(res));
        })
        .catch(promiseErrorHandler);
};

export const getSchoolMessageViewAsync =
    (school_id, message_request_id) => (dispatch, getState) => {
        dispatch(messagePender('pending'));
        const { common } = getState();
        const { page, limit } = common['scm_view_pl'];
        const query = encodeQuery({
            page,
            limit,
        });

        return messageAPI
            .get_view_message(school_id, message_request_id, query)
            .then(({ res }) => {
                dispatch(messagePender('success'));
                dispatch(getSchoolMessageView(res));
            })
            .catch(promiseErrorHandler);
    };

export const updateFailMessage = (school_id, message_request_id, alert) => (dispatch) => {
    return messageAPI
        .update_fail_message(school_id, message_request_id)
        .then(() => {
            dispatch(getSchoolMessageViewAsync(message_request_id));
            alert.success('code::success update');
        })
        .catch((err) => {
            alert.error(err.message);
        });
};

export const filterAsync = (name, value) => (dispatch, getState) => {
    dispatch(changeFilter({ name, value }));

    let messages = [];
    const { message } = getState();

    if (message.messages.length) {
        if (message.result) {
            const resultSearch = isNull(message.result.value) ? null : `%${message.result.value}%`;

            messages = find(message.messages, {
                where: { result: resultSearch },
            });
        }

        dispatch(updateMessage({ messages }));
    }
};

const initialState = {
    messages: [],
    message_count: 0,
    scm_messages: [],
    scm_count: 0,
    scm_view_messages: [],
    scm_view_count: 0,
    loading: false,
    failure: false,
    // filter
    type: null,
    result: null,
    recent: true,
    search_word: null,
};

export default handleActions(
    {
        [GET_MESSAGE]: (state, action) => {
            const messages = !action.payload.data
                ? []
                : action.payload.data.map((message) => ({
                      ...message,
                      visible: true,
                  }));
            return { ...state, messages };
        },

        [GET_SCHOOL_MESSAGE]: (state, action) => {
            const { data, count } = action.payload;

            return {
                ...state,
                scm_messages: data,
                scm_count: count,
            };
        },

        [GET_SCHOOL_MESSAGE_VIEW]: (state, action) => {
            const { data, count } = action.payload;
            return {
                ...state,
                scm_view_messages: data,
                scm_view_count: count,
            };
        },

        [CHANGE_FILTER]: (state, action) => {
            const { name, value } = action.payload;

            return {
                ...state,
                [name]: value,
            };
        },

        [UPDATE_MESSAGE]: (state, action) => {
            const { messages } = action.payload;
            return {
                ...state,
                messages,
            };
        },

        [INITIALIZE]: (state, action) => {
            switch (action.payload) {
                case 'message':
                    return { ...state, messages: [], message_count: 0 };
                case 'scm_message':
                    return {
                        ...state,
                        scm_messages: [],
                        scm_count: 0,
                        search_word: '',
                    };
                case 'scm_view_messages':
                    return { ...state, scm_view_messages: [], scm_count: 0 };
                default:
                    return state;
            }
        },

        // pending
        [MESSAGE_SUCCESS]: (state) => ({
            ...state,
            loading: false,
            failure: false,
        }),
        [MESSAGE_PENDING]: (state) => ({
            ...state,
            loading: true,
            failure: false,
        }),
        [MESSAGE_FAILURE]: (state) => ({
            ...state,
            loading: false,
            failure: true,
        }),
    },
    initialState
);
