import React from 'react';
import styled, { css } from 'styled-components';

const ImageStyled = styled.img`
    ${(props) =>
        props.round &&
        css`
            border-radius: 50%;
        `}

    max-width: ${(props) => (props.width ? props.width : '200px')};
    border: 1px solid transparent;
    font-size: 0;

    &:hover {
        border-color: ${(props) => props.theme.line};
    }
`;

const defaultProps = {
    src: '',
    loading: 'auto',
};

const Image = ({ src, lazy, className, ...props }) => {
    if (lazy) {
        const classNameMerge = `${className} lazy`;
        return (
            <ImageStyled
                src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                data-src={src}
                className={classNameMerge}
                {...props}
            ></ImageStyled>
        );
    }

    return <ImageStyled src={src} {...props}></ImageStyled>;
};

Image.defaultProps = defaultProps;

export default Image;
