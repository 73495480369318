import React from 'react';
import styled from 'styled-components';
import RadioContext from '../RadioGroup/RadioContext';

const RadioStyled = styled.label`
    overflow: hidden;
    border-top: 1px solid ${(props) => props.theme.line};
    border-bottom: 1px solid ${(props) => props.theme.line};
    height: 3.6rem;
    line-height: 3.4rem;
    cursor: pointer;

    input[type='radio'] {
        display: none;

        &:checked + .radio-visible {
            background-color: ${(props) => props.theme.primary};
            color: #ffffff;
        }
    }

    & + & {
        border-left: 1px solid ${(props) => props.theme.line};
    }

    .radio-visible {
        font-size: 1.2rem;
        padding: 0 1.6rem;
    }

    &:first-child {
        border-left: 1px solid ${(props) => props.theme.line};
        border-radius: 5px 0 0 5px;
    }

    &:last-child {
        border-right: 1px solid ${(props) => props.theme.line};
        border-radius: 0 5px 5px 0;
    }
`;

const Radio = ({ children, value = '', ...props }) => {
    const { name, value: selectedValue, onChange } = React.useContext(RadioContext);
    return (
        <RadioStyled>
            <input
                {...props}
                type="radio"
                name={name}
                value={value.toString()}
                checked={String(selectedValue || '') === String(value || '')}
                onChange={onChange}
            />
            <div className="radio-visible">{children}</div>
        </RadioStyled>
    );
};

export default Radio;
