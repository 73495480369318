import { handleActions } from 'redux-actions';
import { produce } from 'immer';
import moment from 'moment';

import {
    SET_QNA_CATEGORY,
    QNA_HISTORY_FAILURE,
    QNA_HISTORY_REQUEST,
    QNA_HISTORY_SUCCESS,
    SET_LOGIN,
    SET_LOGOUT,
    MESSAGE_HISTORY_FAILURE,
    MESSAGE_HISTORY_SUCCESS,
    MESSAGE_HISTORY_REQUEST,
    SET_CLEANUP,
    EMAIL_LIST_FAILURE,
    EMAIL_LIST_SUCCESS,
    EMAIL_LIST_REQUEST,
    SET_EMAIL,
    SET_EMAIL_FILTER,
    EMAIL_HISTORY_REQUEST,
    EMAIL_HISTORY_SUCCESS,
    EMAIL_HISTORY_FAILURE,
    EMAIL_CHECKED,
    HISTORY_CHECKED,
    EMAIL_ALL_CHECKED,
    EMAIL_REWRITE,
    TEACHER_LIST_REQUEST,
    TEACHER_LIST_FAILURE,
    TEACHER_LIST_SUCCESS,
    TEACHER_CHECKED,
    TEACHER_ALL_CHECKED,
    HISTORY_ALL_CHECKED,
    SURVEY_RESULT_FAILURE,
    SURVEY_RESULT_REQUEST,
    SURVEY_RESULT_SUCCESS,
    SURVEY_FINISH_CHECKED,
} from './actions';

import { setHeader } from '../../api';
import { msgType } from '../../lib/staticdata';

const initialState = {
    connect: false,

    user: {
        name: null,
        position: null,
    },

    qnas: [],
    qna_category: [],
    message: [],
    email: [],
    email_history: [],
    teachers: [],
    survey_result: [],

    message_count: 0,

    loading: {
        qna: false,
        message: false,
        email: false,
        email_history: false,
        teacher: false,
        survey: false,
    },

    count: {
        qna: 0,
        email: 0,
        email_history: 0,
        survey: 0,
    },

    search_word: {
        email: null,
        email_history: null,
    },

    email_rewrite: null,

    answer_list: [
        { label: '메시지 수신여부 문의', value: 0 },
        { label: '메시지 알림 받기 오류', value: 1 },
        { label: '앱알림 아닌 문자로 발송받는 경우', value: 2 },
        { label: '메시지 삭제', value: 3 },
        { label: '회신 응답 문의-알림으로 받을 경우', value: 4 },
        { label: '회신방법', value: 5 },
        { label: '상벌점을 못받아요 안보여요', value: 6 },
        { label: '회원탈퇴', value: 7 },
        { label: '홈페이지 연동 문의(오류)', value: 8 },
        { label: '앱설치 안됨', value: 9 },
        { label: '앱 업데이트', value: 10 },
        { label: '앱 튕김현상', value: 11 },
        { label: '건의사항', value: 12 },
        { label: '뷰어어플 설치(폴라리스뷰어)', value: 13 },
        { label: '타 뷰어 어플 광고(클레임)', value: 14 },
        { label: '학교에 문의하세요', value: 15 },
        { label: '시간표', value: 16 },
        { label: '시간표 새학기', value: 17 },
        { label: '전화번호 변경요청', value: 18 },
        { label: '이름 변경요청', value: 19 },
        { label: '학교 소식', value: 20 },
        { label: '학년, 반, 번호 설정문의', value: 21 },
        { label: '부, 모 카테고리 설정 변경문의', value: 22 },
        { label: '다자녀 추가 문의-타학교', value: 23 },
        { label: '다자녀 추가 문의-같은학교', value: 24 },
        { label: '학교 변경 및 앱삭제 문의', value: 25 },
        { label: '첨부파일 문의- 내용 확인 어려움', value: 26 },
        { label: '앱 설치 확인요청', value: 27 },
        { label: '1:1 채팅문의', value: 28 },
        { label: '캐시버튼문의', value: 29 },
        { label: '캐시버튼 ios', value: 30 },
        { label: '캐시버튼 포인트 없어졌어요', value: 31 },
        { label: '학부모 및 학생 pc버젼 사용 문의', value: 32 },
        { label: 'ios 어느시점 이후에 메시지 안보인다', value: 33 },
        { label: 'ios 알림 오류', value: 34 },
        { label: '앱 or 첨부파일 실행 안됨 - 안드로이드', value: 35 },
        { label: '갑자기 앱 오류로 실행이 안될 때', value: 36 },
        { label: '광고 차단 문의', value: 37 },
        { label: '급식연동 문의', value: 38 },
        { label: '시간표 일정보기 안보임', value: 39 },
        { label: '학교 추가 요청', value: 40 },
        { label: '유선상으로 답변한 경우', value: 41 },
        { label: '유선 상담이 필요할 경우', value: 42 },
        { label: '오류해결안내', value: 43 },
        { label: '홈페이지 연동 차단됨', value: 44 },
    ],

    answer_teacher_list: [
        { label: '유료 요금제 문의', value: 0 },
        { label: '앱 선생님 버전 안내', value: 1 },
        { label: '메시지대기 문의', value: 2 },
        { label: '선생님 로그인 승인', value: 3 },
        { label: '상벌점 삭제 문의- 선생님', value: 4 },
        { label: '건의사항', value: 5 },
        { label: '유선상으로 답변한 경우', value: 6 },
        { label: '유선 상담이 필요할 경우', value: 7 },
        { label: '요청 서류', value: 8 },
        { label: '교직원 학교 변경', value: 9 },
        { label: '교직원 탈퇴', value: 10 },
        { label: '담임변경', value: 11 },
        { label: '관리자위임', value: 12 },
        { label: '교직원 승인신청', value: 13 },
        { label: '비밀번호 찾기', value: 14 },
        { label: '휴대폰 번호 변경', value: 15 },
        { label: '결제함 문의', value: 16 },
        { label: '개별메시지', value: 17 },
        { label: '학생추가 문의', value: 18 },
        { label: '회신(다시만들기)', value: 19 },
        { label: '회신(미응답자 재발송)', value: 20 },
        { label: '회신 수정', value: 21 },
        { label: '회신(하위질문)', value: 22 },
        { label: '그룹추가(교직원명단)', value: 23 },
        { label: '홈페이지 연동', value: 24 },
        { label: '이미 연동된 게시물 수정삭제 문의', value: 25 },
        { label: '오류해결', value: 26 },
        { label: '교표변경', value: 27 },
        { label: '교사 번호 공개 여부 문의', value: 28 },
        { label: '급식 문의', value: 29 },
    ],
};

export default handleActions(
    {
        [SET_LOGIN]: (state, action) =>
            produce(state, (draft) => {
                const { access_token, name, position } = action.payload;

                setHeader(access_token);

                localStorage.setItem('user', JSON.stringify({ access_token, name, position }));

                draft.connect = true;
                draft.user.name = name;
                draft.user.position = position;
            }),

        [SET_LOGOUT]: (state) =>
            produce(state, (draft) => {
                localStorage.clear();

                draft.connect = false;
                draft.user = {
                    name: null,
                    position: null,
                };
            }),

        [SET_CLEANUP]: (state, action) =>
            produce(state, (draft) => {
                if (action.payload === 'message') {
                    draft.message = [];
                    draft.message_count = 0;
                }
            }),

        [SET_QNA_CATEGORY]: (state, action) =>
            produce(state, (draft) => {
                draft.qna_category = action.payload;
            }),

        [MESSAGE_HISTORY_FAILURE]: (state) =>
            produce(state, (draft) => {
                draft.loading.message = false;
            }),

        [MESSAGE_HISTORY_REQUEST]: (state) =>
            produce(state, (draft) => {
                draft.message = [];
                draft.loading.message = true;
            }),

        [MESSAGE_HISTORY_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                const { count, data } = action.payload;
                draft.loading.message = false;
                draft.message_count = count;
                draft.message = data.map(({ send_date, receiver_type, ...rest }) => ({
                    ...rest,
                    receiver_type: msgType.receiver[receiver_type],
                    send_date: send_date === 'Invalid date' ? '미발송' : moment(send_date).format('YYYY-MM-DD HH:mm'),
                }));
            }),

        [QNA_HISTORY_FAILURE]: (state) =>
            produce(state, (draft) => {
                draft.loading.qna = false;
            }),
        [QNA_HISTORY_REQUEST]: (state) =>
            produce(state, (draft) => {
                draft.loading.qna = true;
            }),
        [QNA_HISTORY_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                const { data, count } = action.payload;

                const qnaFormat = data.map(({ category_id, ...rest }) => ({
                    ...rest,
                    category_name: state.qna_category.find((item) => item.id === category_id).name,
                }));

                draft.loading.qna = false;
                draft.qnas = qnaFormat;
                draft.count.qna = count;
            }),

        [SET_EMAIL_FILTER]: (state, action) =>
            produce(state, (draft) => {
                draft.search_word.email = action.payload;
            }),

        [EMAIL_LIST_FAILURE]: (state) =>
            produce(state, (draft) => {
                draft.loading.email = false;
            }),

        [EMAIL_LIST_REQUEST]: (state) =>
            produce(state, (draft) => {
                draft.loading.email = true;
            }),

        [EMAIL_LIST_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loading.email = false;

                const foramt = action.payload.data.map(({ created_at, ...rest }) => ({
                    ...rest,
                    checked: false,
                    created_at: moment(created_at).format('YYYY-MM-DD H:m:s'),
                }));

                draft.email = foramt;
                draft.count.email = foramt.length;
            }),

        [EMAIL_CHECKED]: (state, action) =>
            produce(state, (draft) => {
                const id = action.payload;

                for (let i = 0; i < draft.email.length; i++) {
                    if (draft.email[i].id === id) {
                        draft.email[i].checked = !draft.email[i].checked;
                        break;
                    }
                }
            }),

        [EMAIL_ALL_CHECKED]: (state, action) =>
            produce(state, (draft) => {
                const next = action.payload;

                for (let i = 0; i < draft.email.length; i++) {
                    draft.email[i].checked = next;
                }
            }),

        [SET_EMAIL]: (state, action) =>
            produce(state, (draft) => {
                draft.email.push(action.payload);
            }),

        [EMAIL_HISTORY_FAILURE]: (state) =>
            produce(state, (draft) => {
                draft.loading.email_history = false;
            }),

        [EMAIL_HISTORY_REQUEST]: (state) =>
            produce(state, (draft) => {
                draft.loading.email_history = true;
            }),

        [EMAIL_HISTORY_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                const { data, count } = action.payload;

                const histoty_format = data.map(({ created_at, ...rest }) => ({
                    ...rest,
                    checked: false,
                    created_at: moment(created_at).format('YYYY-MM-DD H:m:s'),
                }));

                draft.email_history = histoty_format;
                draft.count.email_history = count;
                draft.loading.email_history = false;
            }),

        [HISTORY_CHECKED]: (state, action) =>
            produce(state, (draft) => {
                const id = action.payload;

                for (let i = 0; i < draft.email_history.length; i++) {
                    if (draft.email_history[i].id === id) {
                        draft.email_history[i].checked = !draft.email_history[i].checked;
                        break;
                    }
                }
            }),

        [HISTORY_ALL_CHECKED]: (state, action) =>
            produce(state, (draft) => {
                const next = action.payload;

                for (let i = 0; i < draft.email_history.length; i++) {
                    draft.email_history[i].checked = next;
                }
            }),

        [EMAIL_REWRITE]: (state, action) =>
            produce(state, (draft) => {
                if (action.payload === 'reset') {
                    draft.email_rewrite = null;
                    return;
                }

                const initial = {
                    title: null,
                    content: null,
                    receiver: [],
                    files: [],
                };

                draft.email_rewrite = { ...initial, ...action.payload };
            }),

        [TEACHER_LIST_REQUEST]: (state) =>
            produce(state, (draft) => {
                draft.loading.teacher = true;
            }),

        [TEACHER_LIST_FAILURE]: (state) =>
            produce(state, (draft) => {
                draft.loading.teacher = false;
            }),

        [TEACHER_LIST_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loading.teacher = false;
                draft.teachers = action.payload.map((item, index) => {
                    return {
                        ...item,
                        checked: false,
                        unique: index,
                    };
                });
            }),

        [TEACHER_CHECKED]: (state, action) =>
            produce(state, (draft) => {
                for (let i = 0; i < draft.teachers.length; i++) {
                    if (action.payload === draft.teachers[i].unique) {
                        draft.teachers[i].checked = !draft.teachers[i].checked;
                        break;
                    }
                }
            }),

        [TEACHER_ALL_CHECKED]: (state, action) =>
            produce(state, (draft) => {
                for (let i = 0; i < draft.teachers.length; i++) {
                    if (draft.teachers[i].email) {
                        draft.teachers[i].checked = action.payload;
                    }
                }
            }),

        [SURVEY_RESULT_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loading.survey = false;
            }),
        [SURVEY_RESULT_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loading.survey = true;
            }),
        [SURVEY_RESULT_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                const { data, count } = action.payload;

                draft.loading.survey = false;
                draft.survey_result = data;
                draft.count.survey = count;
            }),

        [SURVEY_FINISH_CHECKED]: (state, action) =>
            produce(state, (draft) => {
                const { payload } = action;

                if (typeof payload === 'number') {
                    draft.survey_result[payload].finish = !draft.survey_result[payload].finish;
                }
            }),
    },
    initialState
);
