import { css, keyframes } from 'styled-components';
import { lighten, darken } from 'polished';

const screenSize = {
    tablet: 1280,
};

export const media = (screen, styles) => {
    return css`
        @media (max-width: ${screenSize[screen]}px) {
            ${styles}
        }
    `;
};

export const gray = ['#fafafa', '#e0e0e0', '#cecece', '#c1c1c1', '#a7a7a7', '#8e8e8e', '#747474', '#606060', '#4d4d4d'];

export const white = '#ffffff';
export const black = '#262626';
export const semi_black = lighten(0.1, black);
export const disabled_color = gray[6];
export const primary = '#161c33';
export const red = '#f13b3b';
export const yellow = '#ffca54';
export const blue = '#1a70da';
export const deepblue = '#3654bd';
export const coolblue = '#2e42a7';
export const green = '#5cc259';
export const lg_green = '#44de97';
export const line = '#dedede';
export const size = {
    nav: 210,
    header: 56,
};

export const colorMap = {
    white,
    black,
    semi_black,
    primary,
    red,
    yellow,
    blue,
    deepblue,
    green,
    lg_green,
    line,
};

export const gradient = (to, colors = []) => {
    const format = colors.map(({ color, fill }) => {
        const setColor = colorMap[color] || color;
        return `${setColor} ${fill}`;
    });
    return `background: linear-gradient(${to}, ${format.join(', ')});`;
};

export const ghost = css`
    background-color: transparent;
    border-color: transparent;
    color: ${black};

    &:hover {
        background-color: transparent;
    }
`;

export const success = css`
    background-color: #51da69;
    border-color: #51da69;
    color: ${white};

    &:hover {
        background-color: ${lighten(0.1, '#51da69')};
    }
`;

export const info = css`
    background-color: #1a70da;
    border-color: #1a70da;
    color: ${white};

    &:hover {
        background-color: ${lighten(0.1, '#1a70da')};
    }
`;

export const warning = css`
    background-color: #ffca54;
    border-color: #ffca54;
    color: black;

    &:hover {
        background-color: ${lighten(0.1, '#ffca54')};
    }
`;

export const error = css`
    background-color: ${red};
    border-color: ${red};
    color: ${white};

    &:hover {
        background-color: ${lighten(0.05, red)};
    }
`;

export const light_green = css`
    background-color: ${lg_green};
    border-color: ${lg_green};
    color: ${white};

    &:hover {
        background-color: ${lighten(0.1, lg_green)};
    }
`;

export const deep_navi = css`
    background-color: #24344b;
    border-color: #24344b;
    color: ${white};

    &:hover {
        background-color: ${lighten(0.1, '#24344b')};
    }
`;

export const disabled = css`
    background-color: ${gray[1]} !important;
    border-color: ${gray[1]} !important;
    color: ${gray[5]} !important;
    cursor: not-allowed !important;

    &:hover {
        background-color: ${gray[1]} !important;
    }
`;

export const angel = css`
    background-color: ${white};
    border-color: ${gray[0]};
    color: ${black};

    &:hover {
        background-color: ${gray[0]};
    }
`;

export const building = css`
    background-color: #505050;
    border-color: #505050;
    color: ${white};
`;

export const defaulten = css`
    background-color: ${primary};
    border-color: ${primary};
    color: ${white};

    &:hover:not(:disabled) {
        background-color: ${lighten(0.1, primary)};
    }
`;

export const orange = css`
    background-color: #ff9d38;
    border-color: #ff9d38;
    color: ${white};
`;

export const teal = css`
    background-color: #00b5ad;
    border-color: #00b5ad;
    color: ${white};

    &:hover {
        background-color: #009c95;
    }
`;

export const darkblue = css`
    background-color: ${darken(0.1, blue)};
    color: ${white};

    &:hover {
        background-color: ${darken(0.2, blue)};
    }
`;

export const coolwater = css`
    background-color: ${coolblue};
    color: ${white};
`;

export const Animation = {
    alert: keyframes`
        0% {
            opacity: 0;
            transform: translateY(20px);
        } 10% {
            opacity: 1;
            transform: translateY(0px);
        } 20% {
            transform: translateY(0px);
        } 80% {
            opacity: 1;
            transform: translateY(0px);
        } 100% {
            opacity: 0;
            transform: translateY(-20px);
            
        }
    `,
    bar: keyframes`
        from {left: -200px; width: 120px; background-color: #46de97;}
        50% {width: 40%;}
        70% {width: 80%;}
        80% {left: 60%;}
        95% {left: 110%;}
        to {left: 100%; background-color: #3124f7;}
    `,
    rotate: keyframes`
        0% {
            transform: rotateZ(0deg);
        } 100% {
            transform: rotateZ(360deg);
        }
    `,
};
