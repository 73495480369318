import styled from 'styled-components';

export const TableCover = styled.div`
    width: 100%;
    position: relative;
    padding-bottom: 8px;
`;

export const TStyled = styled.table`
    width: 100%;
    font-size: 1.4rem;
    border-collapse: collapse;

    td {
        padding: 10px 8px;
        text-align: center;

        & > div {
            vertical-align: middle;
        }

        &.loading {
            padding: 32px 0;
            text-align: center;
            border-radius: 5px;
        }

        &.empty {
            padding: 32px 0;
            text-align: center;
            border-radius: 5px;
            color: #7e838a;
        }
    }
`;

export const THStyled = styled.thead`
    position: sticky;
    top: 0;
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);

    tr {
        background-color: transparent;
        border-bottom: 1px solid #d4d4d4;
    }

    th {
        font-size: 1.3rem;
        font-weight: 400;
        text-align: center;

        & > div {
            padding: 10px 6px;
        }
    }
`;

export const TBStyled = styled.tbody``;

export const TD = styled.td`
    ${(props) => props.align && `text-align: ${props.align} !important`};
`;

export const TH = styled.th`
    ${(props) => props.align && `text-align: ${props.align} !important`};
    ${(props) =>
        props.sortable &&
        `
            cursor: pointer;
            
            div::after {
                content: '${props.sort === 'asc' ? '\\2191' : '\\2193'}';
                margin-left: 5px;
            }

            &:active div {
                outline: 1px solid #44de97;
            }
        `}
`;

export const TR = styled.tr`
    transition: all 0.3s;

    &:not([aria-focus='true']):not(.row-header) {
        cursor: pointer;

        &:not(:last-child) {
            border-bottom: 1px solid #f5f5f5;
        }

        &:hover {
            background-color: #f5f7fb;
        }
    }

    &[data-focus='true'] {
        background-color: #fff8e9;
    }

    &.row-expanded-item {
        user-select: none;
        transform: translateY(-5px);
        background-color: #f3f3f3 !important;
        display: none;

        td {
            text-align: unset;
            padding: 16px;
        }

        &.active {
            display: table-row;
        }
    }
`;
