import React from 'react';
import styled, { css } from 'styled-components';

import Content from './CardContent';
import Image from './CardImage';

const CardStyled = styled.div.attrs({
    className: 'card',
})`
    display: inline-block;
    width: 100%;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.08);
    border: 1px solid ${(props) => (props.stroke ? props.theme[props.stroke] : 'rgba(0, 0, 0, 0.08)')};
    border-radius: 8px;
    padding-bottom: 16px;

    ${(props) =>
        props.height &&
        css`
            height: ${props.height}px;
        `}
    ${(props) =>
        props.width &&
        css`
            width: ${props.width}px;
        `}

    .card-h {
        display: flex;
        flex-direction: column;
        height: inherit;
    }
`;

const Card = ({ children, width, height, stroke }) => {
    return (
        <CardStyled stroke={stroke} height={height} width={width}>
            <div className="card-h">{children}</div>
        </CardStyled>
    );
};

Card.Content = Content;
Card.Image = Image;

export default Card;
