import React from 'react';
import styled from 'styled-components';

// import { flex_box } from '../../dressroom/layout';
import { classJoin } from '../../../lib/utils';

const ColumnStyled = styled.div.attrs({
    className: 'column',
})`
    display: flex;
    flex-direction: ${(props) => !props.direction && 'column'};
    height: 100%;
    align-items: ${(props) => (props.align ? props.align : 'flex-start')};
    justify-content: ${(props) => (props.justify ? props.justify : 'flex-start')};
`;

const Column = ({ children, style, justify, align, direction, className, ...props }) => {
    const include = Object.keys(props).filter((key) => key.indexOf('col') !== -1);
    const _props = !include.length ? { 'col-24': true } : props;
    const classNames = classJoin({ ..._props, className });

    return (
        <ColumnStyled justify={justify} align={align} direction={direction} style={style} className={classNames}>
            {children}
        </ColumnStyled>
    );
};

Column.defaultProps = {};

export default Column;
