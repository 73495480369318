import { lazy } from '@loadable/component';

// main
export { default as Main } from './MainPage';

// contract
export const NewContract = lazy(() => import('./NewContractPage'));

// temp contract use print
export const Board = lazy(() => import('./BoardPage'));

// performance
export const Performance = lazy(() => import('./PerformancePage'));

// additional number
export const SchoolNumbers = lazy(() => import('./SchoolNumbersPage'));

// schoolmom3 use school
export const ServiceSchools = lazy(() => import('./ServiceSchoolsPage'));

// nice school search
export const Nice = lazy(() => import('./NicePage'));

// dashboard
export const Dashborad = lazy(() => import('./DashboardPage'));

// teachers
export const Teachers = lazy(() => import('./TeachersPage'));

// teachers
export const SearchUser = lazy(() => import('./SearchUserPage'));

// notice
export const Notice = lazy(() => import('./NoticePage'));

// talk
export const Talk = lazy(() => import('./TalkPage'));

// message
export const Message = lazy(() => import('./MessagePage'));

// qna
export const Qna = lazy(() => import('./QnaPage'));

// qna
export const TeacherQnaPage = lazy(() => import('./TeacherQnaPage'));

// email
export const Email = lazy(() => import('./EmailPage'));

// member
export { default as Login } from './LoginPage';
export { default as Logout } from './LogoutPage';

// church
export const Church = lazy(() => import('./ChurchPage'));

// survey evnet
export const Sruvey = lazy(() => import('./Survey'));

// error
export const Error = lazy(() => import('./ErrorPage'));
