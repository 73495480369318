import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { flex_box } from '../../dressroom/layout';
import { lighten } from 'polished';

const DropdownItemStyled = styled.div`
    font-size: 1.25rem;
    cursor: pointer;
    padding: 10px 12px;
    text-align: center;

    .i-zone {
        width: 2.4rem;
        padding-right: 1rem;
    }

    &:hover {
        background-color: ${(props) => lighten(0.5, props.theme.primary)};
    }

    &:active {
        color: #fff;
        background-color: ${(props) => lighten(0, props.theme.primary)};
    }

    ${(props) => props.icon && flex_box({ justify: 'flex-start' })}
    ${(props) => props.disabled && props.theme.disabled}
`;

const DropdownItem = ({ children, handler, disabled, icon, data }) => {
    const IconComponent = useCallback(() => <div className="i-zone">{icon ? <i className={icon} /> : ''}</div>, [icon]);

    const clickHanlder = useCallback(() => {
        if (!disabled) {
            handler(data);
        }
    }, [handler, disabled, data]);

    useEffect(() => {
        return function cleanup() {};
    }, []);

    return (
        <DropdownItemStyled icon={icon} disabled={disabled} onClick={clickHanlder}>
            <IconComponent />
            <div>{children}</div>
        </DropdownItemStyled>
    );
};

DropdownItem.defaultProps = {
    disabled: false,
};

export default DropdownItem;
