import React from 'react';
import TabPanal from './TabPanal';
import Tabs from './Tabs';

import { default as TabProvider } from './TabContext';

const TabFactory = ({ children, active, onTabChange }) => {
    return (
        <TabProvider>
            <Tabs active={active} onTabChange={onTabChange}>
                {children}
            </Tabs>
        </TabProvider>
    );
};

TabFactory.Panal = TabPanal;

export default TabFactory;
