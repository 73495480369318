import React from 'react';
import styled from 'styled-components';
import { codeName } from '../../../api/instance';

import { flex_box } from '../../dressroom/layout';
import { Link } from '../';

const alertIcon = {
    success: 'fas fa-check-circle',
    error: 'fas fa-times-circle',
    info: 'fas fa-exclamation-circle',
    warning: 'fas fa-exclamation-triangle',
};

const AlertItem = styled.div`
    position: relative;
    min-width: 420px;
    height: 48px;
    padding-left: 16px
    text-align: left;
    font-size: 1.4rem;
    color: #ffffff;
    border-radius: 3px;
    ${flex_box({ justify: 'space-between' })}

    opacity: 0;
    animation: ${(props) => props.theme.Animation.alert} ${(props) => props.timer}s 0s alternate;
    
    .message {
        overflow: hidden;
        ${flex_box()}

        i {
            margin-right: 6px;
        }
    }

    .close {
        width: 48px;
        height: 48px;
        line-height: 48px;
        text-align: center;
    }

    & + & {
        margin-top: 8px;
    }

    ${(props) => {
        switch (props.color) {
            case 'success':
                return props.theme.success;
            case 'warning':
                return props.theme.warning;
            case 'error':
                return props.theme.error;
            case 'info':
                return props.theme.info;
            default:
                return props.theme.angel;
        }
    }}


    &.download {
        background-color: #4b83e4;
        border-width: 1px 1px 0px 1px;
        border-style: solid;
        border-color: #4b83e4;
        height: 53px;

        &:hover {
        }
    }
`;

const PercentageStyled = styled.div`
    position: absolute;
    width: 100%;
    height: 5px;
    bottom: 0;
    left: 0;

    .percentage-bar {
        height: 100%;
        width: ${(props) => props.percentage + `%`};
        background-color: rgba(255, 255, 255, 0.56);
        transition: width 0.2s;
    }
`;

const Percentage = ({ percentage }) => {
    return (
        <PercentageStyled percentage={percentage}>
            <div className="percentage-bar" />
        </PercentageStyled>
    );
};

const Alert = ({ id, duration, message, options, onClose }) => {
    let _message = null;
    function closeHandler() {
        onClose();
    }

    if (/(code::)/g.test(message)) {
        const code = message.split('code::');
        _message = codeName[code[1]];
    } else {
        _message = typeof message === 'object' ? message.message : message;
    }

    return (
        <AlertItem color={options.type} timer={duration / 1000}>
            <div className="message">
                <i className={alertIcon[options.type]} />
                {_message}
            </div>
            <div className="close">
                <Link onClick={closeHandler}>
                    <i className="fas fa-times"></i>
                </Link>
            </div>

            {(options.percentage || options.percentage === 0) && <Percentage percentage={options.percentage} />}
        </AlertItem>
    );
};

Alert.defaultProps = {
    message: '',
};

export default Alert;
