import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import common from './common/';
import manage from './manage';
import contractV2 from './contract';
import userV2 from './user/index';
import schoolV2 from './school/index';

import user from './user';
import teacher from './teacher';

import base from './base';
import message from './message';

import church from './church';

const reducers = combineReducers({
    common,
    user,
    contractV2,
    userV2,
    schoolV2,
    base,
    teacher,
    message,
    manage,
    church,
    // pender: penderReducer,
    routing: routerReducer,
});

export default reducers;
