import { createAction } from 'redux-actions';

export const SET_PAGE = 'common/SET_PAGE';
export const SET_LIMIT = 'common/SET_LIMIT';
export const UPDATE_FAIL = 'common/UPDATE_FAIL';
export const INITIALIZE = 'common/INITIALIZE';
export const SET_SIDE = "common/SET_SIDE";

export const setPage = createAction(SET_PAGE);
export const setLimit = createAction(SET_LIMIT);
export const updateFail = createAction(UPDATE_FAIL);
export const initialize = createAction(INITIALIZE);
export const setSide = createAction(SET_SIDE);
