import { createAction, handleActions } from 'redux-actions';
import { userAPI } from '../api';
import moment from 'moment-timezone';
import { hasOwnProperty } from '../lib/utils';

export const SET_SEARCH_USER = 'user/SET_SEARCH_USER';
export const SET_SEARCH_HISTORY = 'user/SET_SEARCH_HISTORY';

export const setSearchUser = createAction(SET_SEARCH_USER);
export const setSearchHistory = createAction(SET_SEARCH_HISTORY);

export const searchUserAsync =
    ({ phone, onAlert }) =>
    async (dispatch) => {
        try {
            const { res } = await userAPI.searchUser(phone);

            if (!hasOwnProperty(res.data, 'id')) {
                onAlert.warning('사용자가 존재하지 않습니다.');
                return;
            }

            dispatch(setSearchUser(res.data));
        } catch (e) {
            onAlert.error(e);
        }
    };

const initialState = {
    user: null,
    users: [],
};

export default handleActions(
    {
        [SET_SEARCH_USER]: (state, action) => {
            const { last_login, signup_date } = action.payload;

            const lastLogin = moment(last_login).format('YYYY년 MM월 DD일 HH시 mm분');
            const signupDate = moment(signup_date).format('YYYY년 MM월 DD일 HH시 mm분');

            return {
                ...state,
                user: {
                    ...action.payload,
                    last_login: lastLogin,
                    signup_date: signupDate,
                },
            };
        },

        [SET_SEARCH_HISTORY]: (state, action) => {
            return {
                ...state,
                users: [...state.users, action.payload],
            };
        },
    },
    initialState
);
