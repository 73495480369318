import React from 'react';

const defaultProps = {
    cols: []
};

const ColGroup = ({ cols }) => {
    const colsList = cols.map((value, key) => (
        <col key={key} width={value ? value : '*'} />
    ));
    return <colgroup>{colsList}</colgroup>;
};

ColGroup.defaultProps = defaultProps;

export default ColGroup;
