import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Input, Button, Divider, Row, Column } from '../../components/common';
import { userAPI } from '../../api';
import { useAlert } from '../../contexts/AlertProvider';
import { useToggle } from '../../lib/hooks';
import { setLogin } from '../../modules/user/actions';
import { hasOwnProperty } from '../../lib/utils';
import { useCallback } from 'react';

const LoginContent = styled.div`
    min-width: 1280px;
    width: 100%;
    height: 100vh;
    background-color: ${(props) => props.theme.primary};

    .admin {
        width: 400px;
        margin-top: -100px;
        margin-left: auto;
        margin-right: auto;

        .form {
            padding: 20px 16px 20px 16px;
            background-color: #fff;
            border-radius: 8px;
        }
    }

    .text-admin {
        font-size: 1.4rem;
        font-weight: bold;
        color: ${(props) => props.theme.primary};
    }
`;

const LoginContainer = ({ alert }) => {
    const [loading, setLoading] = useToggle(false);
    const [id, setId] = useState(null);
    const [password, setPassword] = useState(null);
    const [error, setError] = useState(null);

    const history = useHistory();
    const dispatch = useDispatch();

    const loginHandler = async (ev) => {
        try {
            const response = await userAPI.new_login({ id, password });

            const { accessToken, ...rest } = response.res.data;

            dispatch(setLogin({ ...rest, access_token: accessToken }));
            history.push('/dashboard');
            setLoading(false);
        } catch (e) {
            setLoading(false);
            alert.error(e);
            setError(e);
        }
    };

    const checkUserHandler = useCallback(() => {
        const user = JSON.parse(localStorage.getItem('user'));

        if (user && hasOwnProperty(user, 'access_token')) {
            dispatch(setLogin(user));
            history.push('/dashboard');
        }
    }, [dispatch, history]);

    useEffect(() => {
        checkUserHandler();

        return function cleanup() {};
    }, [checkUserHandler]);

    return (
        <LoginContent>
            <Row>
                <Column justify="center" style={{ margin: '0 auto' }}>
                    <div className="admin">
                        <div className="form">
                            <Divider>
                                <div className="text-admin">관리자 로그인</div>
                            </Divider>
                            <Divider>
                                <Input
                                    block
                                    value={id}
                                    error={error}
                                    size="large"
                                    icon="fas fa-user"
                                    disabled={loading}
                                    placeholder="아이디"
                                    onChange={(e) => {
                                        if (error) {
                                            setError(null);
                                        }
                                        setId(e.target.value);
                                    }}
                                />
                            </Divider>

                            <Divider>
                                <Input
                                    block
                                    type="password"
                                    value={password}
                                    error={error}
                                    size="large"
                                    icon="fas fa-lock"
                                    disabled={loading}
                                    placeholder="패스워드"
                                    onChange={(e) => {
                                        if (error) {
                                            setError(null);
                                        }
                                        setPassword(e.target.value);
                                    }}
                                    onEnter={loginHandler}
                                />
                            </Divider>

                            <Button type="submit" block loading={loading} onClick={loginHandler}>
                                로그인
                            </Button>
                        </div>
                    </div>
                </Column>
            </Row>
        </LoginContent>
    );
};

export default useAlert(LoginContainer);
