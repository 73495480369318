import React, { Component } from 'react';

export const TabContext = React.createContext({ onTab: () => {} });

class TabProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: null,
            labels: [],
        };
    }

    tabHandler = (tab) => {
        this.setState({ tab });
    };

    setTabLabel = (label, tab) => {
        this.setState((prevState) => ({
            tab: prevState.labels.length === 0 ? tab : prevState.tab, // 최초 tab 값 적용
            labels: [...prevState.labels, { label, tab }],
        }));
    };

    render() {
        const { children } = this.props;

        const value = {
            tab: this.state.tab,
            labels: this.state.labels,
            onTab: this.tabHandler,
            setLabel: this.setTabLabel,
        };

        return <TabContext.Provider value={value}>{children}</TabContext.Provider>;
    }
}

export default TabProvider;
