import React, { useReducer } from 'react';
import ConfirmWrapper from './ConfirmWrapper';

const { Provider, Consumer: ConfirmConsumer } = React.createContext();

const initialState = {
    visible: false,
    title: null,
    mode: 'default',
    promptType: null,
    defaultPromptOptions: {
        placeholder: '',
        value: null,
    },
    content: null,
    onOk: null,
    onCancel: null,
};

const ConfirmProvider = ({ children }) => {
    function reducer(state, { type, payload }) {
        switch (type) {
            case 'OPEN':
                return {
                    visible: true,
                    title: payload.title,
                    content: payload.content,
                    mode: !payload.mode ? 'default' : payload.mode,
                    promptType: !payload.promptType ? null : payload.promptType,
                    defaultPromptOptions: !payload.defaultPromptOptions ? {} : payload.defaultPromptOptions,
                    onOk: !payload.onOk ? null : payload.onOk,
                    onCancel: !payload.onCancel ? null : payload.onCancel,
                };
            case 'RESET':
                return {
                    title: null,
                    content: null,
                    mode: 'default',
                    promptType: null,
                    defaultPromptOptions: {
                        placeholder: '',
                        value: null,
                    },
                    visible: false,
                    onOk: null,
                    onCancel: null,
                };
            case 'CLOSE':
                return { ...state, visible: false };
            default:
                return new Error();
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    const confirm = ({ title, content, mode, promptType, defaultPromptOptions, onOk, onCancel }) => {
        dispatch({
            type: 'OPEN',
            payload: { visible: true, title, mode, promptType, defaultPromptOptions, content, onOk, onCancel },
        });
    };

    const agreeHandler = (value) => {
        state.onOk(value);
        dispatch({ type: 'CLOSE' });

        setTimeout(() => {
            dispatch({ type: 'RESET' });
        }, 500);
    };

    const disAgreeHandler = () => {
        if (state.onCancel) {
            state.onCancel();
        }

        dispatch({ type: 'CLOSE' });
    };

    const closeHandler = () => {
        dispatch({ type: 'CLOSE' });
    };

    const confirms = {
        actions: {
            confirm,
        },
    };

    return (
        <Provider value={confirms}>
            {children}
            <ConfirmWrapper
                title={state.title}
                content={state.content}
                visible={state.visible}
                mode={state.mode}
                defaultPromptOptions={state.defaultPromptOptions}
                promptType={state.promptType}
                onAgree={agreeHandler}
                onDisAgree={disAgreeHandler}
                onClose={closeHandler}
            />
        </Provider>
    );
};

export { ConfirmProvider, ConfirmConsumer };
