import React from 'react';
import styled, { css } from 'styled-components';

const LabelStyled = styled.span`
    display: inline-block;
    width: auto;
    max-width: 500px;
    overflow: hidden;
    cursor: default;
    border-radius: 3px;
    vertical-align: middle;

    & + & {
        margin-left: 0.6rem;
    }

    ${(props) => {
        switch (props.size) {
            case 'small':
                return css`
                    padding: 0 4px;
                    height: 18px;
                    line-height: 18px;
                    font-size: 0.8rem !important;
                `;
            case 'large':
                return css`
                    padding: 0 8px;
                    height: 24px;
                    line-height: 24px;
                    font-size: 1.3rem !important;
                `;
            default:
                return css`
                    padding: 0 6px;
                    height: 20px;
                    line-height: 20px;
                    font-size: 1.1rem !important;
                `;
        }
    }}

    ${(props) => props.theme[props.color]}
`;

const LabelDoubleStyled = styled.div`
    span {
        &:first-child {
            border-radius: 3px 0 0 3px;
        }

        &:last-child {
            margin-left: 0;
            border-radius: 0 3px 3px 0;
        }
    }
`;

const defaultProps = {
    size: 'default',
    color: 'defaulten',
};

const Label = ({ children, size, color, double, ...props }) => {
    if (double) {
        return (
            <LabelDoubleStyled>
                <LabelStyled size={size} color={double.color} {...props}>
                    {double.text}
                </LabelStyled>
                <LabelStyled size={size} color={color} {...props}>
                    {children}
                </LabelStyled>
            </LabelDoubleStyled>
        );
    }

    return (
        <LabelStyled size={size} color={color} {...props}>
            {children}
        </LabelStyled>
    );
};

Label.defaultProps = defaultProps;

export default Label;
