import { setPlan, contractSchoolsPender, contractSchoolsViewPender, contractMessagePender } from './actions';
import { contractAPI } from '../../api';
import { lazyLoading } from '../../lib/utils';

// 계약 "학교" 관리
export const getContractSchoolsThunk =
    ({ alert }) =>
    async (dispatch, getState) => {
        const { contractV2, common } = getState();
        const { page, limit } = common['contract_school'];

        dispatch(contractSchoolsPender.request());
        try {
            const response = await contractAPI.schools({
                page,
                limit,
                ...contractV2.filter,
            });

            dispatch(contractSchoolsPender.success(response.res));

            // image
            lazyLoading();
        } catch (e) {
            alert && alert.error(e.message);
            dispatch(contractSchoolsPender.failure());
        }
    };

// 요금표 가져오기
export const getPlanThunk =
    ({ alert }) =>
    async (dispatch, getState) => {
        const { contractV2 } = getState();

        try {
            if (contractV2.plan.length === 0) {
                try {
                    const response = await contractAPI.plan();
                    dispatch(setPlan(response.res));
                } catch (e) {
                    alert && alert.error(e.message);
                }
            } else {
                dispatch(setPlan(null));
            }
        } catch (e) {
            alert && alert.error(e.message);
        }
    };

// 계약학교 상세보기 (V2)
export const getContractViewThunk =
    ({ smom_id, alert }) =>
    async (dispatch) => {
        dispatch(contractSchoolsViewPender.request());
        try {
            const response = await contractAPI.schools_view(smom_id);
            dispatch(contractSchoolsViewPender.success(response.res));
        } catch (e) {
            alert && alert.error(e.message);
            dispatch(contractSchoolsViewPender.failure());
        }
    };

// 발송 조회
export const getContractMessageThunk =
    ({ filter, alert }) =>
    async (dispatch, getState) => {
        const { contractV2, common } = getState();
        const { contract_id, start_date } = contractV2.active_contract;
        const { smom_id } = contractV2.view.info;
        const year = start_date.split('-')[0];

        const { type, ...dates } = filter; // dates = { start_date, end_date }
        const { page, limit } = common['contract_message'];
        dispatch(contractMessagePender.request());
        try {
            const response = await contractAPI.detail(contract_id, { smom_id, year, page, limit, type, ...dates });
            dispatch(contractMessagePender.success(response.res));
        } catch (e) {
            alert && alert.error(e.message);
            dispatch(contractMessagePender.failure());
        }
    };
