export { default as contractAPI } from './contract';
export { default as teacherAPI } from './teacher';
export { default as userAPI } from './user';
export { default as otherAPI } from './other';
export { default as noticeAPI } from './notice';
export { default as messageAPI } from './message';
export { default as schoolAPI } from './school';
export { default as talkAPI } from './talk';
export { default as churchAPI } from './church';

export { setHeader } from './instance';
