import { isBoolean } from 'util';

/**
 * In case
 * 1. Boolean
 * 2. String
 */
export const hasOwnProperty = (obj, props) => Object.prototype.hasOwnProperty.call(obj, props);

export const classJoin = (values) => {
    const classes = Object.keys(values).map((key) => {
        if (isBoolean(values[key]) && !!values[key]) {
            return key;
        } else {
            return values[key];
        }
    });

    const emptyOut = classes.filter((d) => !!d);
    return emptyOut.join(' ');
};

export const keyClass = (values) => {
    const classes = Object.keys(values).map((key) => {
        if (!!values[key]) {
            return key;
        }

        return false;
    });

    const emptyOut = classes.filter((d) => !!d);
    return emptyOut.join(' ');
};

export const comma = (value) => {
    if (!value) return '0';
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const sawNumber = (number, index) => {
    const nN = Math.floor(number / 1000);

    if (nN <= 1000) {
        return [nN, index];
    }

    const nI = index + 1;

    return sawNumber(nN, nI);
};

export const shortNumber = (number = 0) => {
    const i = 1;
    const result = sawNumber(parseInt(number), i);
    return result[0] + ' K';
};

export const sortType = ['asc', 'desc'];

export function nexort(value) {
    const index = sortType.findIndex((d) => d === value);
    const next = index + 1 === 2 ? 0 : 1;
    return sortType[next];
}

export const sortinum = (function () {
    return {
        sortEach: function (value = [], keys = {}) {
            let _value = value;

            Object.keys(keys).forEach((column) => {
                switch (keys[column]) {
                    case 'asc':
                        _value = this.asc(_value, column);
                        break;
                    case 'desc':
                        _value = this.desc(_value, column);
                        break;
                    default:
                        return false;
                }
            });

            return _value;
        },
        asc: function (value, column) {
            return value.sort((a, b) => {
                if (a[column] < b[column]) return -1;
                if (a[column] > b[column]) return 1;
                return 0;
            });
        },
        desc: function (value, column) {
            return value.sort((a, b) => {
                if (a[column] > b[column]) return -1;
                if (a[column] < b[column]) return 1;
                return 0;
            });
        },
    };
})();

export const query = function (table = []) {
    const _table = [...table];

    return {
        find: (config = {}) => {
            return new Promise((resolve) => {
                if (!config.hasOwnProperty('where')) {
                    resolve(_table);
                } else {
                    const keys = Object.keys(config.where);

                    const select = _table.filter((cl) => {
                        const reg = new RegExp(`(${config.where[keys[0]]})`, 'g');

                        return reg.test(cl[keys[0]]);
                    });

                    resolve(select);
                }
            });
        },
    };
};

export const agentEvent = (event) => {
    const user = window.navigator.userAgent.toLocaleLowerCase();
    return /(mobile)/g.test(user) ? 'touchend' : 'click';
};

export const isNull = (value) => typeof value === 'object' && value === null;

export const isEmpty = (value) => value === undefined || value === '' || isNull(value);

export const isArray = (value) => Object.prototype.toString.call(value) === '[object Array]';

export const encodeObject = (obj) => {
    const not_include_null = {};

    Object.keys(obj).forEach((key) => {
        if (!isEmpty(obj[key])) {
            not_include_null[key] = obj[key];
        }
    });

    return not_include_null;
};

export const encodeQuery = (obj) => {
    let query = '';
    const pure = encodeObject(obj);
    const length = Object.keys(pure).length - 1;

    Object.keys(pure).forEach((key, index) => {
        const value = encodeURIComponent(pure[key]);

        if (length === index) {
            query += `${key}=${value}`;
        } else {
            query += `${key}=${value}&`;
        }
    });

    return query;
};

// 전화번호 분할긴능
export const phoneDivider = (number) => {
    const str = number.toString();

    const zero = str[0];

    if (zero !== '0') {
        return str.replace(/(\d{3,4})(\d{4})/g, `$1 - $2`);
    }

    const regexp = /^(02)/.test(str) ? /(\d{2})(\d{3,4})(\d{4})/g : /(\d{3})(\d{3,4})(\d{4})/g;

    return str.replace(regexp, '$1 - $2 - $3');
};

// 늦은 이미지 로딩 * request 가 끝난뒤에 실행해야함
export const lazyLoading = () => {
    let lazyImages = null;

    // IntersectionObserver check in window
    if ('IntersectionObserver' in window) {
        lazyImages = document.querySelectorAll('.lazy');
        // entries is elements
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    var image = entry.target;
                    image.src = image.dataset.src;

                    image.onerror = function () {
                        image.src = 'http://api.school-mom.kr/service/img/school_default.png';
                    };
                    image.classList.remove('lazy');
                    observer.unobserve(image);
                }
            });
        });

        lazyImages.forEach((image) => {
            observer.observe(image);
        });
    }
};

// \n to br
export const nl2br = (value) => value.replace(/\n/g, '<br/>');
// remove br
export const rmbr = (value) => value.replace(/<br+\s>/g, '');
// br to \n
export const br2nl = (value) => value.replace(/<br+\s>/g, '\n');

export const promiseErrorHandler = (error) => {
    throw new Error(error);
};
export const byte2str = (size, options = { limit: 'kb', floor: false }) => {
    const { limit, floor } = options;
    const sizes = ['kb', 'mb', 'gb'];
    let short = size;

    for (let i = 0; i < sizes.length; i++) {
        short = short / 1024;
        short = floor ? Math.ceil(short) : short.toFixed(2);

        if (sizes[i] === limit) {
            break;
        }
    }

    return `${short} ${limit.toUpperCase()}`;
};

// 퍼센트 계산기 return int
export const percentage = (total, target) => ((target / total) * 100).toFixed(2);

// 클립보드 복사기능
export const copy = (content, hook) => {
    const el = document.createElement('textarea');
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    hook && typeof hook === 'function' && hook();
};

export const lazy = (fn, duration) => {
    let timer = null;

    return (value) => {
        clearTimeout(timer);

        timer = setTimeout(() => {
            fn(value);
            timer = null;
        }, duration);
    };
};

export function queries(search = '') {
    if (!search) {
        return {};
    }

    const items = search.substring(1).split('&');
    const result = items.reduce((p, c) => {
        const [key, value] = c.split(/=/);
        p[key] = isNaN(parseInt(value)) ? value : parseInt(value);
        return p;
    }, {});

    return result;
}

export function unionQueries(base, args = {}) {
    const query = new URLSearchParams(base);

    for (let key in args) {
        if (!args[key]) {
            query.delete(key);
            continue;
        }

        if (query.get(key)) {
            query.set(key, args[key]);
        } else {
            query.append(key, args[key]);
        }
    }

    return query.toString();
}
