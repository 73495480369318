import React from 'react';
import styled from 'styled-components';

const TitleStyled = styled.div`
    font-size: ${(props) => props.size || 1.62}rem;
    font-weight: bold;

    .title-description {
        display: inline-block;
        font-size: 1.2rem;
        color: ${(props) => props.theme.disabled_color};
        font-weight: normal;
        margin-left: 1rem;
    }
`;

const Title = ({ children, description, color, size, ...props }) => {
    return (
        <TitleStyled size={size}>
            {children}
            {description && <div className="title-description">{description}</div>}
        </TitleStyled>
    );
};

export default Title;
