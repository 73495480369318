import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import LoginContainer from '../containers/user/LoginContainer';

const LoginStyled = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
`;

function LoginPage() {
    return (
        <>
            <Helmet>
                <title>관리자 로그인</title>
            </Helmet>
            <LoginStyled>
                <LoginContainer />
            </LoginStyled>
        </>
    );
}

export default LoginPage;
