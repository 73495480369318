import { handleActions } from 'redux-actions';
import { produce } from 'immer';
import { sortinum } from '../../lib/utils';

import {
    DASHBOARD_LOADING_INITIALIZE,
    SCHOOL_SEARCH_FAILURE,
    SCHOOL_SEARCH_REQUEST,
    SCHOOL_SEARCH_SUCCESS,
    INFLOW_FAILURE,
    INFLOW_REQUEST,
    INFLOW_SUCCESS,
    DAY_USER_FAILURE,
    DAY_USER_REQUEST,
    DAY_USER_SUCCESS,
    ATTENSTION_SCHOOL_FAILURE,
    ATTENSTION_SCHOOL_REQUEST,
    ATTENSTION_SCHOOL_SUCCESS,
    RANKING_SCHOOL_FAILURE,
    RANKING_SCHOOL_REQUEST,
    RANKING_SCHOOL_SUCCESS,
    SET_RECENT_SCHOOL,
    SCHOOL_NUMBERS_FAILURE,
    SCHOOL_NUMBERS_REQUEST,
    SCHOOL_NUMBERS_SUCCESS,
    LOCAL_SERVER_PERFORMANCE_FAILURE,
    LOCAL_SERVER_PERFORMANCE_REQUEST,
    LOCAL_SERVER_PERFORMANCE_SUCCESS,
    CHANGE_FILTER_VALUE,
    SCHOOL_DATA_UPDATE,
    SERVICE_MESSAGES_FAILURE,
    SERVICE_MESSAGES_REQUEST,
    SERVICE_MESSAGES_SUCCESS,
    SERVICE_MESSAGE_DETAILS_FAILURE,
    SERVICE_MESSAGE_DETAILS_REQUEST,
    SERVICE_MESSAGE_DETAILS_SUCCESS,
    NICE_SCHOOINFO_FAILURE,
    NICE_SCHOOINFO_SUCCESS,
    NICE_SCHOOINFO_REQUEST,
} from './actions';
import { msgType } from '../../lib/staticdata';

const initialState = {
    loading: {
        search: false,
        inflow: false,
        day: false,
        attention: false,
        ranking: false,
        numbers: false,
        performance: false,
        messages: false,
        detail: false,
        nicel: false, // nice _ list
    },

    count: {
        numbers: 0,
        performance: 0,
        messages: 0,
        detail: 0,
        nicel: 0, // nice _ list
    },

    search_school: [], // for contract write
    school_numbers: [],
    local_servers: [],
    service_messages: [],
    service_message_details: [],

    day_user: [],
    attention_schools: [],
    ranking_schools: [],
    inflow: {
        school_count: 0,
        site_notice_count: 0,
        user_coun: 0,
        user_school_count: 0,
    },

    filter: {
        message: {
            search_word: null,
        },
    },

    recent_signin_user_count: {
        data: null,
        reg: 0,
        acc: 0,
        tot: 0,
    },

    nice_school_list: [],
};

export default handleActions(
    {
        [DASHBOARD_LOADING_INITIALIZE]: (state) =>
            produce(state, (draft) => {
                draft.loading = {
                    ...state.loading,
                    inflow: true,
                    day: true,
                    attention: true,
                    ranking: true,
                };
            }),
        [SET_RECENT_SCHOOL]: (state, action) =>
            produce(state, (draft) => {
                const items = draft.day_user;

                if (action.payload === 'asc') {
                    draft.day_user = sortinum.asc(items, 'date');
                }

                if (action.payload === 'desc') {
                    draft.day_user = sortinum.desc(items, 'date');
                }
            }),

        [CHANGE_FILTER_VALUE]: (state, action) =>
            produce(state, (draft) => {
                const { key, name, value } = action.payload;
                draft.filter[key][name] = value;
            }),

        [SCHOOL_DATA_UPDATE]: (state, action) =>
            produce(state, (draft) => {
                const { name, updateSchoolId, value } = action.payload;
                draft.service_schools.reduce((prev, current) => {
                    let temp = current;
                    if (current.id === updateSchoolId) {
                        temp[name] = value;
                    }
                    return [...prev, temp];
                }, []);
            }),

        // * request penders
        [SCHOOL_SEARCH_FAILURE]: (state) =>
            produce(state, (draft) => {
                draft.loading.search = false;
            }),
        [SCHOOL_SEARCH_REQUEST]: (state) =>
            produce(state, (draft) => {
                draft.loading.search = true;
            }),
        [SCHOOL_SEARCH_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                const { schoolList = [] } = action.payload.data;

                draft.loading.search = false;
                draft.search_school = schoolList;
            }),

        [INFLOW_FAILURE]: (state) =>
            produce(state, (draft) => {
                draft.loading.inflow = false;
            }),
        [INFLOW_REQUEST]: (state) =>
            produce(state, (draft) => {
                draft.loading.inflow = true;
            }),
        [INFLOW_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loading.inflow = false;
                draft.inflow = action.payload;
            }),

        [DAY_USER_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loading.day = false;
            }),
        [DAY_USER_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loading.day = true;
            }),
        [DAY_USER_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                const size = action.payload.day_users.length;

                draft.loading.day = false;

                const { acc, reg, ...rest } = action.payload.day_users[size - 1];

                draft.recent_signin_user_count = {
                    ...rest,
                    acc: parseInt(acc),
                    reg: parseInt(reg),
                };

                draft.day_user = draft.day_user = sortinum.asc(action.payload.day_users, 'date');
            }),

        [ATTENSTION_SCHOOL_FAILURE]: (state) =>
            produce(state, (draft) => {
                draft.loading.attention = false;
            }),

        [ATTENSTION_SCHOOL_REQUEST]: (state) =>
            produce(state, (draft) => {
                draft.loading.attention = true;
            }),

        [ATTENSTION_SCHOOL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loading.attention = false;
                draft.attention_schools = action.payload.star_schools;
            }),

        [RANKING_SCHOOL_FAILURE]: (state) =>
            produce(state, (draft) => {
                draft.loading.ranking = false;
            }),

        [RANKING_SCHOOL_REQUEST]: (state) =>
            produce(state, (draft) => {
                draft.loading.ranking = true;
            }),

        [RANKING_SCHOOL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loading.ranking = false;
                draft.ranking_schools = action.payload.raking_schools;
            }),

        [SCHOOL_NUMBERS_FAILURE]: (state) =>
            produce(state, (draft) => {
                draft.loading.numbers = false;
            }),

        [SCHOOL_NUMBERS_REQUEST]: (state) =>
            produce(state, (draft) => {
                draft.school_numbers = [];
                draft.count.numbers = 0;
                draft.loading.numbers = true;
            }),

        [SCHOOL_NUMBERS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loading.numbers = false;
                draft.count.numbers = action.payload.length;
                draft.school_numbers = action.payload;
            }),

        [LOCAL_SERVER_PERFORMANCE_FAILURE]: (state) =>
            produce(state, (draft) => {
                draft.loading.performance = false;
            }),

        [LOCAL_SERVER_PERFORMANCE_REQUEST]: (state) =>
            produce(state, (draft) => {
                draft.loading.performance = true;
                draft.count.performance = 0;
                draft.local_servers = [];
            }),

        [LOCAL_SERVER_PERFORMANCE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loading.performance = false;
                draft.count.performance = action.payload.length;
                draft.local_servers = action.payload;
            }),

        [SERVICE_MESSAGES_FAILURE]: (state) =>
            produce(state, (draft) => {
                draft.loading.messages = false;
            }),

        [SERVICE_MESSAGES_REQUEST]: (state) =>
            produce(state, (draft) => {
                draft.loading.messages = true;
                draft.service_messages = [];
            }),

        [SERVICE_MESSAGES_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                const { data, count } = action.payload;

                const service_messages = data.map(
                    ({ message_type, send_status, send_type, ...rest }) => ({
                        ...rest,
                        send_status: msgType.sendStatus[send_status + 1],
                        message_type: msgType.message[message_type - 1],
                        send_type: msgType.send[send_type],
                    })
                );

                draft.loading.messages = false;
                draft.service_messages = service_messages;
                draft.count.messages = count;
            }),

        [SERVICE_MESSAGE_DETAILS_FAILURE]: (state) =>
            produce(state, (draft) => {
                draft.loading.detail = false;
            }),

        [SERVICE_MESSAGE_DETAILS_REQUEST]: (state) =>
            produce(state, (draft) => {
                draft.loading.detail = true;
                draft.service_message_details = [];
            }),

        [SERVICE_MESSAGE_DETAILS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                const { data, count } = action.payload;
                draft.service_message_details = data;
                draft.loading.detail = false;
                draft.count.detail = count;
            }),

        [NICE_SCHOOINFO_FAILURE]: (state) =>
            produce(state, (draft) => {
                draft.loading.nicel = false;
            }),
        [NICE_SCHOOINFO_REQUEST]: (state) =>
            produce(state, (draft) => {
                draft.loading.nicel = true;
            }),
        [NICE_SCHOOINFO_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                const [{ head }, { row }] = action.payload.schoolInfo;

                draft.loading.nicel = false;
                draft.count.nicel = head[0].list_total_count;
                draft.nice_school_list = row;
            }),
    },
    initialState
);
