import React from 'react';
import styled, { css } from 'styled-components';

const PaginationItemStyled = styled.div`
    height: 32px;
    line-height: 32px;
    min-width: 32px;
    padding: 0 8px;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;

    ${(props) =>
        props.active &&
        css`
            font-weight: bold;
            ${props.theme.info}
        `}
`;

const PaginationItem = ({ page, onPage, active }) => {
    return (
        <PaginationItemStyled active={active} onClick={() => !active && onPage(page)}>
            {page}
        </PaginationItemStyled>
    );
};

export default PaginationItem;
