import axios from 'axios';
import { createHashHistory } from 'history';
import { hasOwnProperty } from '../lib/utils';

const history = createHashHistory();
// process.env.NODE_ENV === 'development'
//     ? createHashHistory()
//     : createBrowserHistory();

const INSTANCE = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
});

const user = JSON.parse(localStorage.getItem('user'));

if (user && user.access_token) {
    INSTANCE.defaults.headers = {
        Authorization: user.access_token,
    };
}

INSTANCE.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

INSTANCE.interceptors.response.use(
    function (response) {
        const { data, headers, status, config } = response;

        const req = config.data || '{}';

        return { res: data, req: JSON.parse(req), headers, status };
    },
    function (error) {
        const unauthorized = '이용시간이 초과되어 자동 로그아웃 됩니다.';

        const { data } = hasOwnProperty(error.response, 'data') ? error.response : { data: null };

        const codeOrerror = typeof data === 'string' ? false : hasOwnProperty(data, 'code') ? 'code' : 'error';

        const message = codeOrerror
            ? !codeName[data[codeOrerror]]
                ? data[codeOrerror]
                : codeName[data[codeOrerror]]
            : codeName[data]
            ? codeName[data]
            : data;

        if (error.request.statusText === 'Unauthorized') {
            history.push('/logout');
            return Promise.reject({ message: message || unauthorized });
        }

        if (data.error === 'jwt expired' || data.error === 'jwt malformed') {
            history.push('/logout');
            return Promise.reject({ message: unauthorized });
        }

        if (data.error === 'No Authorization headers') {
            history.push('/logout');
            return Promise.reject({ message: '올바른 접근이 아닙니다.' });
        }

        if (error.response.status >= 400) {
            return Promise.reject({ message });
        }

        if (!data) {
            console.warn('No code specified(코드가 지정되지 않았습니다.)');
            return;
        }

        return Promise.reject({ message: message || '알 수 없는 오류가 발생했습니다.' });
    }
);

export const codeName = {
    'no code': '알 수 없는 오류 발생',

    // contract
    'no school': '학교를 선택해주세요.',
    'no start date': '시작일을 선택해주세요.',
    'no end date': '종료일을 선택해주세요.',
    'no date': '날짜를 선택해주세요.',
    'no plan': '요금제를 선택해주세요.',
    'no money': '충전금액을 입력해주세요.',
    'no count': '충전건수를 입력해주세요.',
    'no smom_id': '학교 id가 누락 되었습니다.',
    'no balance': '기본건수 충전이 안되어있습니다.',
    'not correct date': '종료일이 시작일보다 과거입니다.',
    'no payment_type': '납입방식을 선택하지 않았습니다.',
    'no api_key': '접근권한이 없습니다.',
    'no contract_ids': '학교 id 없이는 삭제가 불가능 합니다.',
    'success contract': '계약이 등록되었습니다.',
    'contract end': '계약이 종료된 상태입니다.',
    'already exist contract': '이미 존재하는 계약건이 있습니다.',
    'success charge': '충전이 완료 되었습니다.',
    'contract is not finish': '아직 계약상태에 있는 학교입니다.',

    // notice
    'request entity too large': '?',
    invalid_content: '내용을 작성해주세요.',
    invalid_title: '제목을 작성해 주세요',
    invalid_important: '공지 유무를 선택해 주세요.',
    invalid_popup: '팝업 유무를 선택해 주세요.',
    invalid_board_id: '게시글이 유효하지 않습니다.',

    // teacher
    'no phone': '번호를 입력해주세요.',
    'duplicated username': '이미 중복된 아이디가 있습니다.',
    'not correct phone': '입력값이 번호가 아닙니다.',

    // user
    'no id': '아이디를 입력해주세요.',
    'no password': '비밀번호를 입력해주세요.',
    'no matched_password': '아이디 또는 비밀번호를 확인해주세요.',
    'no user': '로그인 정보가 존재하지 않습니다.',
    'Login failure': '아이디 또는 비밀번호를 확인해주세요.',
    'No Authorization headers': '올바른 접근이 아닙니다',
    'jwt expired': '이용시간이 초과되어 자동 로그아웃 됩니다.',

    // global
    'access denied': '접근권한이 없습니다.',
    'server error': '서버에서 문제가 발생했습니다.',
    'success delete': '삭제가 완료되었습니다.',
    'success recycle': '복구가 완료되었습니다.',
    'success update': '업데이트가 완료되었습니다.',
    'success insert': '등록이 완료되었습니다.',
    'start download': '다운로드를 시작합니다.',

    // test
    'just test': '테스트입니다.',
    'jwt malformed': '토큰형태에 문제가 있습니다.',
    not_found: '[404] 서버문제가 발생했습니다.',

    // qna
    already_answer: '이미 답변이 등록된 문의글입니다.',
};

export const setHeader = (token) => {
    INSTANCE.defaults.headers = { Authorization: token };
};

export const request = axios;

export const API_KEY = process.env.REACT_APP_ADMIN_API_KEY;

export default INSTANCE;
