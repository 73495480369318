import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
    const [search, setSearch] = useState(useLocation().search);
    const location = useLocation();

    useEffect(() => {
        setSearch(location.search);
    }, [location]);

    return new URLSearchParams(search);
};

export default useQuery;
