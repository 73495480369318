import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';

import { AlertProvider } from '../contexts/AlertProvider';
import { Alert } from './common';

import { Login, Logout, Main } from '../pages';

import '@fortawesome/fontawesome-free/css/all.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '../styles/base.scss';

// Create a client
const queryClient = new QueryClient();

const App = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const isLogged = user !== null;

    return (
        <QueryClientProvider client={queryClient}>
            <AlertProvider template={Alert} duration={4000}>
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={() => (isLogged ? <Redirect to="/dashboard" /> : <Redirect to="/login" />)}
                    />
                    <Route path="/login" component={Login} />
                    <Route path="/logout" component={Logout} />

                    <Main />
                </Switch>
            </AlertProvider>
        </QueryClientProvider>
    );
};

export default App;
