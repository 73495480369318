import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled, { css } from 'styled-components';
import Input from '../Input/Input';
import { agentEvent, encodeObject, isEmpty } from '../../../lib/utils';

const SelectorStyled = styled.div`
    position: relative;
    display: inline-block;
    cursor: pointer;

    ${(props) =>
        props.block &&
        css`
            width: 100%;
        `}

    label {
        position: relative;
        cursor: pointer;
        display: ${(props) => (props.block ? 'block' : 'inline-block')};
    }
`;

const SelectorOptionsStyled = styled.ul`
    font-size: 1.3rem;
    position: absolute;
    left: 0;
    top: 100%;
    background-color: #fff;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.15);
    z-index: 1004;
    visibility: hidden;
    opacity: 0;
    transform: translateY(7px);
    transition: all 0.2s;
    border: 1px solid #d2d2d2;
    border-radius: 3px;
    max-height: ${(props) => props.maxHeight}px;
    overflow-y: auto;
    min-width: ${(props) => (props.width ? `${props.width}px` : '100%;')}
        ${(props) =>
            props.active &&
            css`
                visibility: visible;
                opacity: 1;
                transform: translateY(2px);
            `};
`;

const OptionStyled = styled.li`
    padding: 14px 14px;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;

    ${(props) =>
        props.width &&
        css`
            max-width: ${props.width}px;
        `}

    ${(props) =>
        props.active
            ? css`
                  background-color: ${props.theme.primary};
                  color: #ffffff;
              `
            : css`
                  background-color: #ffffff;

                  &:hover {
                      background-color: #efefef;
                  }
              `};
`;

const defaultProps = {
    isLabel: 'label',
    options: [],
    placeholder: 'Select to Option',
    maxHeight: 200,
};

const event = agentEvent('click');

const Selector = ({ options, isLabel, value, width, name, block, maxHeight, style, size, placeholder, onChange }) => {
    const [active, setActive] = useState(false);
    const [inValue, setInValue] = useState(null);
    const documentListener = useRef(null);

    const show = () => {
        setActive(true);
        bindDocumentListener();
    };

    const hide = () => {
        setActive(false);
        unbindDocumentListener();
    };

    const unbindDocumentListener = useCallback(async () => {
        if (documentListener.current) {
            document.removeEventListener(event, documentListener.current);
            documentListener.current = null;
        }
    }, []);

    const bindDocumentListener = () => {
        if (!documentListener.current) {
            documentListener.current = () => {
                if (!active) {
                    hide();
                }
            };
        }

        document.addEventListener(event, documentListener.current);
    };

    const clickHandler = () => (!active ? show() : hide());
    const clickToItem = (item, index) => {
        if (onChange) {
            onChange(item, index);
        }
    };

    useEffect(() => {
        if (value && typeof value === 'object') {
            setInValue(value);
        }

        if (!value && inValue) {
            setInValue(null);
        }

        return async function cleanup() {
            if (documentListener.current) await unbindDocumentListener();
        };
    }, [value, isLabel, inValue, unbindDocumentListener]);

    const optionRender = options.map((item, index) => {
        const isState = !inValue ? value : inValue[isLabel];
        const optionActive = isState === item[isLabel];

        return (
            <OptionStyled
                key={index}
                disabled={item.disabled}
                width={width}
                active={optionActive}
                onClick={() => clickToItem(item, index)}
            >
                {item[isLabel]}
            </OptionStyled>
        );
    });

    const customStyle = !style ? encodeObject({ width }) : style;

    // const title = inValue && inValue[isLabel];

    const item = options.find((option) =>
        !isEmpty(value) && typeof value === 'object' ? value[isLabel] === option.value : value === option.value
    );

    const val = !isEmpty(item) && typeof value === 'object' ? item[isLabel] : value;

    const $value = item ? item[isLabel] : val && val[isLabel] ? val[isLabel] : val;

    return (
        <SelectorStyled block={block}>
            <label>
                <Input
                    value={$value || ''}
                    name={name}
                    readOnly
                    size={size}
                    width={width}
                    select={true}
                    placeholder={placeholder}
                    style={customStyle}
                    onClick={clickHandler}
                />
            </label>

            <SelectorOptionsStyled active={active} maxHeight={maxHeight} width={width}>
                {optionRender}
            </SelectorOptionsStyled>
        </SelectorStyled>
    );
};

Selector.defaultProps = defaultProps;

export default Selector;
