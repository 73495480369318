import { isArray, isNull } from './utils';

export const OptionsType = {
    where: null
};

export const find = (rows = [], options = OptionsType) => {
    if (!isArray(rows)) {
        throw new TypeError('rows is not Array');
    }

    if (!options.where) {
        throw new Error('where property is undefined');
    }

    let _rows = [...rows];

    Object.keys(options.where).forEach(key => {
        _rows = _rows.map(row => {
            const like = isNull(options.where[key])
                ? false
                : ql(options.where[key]);

            // null 일때 모두 보여주기
            if (!like && isNull(options.where[key])) {
                return { ...row, visible: true };
            }

            // like 문인지 판단.
            if (like.is) {
                // like 문이면 포함된 문자만 보여주기
                if (row[key].indexOf(like.word()) !== -1) {
                    return { ...row, visible: true };
                }
            }

            // 일반적인 where 절
            if (row[key] === options.where[key]) {
                return { ...row, visible: true };
            }

            // 찾는게 아니면 숨기기
            return { ...row, visible: false };
        });
    });

    return _rows;
};

// query %like%
function ql(val) {
    const part = val.split('');

    return {
        is: part[0] === '%' && part[val.length - 1] === '%',
        word: () => {
            part.pop();
            part.shift();

            return part.join('');
        }
    };
}
