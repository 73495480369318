import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';

import { Input } from '../';
import { classJoin, isEmpty, lazy } from '../../../lib/utils';

const ComboBoxStyled = styled.div`
    position: relative;

    &.small {
        height: 38px;
    }

    &.middle {
        height: 42px;
    }

    &.large {
        height: 46px;
    }

    .comboItems {
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        border: 1px solid ${(props) => props.theme.line};
        z-index: 1004;
        visibility: hidden;
        opacity: 0;
        transform: translateY(15px);
        transition: all 0.3s;
        overflow: auto;

        li {
            cursor: pointer;
            height: 42px;
            padding: 0 16px;
            line-height: 42px;
            font-size: 1.3rem;
            background-color: #fff;

            &.empty {
            }

            &:hover {
                background-color: ${(props) => props.theme.primary};
                color: #ffffff;
            }
        }

        &.active {
            visibility: visible;
            opacity: 1;
            transform: translateY(5px);
        }
    }
`;

const ComboBox = ({
    debounce,
    enter,
    value,
    icon,
    size,
    search,
    items,
    name,
    field,
    loadingSync,
    maxHeight,
    placeholder,
    customRender: CustomComponent,
    onSearch,
    onChange,
    onComplate,
}) => {
    let _value;
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    const changeHandler = useCallback(
        lazy((event) => {
            onComplate({ query: event.value });
            setLoading(false);
        }, debounce),
        []
    );

    const comboEvent = (e) => {
        onSearch(e.target.value);

        // 빈값을 받으면 selector 를 숨긴다.
        if (isEmpty(e.target.value)) {
            setShow(false);
        }

        // enter를 칠때만 검색을 가능하게 바꿀 수 있다. enter=true
        if (enter) {
            if (e.keyCode === 13) {
                changeHandler(e.target);
                setLoading(true);
            }
        } else {
            changeHandler(e.target);
            setLoading(true);
        }
    };

    const selectedHandler = (props, e) => {
        // selector에서 option을 선택하면 value 값을 바꾸고 search 를 null 로 만들고 selector를 숨긴다.
        onChange(props);
        onSearch(null);
        setShow(false);
    };

    const focusHandler = (e) => {
        // focus 주면 items 가 있을 때만 selector를 보여준다.
        if (items.length !== 0) {
            setShow(true);
        }
    };

    const blurHandler = (e) => {
        // blur 했을때 selector를 숨기고, loading을 숨긴다.
        setShow(false);
        setLoading(false);
    };

    useEffect(() => {
        if (!isEmpty(search) && !loadingSync) {
            setShow(true);
            return;
        }

        if (isEmpty(search)) {
            setShow(false);
            return;
        }
    }, [loadingSync, value, search]);

    const classNames = classJoin({ size });

    const comboItems = classJoin({
        comboItems: true,
        active: show,
    });

    if (!value) {
        _value = '';
    } else if (typeof value === 'object') {
        if (!value.hasOwnProperty(field)) {
            _value = value['label'];
        } else {
            _value = value[field];
        }
    } else {
        _value = value;
    }

    const optionItems = items.map((props) => {
        if (CustomComponent && typeof CustomComponent === 'function') {
            return (
                <li key={props.value} onClick={(e) => selectedHandler(props, e)}>
                    <CustomComponent {...props} />
                </li>
            );
        }

        return (
            <li key={props.value} onClick={(e) => selectedHandler(props, e)}>
                {props.label}
            </li>
        );
    });

    return (
        <ComboBoxStyled className={classNames}>
            <Input
                value={_value}
                name={name}
                size={size}
                icon={icon}
                loading={loading || loadingSync}
                placeholder={placeholder}
                onChange={(e) => comboEvent(e)}
                onFocus={focusHandler}
                onBlur={blurHandler}
            />
            <div />

            <div className={comboItems} style={{ maxHeight }}>
                <ul>{items.length === 0 ? <li className="empty">조회된 데이터가 없습니다.</li> : optionItems}</ul>
            </div>
        </ComboBoxStyled>
    );
};

ComboBox.defaultProps = {
    customRender: null,
    duration: 1000,
    enter: false,
    field: 'label',
    loadingSync: false,
    items: [],
    name: '',
    maxHeight: 300,
    size: 'middle',
    placeholder: null,
    value: null,
    onChange: () => {},
    onComplate: () => {},
};

export default ComboBox;
