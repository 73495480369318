import React, { useContext, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import { TabContext } from './TabContext';

const TabsStyled = styled.div`
    .tab-labels {
        display: block;
        border-bottom: 1px solid #c3c3c3;

        .tab-label {
            position: relative;
            display: inline-block;
            padding: 8px 14px;
            font-size: 1.3rem;
            cursor: pointer;
            border-width: 1px 1px 0 1px;
            border-style: solid;
            border-color: #ffffff;

            &.active {
                color: #314590;
                border-bottom: 0px solid #ffffff;
                border-color: #c3c3c3;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: -1px;
                    left: 0px;
                    height: 1px;
                    background-color: #fff;
                    width: 100%;
                }
            }
        }
    }

    .tab-panals {
        padding-top: 10px;
    }
`;

const defaultProps = {};

const Tabs = ({ children, active, onTabChange }) => {
    const { tab: activeTab, labels, onTab } = useContext(TabContext);

    const tabHandler = useCallback(
        (panalTab) => {
            onTab(active || panalTab);
            onTabChange && onTabChange(panalTab);
        },
        [active, onTabChange, onTab]
    );

    useEffect(() => {
        if (active) {
            onTab(active);
        }
    }, [active, onTab]);

    const _active = active || activeTab;

    return (
        <TabsStyled>
            <div className="tab-labels">
                {labels.map(({ label, tab: panalTab }, index) => {
                    const className = ['tab-label', panalTab === _active && 'active'].join(' ');

                    return (
                        <div className={className} key={index} onClick={() => tabHandler(panalTab)}>
                            {label}
                        </div>
                    );
                })}
            </div>
            <div className="tab-panals">{children}</div>
        </TabsStyled>
    );
};

Tabs.defaultProps = defaultProps;

export default Tabs;
