import { createAction, handleActions } from 'redux-actions';
import { teacherAPI } from '../api';

export const GET_TEACHERS = 'teacher/GET_TEACHERS';
export const SET_FILTER = 'teacher/SET_FILTER';
export const UPDATE_TEACHER = 'teacher/UPDATE_TEACHER';

export const getTeachers = createAction(GET_TEACHERS);
export const setFilter = createAction(SET_FILTER);
export const updateTeachers = createAction(UPDATE_TEACHER);

export const getAsync = (phone) => (dispatch) =>
    teacherAPI
        .get(phone)
        .then(({ res }) => {
            dispatch(setFilter(phone));
            dispatch(getTeachers(res));
        })
        .catch((error) => {
            throw new Error(error);
        });

export const updateAsync = ({ name, username, phone, email }) => (dispatch, getState) => {
    const { teacher } = getState();

    const teacher_id = teacher['teacher'].id;

    const data = {};

    // receive data
    if (name !== null) data.name = name;
    if (username !== null) data.username = username;
    if (phone !== null) data.phone = phone;
    if (email !== null) data.email = email;

    return teacherAPI
        .update(teacher_id, data)
        .then(() => {
            dispatch(updateTeachers({ name, username, phone, email }));
        })
        .catch((error) => {
            throw new Error(error);
        });
};

const initialState = {
    teacher: null,
    filter: null,
    notFound: false,
};

export default handleActions(
    {
        [GET_TEACHERS]: (state, action) => ({
            ...state,
            teacher: action.payload.data,
            notFound: state.filter && !action.payload.data ? true : false,
        }),
        [SET_FILTER]: (state, action) => ({
            ...state,
            filter: action.payload,
        }),
        [UPDATE_TEACHER]: (state, action) => {
            const { name, username, phone, email } = action.payload;

            return {
                ...state,
                teacher: {
                    ...state.teacher,
                    name: !name ? state.teacher.name : name,
                    username: !username ? state.teacher.username : username,
                    phone: !phone ? state.teacher.phone : phone,
                    email: !email ? state.teacher.email : email,
                },
            };
        },
    },
    initialState
);
