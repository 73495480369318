import { handleActions } from 'redux-actions';
import { produce } from 'immer';
import moment from 'moment';

import {
    SET_FILTER,
    SET_PLAN,
    SET_ACTIVE_CONTRACT,
    CONTRACT_SCHOOLS_FAILURE,
    CONTRACT_SCHOOLS_SUCCESS,
    CONTRACT_SCHOOLS_REQUEST,
    CONTRACT_MESSAGE_FAILURE,
    CONTRACT_MESSAGE_REQUEST,
    CONTRACT_MESSAGE_SUCCESS,
    CONTRACT_SCHOOLS_VIEW_FAILURE,
    CONTRACT_SCHOOLS_VIEW_REQUEST,
    CONTRACT_SCHOOLS_VIEW_SUCCESS,
    CLEAN_UP,
    SET_RE_CONTRACT_INFO,
    USAGE_STATEMENT_FAILURE,
    USAGE_STATEMENT_REQUEST,
    USAGE_STATEMENT_SUCCESS,
} from './actions';

const initialState = {
    // global
    plan: [],
    plan_select_options: [],
    payment_options: ['일시납', '분기납', '반기납'],
    payment_type_options: ['선불', '후불'],

    // contract
    contracts: [], // 계약
    contract_schools: [], // 계약학교
    view: null, // 계약학교 상세보기
    message: [], // 발송조회
    active_contract: null, // 발송조회 정보 임시저장

    // new contract filter
    filter: {
        search_word: null,
        free: '0', // 0 유료 1 무로
        grady_day: null,
        order: null,
        plan: null,
        payment: null,
        is_all: '0',
    },

    // usage_statement
    usage_statements: [],
    usage_statement_total_price: 0,

    counts: {
        contracts: 0,
        contract_schools: 0,
        message: 0,
        view: 0,
        usage: 0,
    },

    loading: {
        contracts: false,
        contract_schools: false,
        message: false,
        view: false,
        usage: false,
    },

    re_contract: {},
};

export default handleActions(
    {
        [CLEAN_UP]: (state, action) =>
            produce(state, (draft) => {
                const which = action.payload;

                if (which === 'view') {
                    draft.message = initialState.message;
                    draft.active_contract = initialState.active_contract;
                    draft.view = initialState.view;
                    draft.counts = { ...draft.counts, view: 0, message: 0 };
                    draft.view = initialState.view;
                }

                if (which === 'filter') {
                    draft.filter = initialState.filter;
                }

                if (which === 'CONTRACT_ALL') {
                    draft.contract_schools = [];
                    draft.counts.contract_schools = 0;
                    draft.loading.contract_schools = false;
                    draft.filter = initialState.filter;
                }
            }),
        [SET_FILTER]: (state, action) =>
            produce(state, (draft) => {
                const key = action.payload[0];
                const value = action.payload[1];

                draft.filter[key] = value;
            }),

        [SET_PLAN]: (state, action) =>
            produce(state, (draft) => {
                if (action.payload !== null) {
                    const { data } = action.payload;

                    draft.plan = data;
                    draft.plan_select_options = data.map(({ name, plan }) => ({ label: name, value: plan }));
                }
            }),

        [SET_ACTIVE_CONTRACT]: (state, action) =>
            produce(state, (draft) => {
                const { item, callback } = action.payload;
                draft.active_contract = item;
                callback();
            }),

        [SET_RE_CONTRACT_INFO]: (state, action) =>
            produce(state, (draft) => {
                draft.re_contract = action.payload;
            }),

        [CONTRACT_SCHOOLS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loading.contract_schools = false;
            }),

        [CONTRACT_SCHOOLS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.contract_schools = [];
                draft.loading.contract_schools = true;
            }),

        [CONTRACT_SCHOOLS_SUCCESS]: (state, action) => {
            const { data = [], count } = action.payload;
            return produce(state, (draft) => {
                const contract_schools = data.map(({ end_date, start_date, status, ...rest }) => {
                    // 만료 유무 ( default 진행중 )
                    let expired = 0;

                    const end_compare = moment(end_date).diff(moment()) < 0;

                    if (status === 2) {
                        // 계약 예약
                        expired = -1;
                    }

                    if (status === 1 && end_compare) {
                        // 기간 만료
                        expired = 1;
                    }

                    if (status === 0) {
                        // 계약 종료
                        expired = 2;
                    }

                    return {
                        ...rest,
                        status,
                        start_date,
                        end_date,
                        expired,
                    };
                });

                draft.loading.contract_schools = false;
                draft.contract_schools = contract_schools;
                draft.counts.contract_schools = count;
            });
        },

        [CONTRACT_MESSAGE_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loading.message = false;
            }),

        [CONTRACT_MESSAGE_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loading.message = true;
                draft.message = [];
            }),

        [CONTRACT_MESSAGE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loading.message = false;
                draft.message = action.payload.data;
                draft.counts.message = action.payload.count;
            }),

        [CONTRACT_SCHOOLS_VIEW_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loading.view = false;
            }),

        [CONTRACT_SCHOOLS_VIEW_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loading.view = true;
            }),

        [CONTRACT_SCHOOLS_VIEW_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                const { info, contracts } = action.payload.data;

                const __contracts = contracts.map(({ end_date, start_date, status, ...rest }) => {
                    // 만료 유무 ( default 진행중 )
                    let expired = 0;

                    const end_compare = moment(end_date).diff(moment()) < 0;

                    if (status === 2) {
                        // 계약 예약
                        expired = -1;
                    }

                    if (status === 1 && end_compare) {
                        // 기간 만료
                        expired = 1;
                    }

                    if (status === 0) {
                        // 계약 종료
                        expired = 2;
                    }

                    return {
                        ...rest,
                        status,
                        start_date,
                        end_date,
                        expired,
                    };
                });

                draft.loading.view = false;
                draft.view = {
                    info,
                    contracts: __contracts,
                };
                draft.counts.view = action.payload.data.contracts.length;
            }),

        [USAGE_STATEMENT_FAILURE]: (state) =>
            produce(state, (draft) => {
                draft.loading.usage = false;
            }),

        [USAGE_STATEMENT_REQUEST]: (state) =>
            produce(state, (draft) => {
                draft.loading.usage = true;
                draft.usage_statements = [];
                draft.counts.usage = 0;
                draft.usage_statement_total_price = 0;
            }),

        [USAGE_STATEMENT_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                const { data, count } = action.payload;

                draft.loading.usage = false;

                const price = data.reduce((prev, current) => prev + parseInt(current.TOTAL_price, 10), 0);

                draft.usage_statements = data;
                draft.counts.usage = count;
                draft.usage_statement_total_price = price;
            }),
    },
    initialState
);
