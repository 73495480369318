import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setLogout } from '../modules/user/actions';

const LogoutPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        async function action() {
            await dispatch(setLogout());
            history.push('/login');
        }

        action();
    }, [dispatch, history]);

    return <div />;
};

export default LogoutPage;
