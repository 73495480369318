import { createAction } from 'redux-actions';
import { actionString, createRequestAction } from '../helper';

const key = 'userV2';
const action = actionString(key);

export const SET_QNA_CATEGORY = action('SET_QNA_CATEGORY');
export const SET_LOGIN = action('SET_LOGIN');
export const SET_LOGOUT = action('SET_LOGOUT');
export const SET_CLEANUP = action('SET_CLEANUP');
export const SET_EMAIL = action('SET_EMAIL');
export const SET_EMAIL_FILTER = action('SET_EMAIL_FILTER');
export const EMAIL_CHECKED = action('EMAIL_CHECKED');
export const EMAIL_ALL_CHECKED = action('EMAIL_ALL_CHECKED');
export const HISTORY_CHECKED = action('HISTORY_CHECKED');
export const HISTORY_ALL_CHECKED = action('HISTORY_ALL_CHECKED');
export const EMAIL_REWRITE = action('EMAIL_REWRITE');
export const TEACHER_CHECKED = action('TEACHER_CHECKED');
export const TEACHER_ALL_CHECKED = action('TEACHER_ALL_CHECKED');
export const SURVEY_FINISH_CHECKED = action('SURVEY_FINISH_CHECKED');

export const QNA_HISTORY_REQUEST = action('QNA_HISTORY_REQUEST');
export const QNA_HISTORY_SUCCESS = action('QNA_HISTORY_SUCCESS');
export const QNA_HISTORY_FAILURE = action('QNA_HISTORY_FAILURE');

export const MESSAGE_HISTORY_REQUEST = action('MESSAGE_HISTORY_REQUEST');
export const MESSAGE_HISTORY_SUCCESS = action('MESSAGE_HISTORY_SUCCESS');
export const MESSAGE_HISTORY_FAILURE = action('MESSAGE_HISTORY_FAILURE');

export const EMAIL_LIST_REQUEST = action('EMAIL_LIST_REQUEST');
export const EMAIL_LIST_SUCCESS = action('EMAIL_LIST_SUCCESS');
export const EMAIL_LIST_FAILURE = action('EMAIL_LIST_FAILURE');

export const EMAIL_HISTORY_REQUEST = action('EMAIL_HISTORY_REQUEST');
export const EMAIL_HISTORY_SUCCESS = action('EMAIL_HISTORY_SUCCESS');
export const EMAIL_HISTORY_FAILURE = action('EMAIL_HISTORY_FAILURE');

export const TEACHER_LIST_REQUEST = action('TEACHER_LIST_REQUEST');
export const TEACHER_LIST_SUCCESS = action('TEACHER_LIST_SUCCESS');
export const TEACHER_LIST_FAILURE = action('TEACHER_LIST_FAILURE');

export const SURVEY_RESULT_REQUEST = action('SURVEY_RESULT_REQUEST');
export const SURVEY_RESULT_SUCCESS = action('SURVEY_RESULT_SUCCESS');
export const SURVEY_RESULT_FAILURE = action('SURVEY_RESULT_FAILURE');

export const setQnaCategory = createAction(SET_QNA_CATEGORY);
export const setLogin = createAction(SET_LOGIN);
export const setLogout = createAction(SET_LOGOUT);
export const setCleanup = createAction(SET_CLEANUP);
export const setEmail = createAction(SET_EMAIL);
export const setEmailFilter = createAction(SET_EMAIL_FILTER);
export const emailChecked = createAction(EMAIL_CHECKED);
export const emailAllChecked = createAction(EMAIL_ALL_CHECKED);
export const historyChecked = createAction(HISTORY_CHECKED);
export const historyAllChecked = createAction(HISTORY_ALL_CHECKED);
export const emailRewrite = createAction(EMAIL_REWRITE);
export const teacherChecked = createAction(TEACHER_CHECKED);
export const teacherAllChecked = createAction(TEACHER_ALL_CHECKED);
export const surveyFinishChecked = createAction(SURVEY_FINISH_CHECKED);

export const qnaHistoryPender = createRequestAction(QNA_HISTORY_REQUEST, QNA_HISTORY_SUCCESS, QNA_HISTORY_FAILURE);

export const messageHistoryPender = createRequestAction(
    MESSAGE_HISTORY_REQUEST,
    MESSAGE_HISTORY_SUCCESS,
    MESSAGE_HISTORY_FAILURE
);

export const emailListPender = createRequestAction(EMAIL_LIST_REQUEST, EMAIL_LIST_SUCCESS, EMAIL_LIST_FAILURE);

export const emailHistoryPender = createRequestAction(
    EMAIL_HISTORY_REQUEST,
    EMAIL_HISTORY_SUCCESS,
    EMAIL_HISTORY_FAILURE
);

export const teacherListPender = createRequestAction(TEACHER_LIST_REQUEST, TEACHER_LIST_SUCCESS, TEACHER_LIST_FAILURE);

export const surveyResultPender = createRequestAction(
    SURVEY_RESULT_REQUEST,
    SURVEY_RESULT_SUCCESS,
    SURVEY_RESULT_FAILURE
);
