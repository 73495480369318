import React, { useRef } from 'react';
import styled from 'styled-components';

import { Button } from '../Button';

const UploadStyled = styled.label`
    width: 100%;
    display: inline-block;

    i.fa-cloud-upload-alt {
        margin-top: 0.25rem;
        margin-left: 0.56rem;
    }

    input {
        display: none;
    }
`;

const defaultProps = {
    children: 'File Upload'
};

const Upload = ({
    children,
    className,
    loading,
    onUploadCallback,
    ...props
}) => {
    const upload = useRef(null);

    const uploadHandler = () => {
        upload.current.click();
    };

    const changeHandler = e => {
        onUploadCallback(e.target.files);
        e.target.value = '';
    };

    return (
        <div>
            <UploadStyled className={className}>
                <input
                    type="file"
                    ref={upload}
                    onChange={changeHandler}
                    {...props}
                />
                <Button onClick={uploadHandler} loading={loading}>
                    <span>{children}</span>
                    <i className="fas fa-cloud-upload-alt"></i>
                </Button>
            </UploadStyled>
        </div>
    );
};

Upload.defaultProps = defaultProps;

export default Upload;
