import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Provider } from './RadioContext';
import styled from 'styled-components';

const RadioGroupStyled = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .m-button {
        .radio {
        }
    }
`;

const defaultProps = {
    mode: 'button',
    className: '',
};

const RadioGroup = ({ children, value, name, onChange }) => {
    const [groupValue, setGroupValue] = useState(value);

    const changeHandler = useCallback(
        (event) => {
            // event.presist();

            const { target } = event;

            if (target.checked) {
                setGroupValue(target.value);

                if (onChange && typeof onChange === 'function') {
                    onChange(event, target.value);
                }
            }
        },
        [onChange]
    );

    const contextValue = useMemo(() => ({ onChange: changeHandler, value: groupValue, name }), [
        changeHandler,
        name,
        groupValue,
    ]);

    useEffect(() => {
        setGroupValue(value);
    }, [value]);

    return (
        <RadioGroupStyled>
            <Provider value={contextValue}>{children}</Provider>
        </RadioGroupStyled>
    );
};

RadioGroup.defaultProps = defaultProps;

export default RadioGroup;
