import React, { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';

const TooltipStyled = styled.div.attrs({
    className: 'tool-tip',
})`
    position: relative;
    display: inline-block;
    border-radius: 3px;
    cursor: ${(props) => props.mode === 'toggle' && 'pointer'};
    ${(props) => props.bg && props.theme[props.bg]}
`;

const TooltipBoard = styled.div`
    position: absolute;
    transition: all 0.25s;
    display: flex;
    z-index: 11;

    span {
        font-size: 1.2rem;
        display: flex;
        flex: 1;
        word-break: break-all;
        white-space: nowrap;
        color: #262626;
        background-color: #fff;
        padding: 4px 8px;
        border-radius: 3px;
        border: 1px solid ${(props) => props.theme.black};
    }

    ${(props) =>
        props.top &&
        css`
            bottom: 100%;
            margin-bottom: 0.7rem;
        `}
    ${(props) =>
        props.left &&
        css`
            left: 0;
            margin-right: 0.7rem;
        `}
    ${(props) =>
        props.right &&
        css`
            right: 0;
            margin-left: 0.7rem;
        `}
    ${(props) =>
        props.bottom &&
        css`
            top: 100%;
            margin-top: 0.7rem;
        `}
    ${(props) =>
        props.center &&
        css`
            left: 50%;
            transform: translateX(-50%);
        `}

    ${(props) =>
        !props.active
            ? css`
                  opacity: 0;
                  visibility: hidden;
              `
            : css`
                  opacity: 1;
                  visibility: visible;
              `}

    ${(props) =>
        props.textAlign &&
        css`
            text-align: ${props.textAlign};
        `}
`;

const defaultProps = {
    mode: 'hover', // hover || toggle
};

const TooltipModeTypes = ['hover', 'toggle'];

const Tooltip = ({ children, bgColor, mode, comment, top, bottom, left, right, center, boxWidth, textAlign }) => {
    const [handler, setHandler] = useState({});
    const [active, setActive] = useState(false);

    const hoverHandler = useCallback((hover) => {
        setActive(hover);
    }, []);

    const clickHandler = useCallback(() => {
        setActive(!active);
    }, [active]);

    useEffect(() => {
        const event = TooltipModeTypes.indexOf(mode);

        if (event === 0 || event === -1) {
            setHandler({
                onMouseOver: () => hoverHandler(true),
                onMouseOut: () => hoverHandler(false),
            });
        } else {
            setHandler({ onClick: clickHandler });
        }
    }, [mode, hoverHandler, clickHandler]);

    return (
        <TooltipStyled bg={bgColor} mode={mode} {...handler}>
            <div>{children}</div>
            <TooltipBoard
                active={active}
                top={top}
                bottom={bottom}
                left={left}
                right={right}
                center={center}
                boxWidth={boxWidth}
                textAlign={textAlign}
            >
                <span dangerouslySetInnerHTML={{ __html: comment }} />
            </TooltipBoard>
        </TooltipStyled>
    );
};

Tooltip.defaultProps = defaultProps;

export default Tooltip;

/**
 * 
 *  &:after {
        content: '';
        position: absolute;
        display: block;
        border-width: 5px;
        width: 18px;
        height: 18px;
        border-bottom: 1px solid #262626;
        border-right: 1px solid #262626;
        background-color: #fff;
        

        ${props =>
            props.top &&
            css`
                bottom: -1.7rem;
            `}
        ${props =>
            props.left &&
            css`
                right: -1.7rem;
                transform: rotateZ(45deg);
            `}
        ${props =>
            props.right &&
            css`
                left: -1.7rem;
                transform: rotateZ(45deg);
            `}
        ${props =>
            props.bottom &&
            css`
                top: -0.3rem;
            `}
        ${props =>
            props.center &&
            css`
                left: 50%;
                transform: rotateZ(${props.top ? '45deg' : '-135deg'})
                    translateX(${props.top ? '-50%' : '50%'});
            `}
        
    }
 */
