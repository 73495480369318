import { handleActions } from 'redux-actions';
import { produce } from 'immer';

import {
    CHURCH_LIST_FAILURE,
    CHURCH_LIST_REQUEST,
    CHURCH_LIST_SUCCESS,
    CHURCH_VIEW_FAILURE,
    CHURCH_VIEW_REQUEST,
    CHURCH_VIEW_SUCCESS,
    CHURCH_GATE_FAILURE,
    CHURCH_GATE_SUCCESS,
    CHURCH_GATE_REQUEST,
    SET_REMOVE_ACCOUNT,
    SET_UPDATE_NAME,
    SET_UPDATE_GATE,
    SET_REMOVE_GATE,
} from './actions';

import { phoneDivider, hasOwnProperty } from '../../lib/utils';

const initizlState = {
    churches: [],
    places: [],
    accounts: [],
    loading: {
        church: false,
        view: false,
    },
};

export default handleActions(
    {
        [SET_REMOVE_ACCOUNT]: (state, action) =>
            produce(state, (draft) => {
                draft.accounts = draft.accounts.filter(({ id }) => id !== action.payload);
            }),

        [SET_UPDATE_NAME]: (state, action) =>
            produce(state, (draft) => {
                const newPlaces = draft.places.reduce((prev, current) => {
                    const { placeId, name } = action.payload;
                    if (current.id === placeId) {
                        current.name = name;
                    }
                    return [...prev, current];
                }, []);

                draft.places = newPlaces;
            }),

        [SET_UPDATE_GATE]: (state, action) =>
            produce(state, (draft) => {
                const { placeId, gateId, beaconId, name } = action.payload;

                const placeIndex = draft.places.findIndex(({ id }) => id === placeId);

                if (hasOwnProperty(draft.places[placeIndex], 'beacons')) {
                    draft.places[placeIndex].beacons = draft.places[placeIndex].beacons.reduce((prev, current) => {
                        if (current.id === gateId) {
                            current.name = name;
                            current.beacon_id = beaconId;
                        }
                        return [...prev, current];
                    }, []);
                }
            }),

        [SET_REMOVE_GATE]: (state, action) =>
            produce(state, (draft) => {
                const { placeId, gateId } = action.payload;

                const placeIndex = draft.places.findIndex(({ id }) => id === placeId);
                draft.places[placeIndex].beacons = draft.places[placeIndex].beacons.filter(({ id }) => id !== gateId);
            }),

        [CHURCH_LIST_FAILURE]: (state) =>
            produce(state, (draft) => {
                draft.loading.church = false;
            }),

        [CHURCH_LIST_REQUEST]: (state) =>
            produce(state, (draft) => {
                draft.loading.church = true;
            }),

        [CHURCH_LIST_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loading.church = false;
                draft.churches = action.payload.church.map(({ tel, ...rest }) => ({
                    ...rest,
                    tel: phoneDivider(tel),
                }));
            }),

        [CHURCH_VIEW_FAILURE]: (state) =>
            produce(state, (draft) => {
                draft.loading.view = false;
            }),

        [CHURCH_VIEW_REQUEST]: (state) =>
            produce(state, (draft) => {
                draft.loading.view = true;
            }),

        [CHURCH_VIEW_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loading.view = false;

                draft.places = action.payload.places.map((data) => {
                    return { ...data, beacons: [] };
                });

                draft.accounts = action.payload.accounts;
            }),

        [CHURCH_GATE_FAILURE]: (state) =>
            produce(state, (draft) => {
                return state;
            }),

        [CHURCH_GATE_REQUEST]: (state) =>
            produce(state, (draft) => {
                return state;
            }),

        [CHURCH_GATE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                const { gates, placeId } = action.payload;

                draft.places = draft.places.reduce((prev, current) => {
                    if (current.id === placeId) {
                        current.beacons = gates;
                    }

                    return [...prev, current];
                }, []);
            }),
    },
    initizlState
);
